import React, { useState } from "react";
import PropTypes from "prop-types";

const proptypes = {
    primaryMessage: PropTypes.string.isRequired,
    primaryIcon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.elementType
    ]).isRequired,
    secondaryMessage: PropTypes.string,
    secondaryIcon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.elementType
    ]),
    buttonText: PropTypes.string,
    buttonCssClass: PropTypes.string,
    onClick: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.bool
    ])
};

const NoResults = ({ primaryMessage, primaryIcon, secondaryMessage = '', secondaryIcon = '', buttonText = '', buttonCssClass = '', onClick = false }) => {

    return (
        <div className="no-results">
            <div className="no-results__message">
                <span className='no-results__icon material-icons material-icons-outlined'>{primaryIcon}</span>
                <p className="no-results__text">{primaryMessage}</p>
            </div>
            {(secondaryMessage || secondaryIcon) && <div className="no-results__seperator">or</div>}
            {(secondaryMessage || secondaryIcon) &&
                <div className="no-results__message">
                    {secondaryIcon &&
                        <span className="no-results__icon material-icons material-icons-outlined">{secondaryIcon}</span>
                    }
                    {secondaryMessage &&
                        <p className="no-results__text">{secondaryMessage}</p>
                    }
                </div>
            }
            {buttonText && onClick &&
                <button
                    className={`button ${buttonCssClass}`}
                    onClick={onClick}
                >{buttonText}
                </button>
            }
        </div>
    );
}

NoResults.propTypes = proptypes;
export default NoResults