import React from "react";
import PropTypes from "prop-types";
const proptypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    label: PropTypes.object.isRequired,
    buttonText: PropTypes.string,
    onClick: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.bool
    ])
}
const Upsell = ({ title, text = '', label, buttonText = '', onClick = false}) => {

    return (
        <div className="results-card__upsell">
            <div className="results-card__upsell-label">{label}</div>
            <div className="results-card__upsell-content">
                <h4 className="results-card__upsell-title">{title}</h4>
                {text && <p className="results-card__upsell-text">{text}</p>}
                {buttonText && onClick &&
                    <button className="button" onClick={onClick}>{buttonText}</button>
                }
            </div>
        </div>
    )
}
Upsell.proptypes = proptypes;
export default Upsell;