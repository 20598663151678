import React from "react";
import PropTypes from "prop-types";
const proptypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired
}
const MoneyBackGuarantee = ({title, text, icon}) => {

    return (
        <div className="results-card__guarantee">
            <div className="results-card__guarantee-icon">
                {icon}
            </div>
            <div className="results-card__guarantee-content">
                <h4 className="results-card__guarantee-title">{title}</h4>
                <p className="results-card__guarantee-text">{text}</p>
            </div>
        </div>
    )
}
MoneyBackGuarantee.proptypes = proptypes;
export default MoneyBackGuarantee;