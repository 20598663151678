import React, { useContext } from 'react';
import { BookingContext } from './../Context/BookingContext';
import PropTypes from "prop-types";

const proptypes = {
    session: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        icon: PropTypes.string,
    }),
    handleSelection: PropTypes.func.isRequired,
}

const SessionTypeItem = ({item, handleSelection}) => {

    const [bookingState, setBookingState] = useContext(BookingContext);

    return (
        <div className={'session-picker__item' + (item.id === bookingState.sessionType.current.id ? ' session-picker__item--active' : '')}
            onClick={() => { handleSelection(item) }}
        >
            <div className='session-picker__icons'>
                {item.id === bookingState.sessionType.current.id
                    ? <span className="session-picker__icon session-picker__icon--selected material-icons material-icons-outlined">check_circle</span>
                    : <span className="session-picker__icon">{item.icon}</span>
                }
            </div>
            <h4 className="session-picker__name">{item.name}</h4>
        </div>
    )
}

SessionTypeItem.propTypes = proptypes;
export default SessionTypeItem;