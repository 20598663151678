import React, { useState, useEffect, useRef } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import PropTypes from 'prop-types';

import { googleGeocodeSearch, getLocalTimeZone } from '../../modules/utilities';
import { defaultObject } from '../Booking/Context/BookingDefaultData';
import Location from '../Booking/Search/Location';
import Map from './Map';
import Marker from './Marker';
import RangeSlider from '../Utilities/RangeSlider';

const propTypes = {
    noResultsTitle: PropTypes.string.isRequired,
    noResultsText: PropTypes.string.isRequired,
    noResultsServicesText: PropTypes.string.isRequired,
    multiSiteId: PropTypes.number.isRequired,
};

const FindACentreMap = ({
    noResultsTitle,
    noResultsText,
    noResultsServicesText,
    multiSiteId,
    geo,
}) => {
    if (typeof geo === 'object') {
        const timeZone = getLocalTimeZone(true, false, geo.country);

        // Need to make sure we only fetch timezone that is supported otherwise force to UTC
        if (defaultObject.supportedTimeZones.includes(timeZone)) {
            geo.timezone = timeZone;
        } else {
            geo.timezone = 'UTC';
        }

        // Will need to convert to the standard time as the times are indexed with that
        if (geo.timezone in defaultObject.standardTimeZones) {
            geo.timezone = defaultObject.standardTimeZones[geo.timezone];
        }
    }
    const countryArray = ['CA', 'US', 'AU'];
    const defaultLatLng = {
        lat: 0,
        lng: 0,
    };
    const defaultSearchDistance = countryArray.includes(geo.country) ? 200 : 75;
    const perPage = 16;
    const [zoom, setZoom] = useState(11);
    const [center, setCenter] = useState(defaultLatLng);
    const [locationValue, setLocationValue] = useState('London, UK');
    const [searchLocation, setSearchLocation] = useState(defaultLatLng);
    const [mapMarkerLocations, setMapMarkerLocations] = useState([]);
    const [activeInfoWindow, setActiveInfoWindow] = useState(null);
    const [searchDistance, setSearchDistance] = useState(defaultSearchDistance);
    const [resultCount, setResultCount] = useState(0);
    const searchInput = useRef(null);
    const [addictions, setAddictions] = useState([]);
    const [selectedAddiction, setSelectedAddiction] = useState({
        name: 'All Addictions',
        id: '',
        services: [],
    });
    const [triggerExpandedSearch, setTriggerExpandedSearch] = useState(false);
    const noResults = useRef(null);
    const [viewAll, setViewAll] = useState(false);

    useEffect(() => {
        fetchAddictions();
        locationSearch();
    }, []);

    useEffect(() => {
        if (searchLocation.lat !== 0 && searchLocation.lng !== 0) {
            // Clear existing markers & infoWindows to prevent old data from appearing
            setMapMarkerLocations([]);
            setActiveInfoWindow(null);

            fetchSearchResults();
        }
    }, [searchLocation, searchDistance, selectedAddiction]);

    useEffect(() => {
        if (triggerExpandedSearch) {
            handleRangeChange(500);
        }
    }, [triggerExpandedSearch]);

    const fetchAddictions = async () => {
        const formData = new FormData();
        formData.append('security', wp_ajax.security);
        formData.append('include_services', true);

        formData.append('country', geo.country);
        formData.append('currency', geo.currency);

        const addictionsResponse = await fetch(
            `${wp_ajax.ajax_url}?action=get_addictions`,
            {
                method: 'POST',
                body: formData,
            },
        );

        const addictionsJson = await addictionsResponse.json();

        setAddictions(addictionsJson?.data?.addictions);
    };

    const locationSearch = async (location = 'London, UK') => {
        setLocationValue(location);

        // Fetch geocoded location from locationValue input string
        const locationGeocodeResults = await googleGeocodeSearch(location);
        const locationCoordinates = {
            lat: locationGeocodeResults.lat,
            lng: locationGeocodeResults.lng,
        };

        // Reset zoom level and centre on new location search
        setZoom(11);
        setCenter(locationCoordinates);

        // Trigger data fetch for results based on new geocoded location via useEffect hook
        setSearchLocation(locationCoordinates);
    };

    const fetchSearchResults = async () => {
        const locationSearch = new Location({
            type: 'location',
            online: false,
            perPage: 1000,
            page: 1,
            sort: 'distance',
            distance: searchDistance,
            location: searchLocation,
            addiction: [selectedAddiction.id].filter(Boolean),
            multiSiteId,
        });
        const response = await locationSearch.getResults();

        setResultCount(response.total);

        if (response.total < 1) {
            // If no results then trigger re-search at 500 distance range the first time
            // zero results received, then reset.
            setTriggerExpandedSearch(!triggerExpandedSearch);

            if (noResults !== null) {
                noResults.current.scrollIntoView({ behavior: 'smooth' });
            }

            return;
        }

        const results = response.results.filter((result => !result.hide_on_map));

        setMapMarkerLocations(results);

        if (response.total <= perPage) {
            setViewAll(true);
        }
    };

    const displaySearchResults = () => {
        if (viewAll) {
            return mapMarkerLocations;
        }

        return mapMarkerLocations.slice(0, perPage);
    };

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            if (searchInput.current.value === '') {
                return;
            }

            locationSearch(searchInput.current.value);
        }
    };

    const handleSearchClick = e => {
        e.preventDefault();

        if (searchInput.current.value === '') {
            return;
        }

        locationSearch(searchInput.current.value);
    };

    const handleActiveInfoWindow = (anchor, map, markerData, position) => {
        if (activeInfoWindow !== null) {
            activeInfoWindow.close();
        }

        const { title, display_title, permalink } = markerData;
        const locationTitle = display_title !== '' ? display_title : title;
        const address = getAddressFor(markerData);
        const infoContent = `
            <div class="map-marker">
                <h4 class="map-marker__title">${locationTitle}</h4>
                <div class="map-marker__address-wrapper">
                    <span class="material-icons pin">
                        pin_drop
                    </span>
                    <p class="map-marker__address">${address}</p>
                    <a class="map-marker__link" href="${permalink}">More info</a>
                </div>
            </div>
        `;

        const infoWindow = new google.maps.InfoWindow({
            content: infoContent,
            disableAutoPan: true,
            maxWidth: 400,
        });

        infoWindow.open({
            anchor,
            map,
            shouldFocus: false,
        });

        infoWindow.addListener('closeclick', () => {
            setActiveInfoWindow(null);
        });

        setActiveInfoWindow(infoWindow);
        setCenter(position);
    };

    const handleRangeChange = value => {
        setSearchDistance(parseInt(value));
    };

    const getAddressFor = result => {
        const { address_line_1, address_line_2, state, post_code } = result;

        let address = '';
        if (address_line_1 !== '') {
            address += `${address_line_1}, `;
        }
        if (address_line_2 !== '') {
            address += `${address_line_2}, `;
        }
        if (state !== '') {
            address += `${state}, `;
        }
        if (post_code !== '') {
            address += `${post_code}, `;
        }

        return address.replace(/, $/g, '');
    };

    const getDistanceFor = result => {
        const { distance, measurement } = result.location;

        return `${distance}${measurement}`;
    };

    const handleAddictionSelect = e => {
        e.preventDefault();

        const select = e.target;
        const selectedAddicitionName =
            select.options[select.selectedIndex].text;

        const addictionItem = addictions.find(
            addiction => addiction.id == e.target.value,
        );

        let selectedAddicitionServices = [];
        if (addictionItem) {
            selectedAddicitionServices = addictionItem.services;
        }

        setSelectedAddiction({
            name: selectedAddicitionName,
            id: e.target.value,
            services: selectedAddicitionServices,
        });
    };

    const getBookingUrlFor = (location, sessionType = 'group') => {
        const urlParams = new URLSearchParams();

        urlParams.set(
            'addiction_id',
            selectedAddiction.name.replace(/\s+/g, '-').toLowerCase(),
        );

        if (selectedAddiction.id === '' && addictions.length > 0) {
            urlParams.set(
                'addiction_id',
                addictions[0].name.replace(/\s+/g, '-').toLowerCase(),
            );
        }

        urlParams.set('session', 'location');
        urlParams.set('session_type', sessionType);
        urlParams.set('session_count', 1);

        if (location !== null) {
            const locationCountry = location?.country_code
                ? location?.country_code?.toLowerCase()
                : 'gb';
            urlParams.set('country', locationCountry);
            urlParams.set('postcode', location.post_code);
        }

        return `/booking-journey/?${urlParams.toString()}`;
    };

    return (
        <>
            <div className="centres-map__wrapper">
                {activeInfoWindow === null && (
                    <>
                        <div className="centres-map__overlay">
                            <div className="centres-map__overlay-columns">
                                <div className="centres-map__overlay-column">
                                    <div className="centres-map__filter-option-wrapper">
                                        <select
                                            className="centres-map__filter-option"
                                            onChange={handleAddictionSelect}>
                                            <option value="">
                                                All Addictions
                                            </option>
                                            {addictions.length > 0 &&
                                                addictions.map(
                                                    (addiction, idx) => (
                                                        <option
                                                            key={idx}
                                                            value={
                                                                addiction.id
                                                            }>
                                                            {addiction.name}
                                                        </option>
                                                    ),
                                                )}
                                        </select>
                                        <span className="material-icons arrow">
                                            arrow_drop_down
                                        </span>
                                    </div>
                                </div>

                                <div className="centres-map__overlay-column">
                                    <div className="centres-map__filter-option-wrapper">
                                        <span className="material-icons pin">
                                            place
                                        </span>
                                        <input
                                            type="text"
                                            placeholder="Search a city"
                                            className="centres-map__filter-input"
                                            onKeyDown={handleKeyDown}
                                            ref={searchInput}
                                        />
                                        <div
                                            className="centres-map__filter-option-submit"
                                            onClick={handleSearchClick}>
                                            <span className="material-icons search">
                                                search
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="centres-map__overlay centres-map__overlay--bottom">
                            <div className="centres-map__overlay-columns">
                                <div className="centres-map__overlay-column">
                                    <RangeSlider
                                        min={5}
                                        max={2000}
                                        step={5}
                                        measurement={'mi'}
                                        defaultValue={defaultSearchDistance}
                                        handleRangeChange={handleRangeChange}
                                        updateValueOnCallback={searchDistance}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                    }}>
                    <Wrapper apiKey={process.env.GOOGLE_MAP_API}>
                        <Map
                            center={center}
                            zoom={zoom}
                            style={{ height: '100%', width: '100%' }}
                            options={{
                                disableDefaultUI: true,
                                zoomControl: false,
                                disableDoubleClickZoom: true,
                                scrollwheel: false,
                                panControl: false,
                            }}>
                            {mapMarkerLocations.map((marker, idx) => (
                                <Marker
                                    key={idx}
                                    markerData={marker}
                                    position={{
                                        lat: marker.location.lat,
                                        lng: marker.location.lon,
                                    }}
                                    handleActiveInfoWindow={
                                        handleActiveInfoWindow
                                    }
                                    activeInfoWindow={activeInfoWindow}
                                />
                            ))}
                        </Map>
                    </Wrapper>
                </div>
            </div>
            <section className="centres-results">
                <div className="centres-results__wrapper">
                    <header className="centres-results__header">
                        <div className="centres-results__count-text">
                            {`Showing '${selectedAddiction.name}' locations closest to ${locationValue}`}
                        </div>
                        <div className="centres-results__count">
                            <span className="value">{resultCount}</span>
                            <span className="title">centres</span>
                        </div>
                    </header>
                    <div className="centres-results__list">
                        {displaySearchResults().map((location, idx) => {
                            const locationAddress = getAddressFor(location);
                            return (
                                <div
                                    key={idx}
                                    className="centres-results__list-item">
                                    <div className="centres-results__list-item-column">
                                        <div className="centres-results__list-item-header">
                                            <h3 className="centres-results__list-item-title">
                                                {location.display_title !== ''
                                                    ? location.display_title
                                                    : location.title}
                                            </h3>
                                            <span className="centres-results__list-item-distance">
                                                ({getDistanceFor(location)})
                                            </span>
                                        </div>
                                        {locationAddress !== '' && (
                                            <div className="centres-results__list-item-address">
                                                <span className="material-icons pin">
                                                    pin_drop
                                                </span>
                                                <p>{getAddressFor(location)}</p>
                                            </div>
                                        )}
                                        <a
                                            href={location.permalink}
                                            className="centres-results__list-item-link">
                                            More info
                                        </a>
                                    </div>

                                    <div className="centres-results__list-item-column">
                                        <div className="centres-results__list-item-cta">
                                            <a
                                                className="button button--primary"
                                                href={getBookingUrlFor(
                                                    location,
                                                )}
                                                title="Check availability">
                                                {' '}
                                                Check availability
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {resultCount === 0 && (
                            <div
                                className="centres-results__list-item"
                                ref={noResults}>
                                <div className="centres-results__list-item-column">
                                    <div className="centres-results__list-item-header">
                                        <h3 className="centres-results__list-item-title">
                                            {noResultsTitle}
                                        </h3>
                                    </div>
                                    <div className="centres-results__list-item-address">
                                        <span className="material-icons pin">
                                            pin_drop
                                        </span>
                                        <p>
                                            {selectedAddiction.services.length >
                                            0
                                                ? noResultsServicesText
                                                : noResultsText}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {resultCount === 0 &&
                            selectedAddiction.services.length > 0 &&
                            selectedAddiction.services.map((service, idx) => (
                                <div
                                    key={idx}
                                    className="centres-results__list-item">
                                    <div className="centres-results__list-item-column">
                                        <div className="centres-results__list-item-header">
                                            <h3 className="centres-results__list-item-title">
                                                {service.title}
                                                {service.secondary_title !== ''
                                                    ? ' - ' +
                                                      service.secondary_title
                                                    : ''}
                                            </h3>
                                        </div>
                                        <div className="centres-results__list-item-address">
                                            {service.icon && (
                                                <img
                                                    className="pin"
                                                    src={service.icon}
                                                    alt={
                                                        'Icon ' + service.title
                                                    }
                                                />
                                            )}
                                            <p>{service.intro_text}</p>
                                        </div>
                                    </div>
                                    {service.book_now_url && (
                                        <div className="centres-results__list-item-column">
                                            <div className="centres-results__list-item-cta">
                                                <span className="centres-results__list-item-price">
                                                    {service.price}
                                                </span>
                                                <a
                                                    className="button button--primary"
                                                    href={service.book_now_url}
                                                    title={
                                                        service.title.includes(
                                                            'One-to-One',
                                                        )
                                                            ? 'Contact Us'
                                                            : service.title.includes(
                                                                  'Online Video Programme',
                                                              )
                                                            ? 'Buy Now'
                                                            : 'Check Availability'
                                                    }>
                                                    {' '}
                                                    {service.title.includes(
                                                        'One-to-One',
                                                    )
                                                        ? 'Contact Us'
                                                        : service.title.includes(
                                                              'Online Video Programme',
                                                          )
                                                        ? 'Buy Now'
                                                        : 'Check Availability'}
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                    </div>
                </div>
            </section>
            {!viewAll && (
                <div className="centres-map__view-more">
                    <a className="button" onClick={() => setViewAll(true)}>
                        View all
                    </a>
                </div>
            )}
        </>
    );
};

FindACentreMap.propTypes = propTypes;

export default FindACentreMap;
