import React, { useEffect, useState, useContext, useRef } from 'react';
import { BookingContext } from './Context/BookingContext';
import Search from './Search/Search';
import { applyShortcodes } from '../../modules/utilities';

//Tabs
import BookingTabs from '../Booking/BookingTabs/';

//Tab Headers
import RangeSlider from '../Utilities/RangeSlider';
import ResultsFound from './Results/ResultsFound';
import DateSelection from './DateSelection/';

// Icons
import LoadingIcon from '../Icons/LoadingIcon';

// Results
import ResultsCard from './Results/ResultsCard';
import NoResults from './Results/NoResults';
import SessionOnlineIcon from '../Icons/SessionOnlineIcon';
import Pagination from '../Pagination/Pagination';
import FranchiseMessage from './FranchiseMessage';

//Form
import Form from '../Forms';

const BookingTabsContainer = () => {
    const [bookingState, setBookingState] = useContext(BookingContext);
    const [activeTabIndex, setActiveTabIndex] = useState(1);

    const [availableAppointments, setAvailableAppointments] = useState(0);
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState({});
    const [paginationVars, setPaginationVars] = useState({});
    const [perPage, setPerPage] = useState(20);
    const [franchiseMessage, setFranchiseMessage] = useState({});
    const [showFranchiseMessage, setShowFranchiseMessage] = useState(false);
    const [showLimitedResultsUpsell, setShowLimitedResultsUpsell] = useState(
        false,
    );
    const [showNoResultsOnline, setShowNoResultsOnline] = useState(false);
    const [showExpandSearch, setShowExpandSearch] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [rangeMaxDistance, setRangeMaxDistance] = useState(200);
    const [rangeDefaultDistance, setRangeDefaultDistance] = useState(75);
    const resultsList = useRef(null);
    const countryArray = ['CA', 'US', 'AU']; //Canada, USA, Australia

    const getDefaultRanges = () => {
        const country =
            'location' in bookingState
                ? bookingState.country
                : bookingState.geo.country;
        let ranges = {
            maxDistance: 200,
            defaultDistance: 75
        };

        if (countryArray.includes(country)) {
            ranges.maxDistance = 2000;
            ranges.defaultDistance = 500;
        }

        return ranges;
    };

    const setDefaultRanges = () => {
        const defaultRanges = getDefaultRanges();
        setRangeMaxDistance(defaultRanges.maxDistance);
        setRangeDefaultDistance(defaultRanges.defaultDistance);
    };

    const fetchFranchiseMessage = async (country, addiction) => {
        let message = {};
        if (country !== '') {
            const search = new Search({
                type: 'franchise',
                country: country,
                addiction:
                    addiction && addiction.current.id !== ''
                        ? [addiction.current.id]
                        : [],
                perPage: 1,
                page: 1,
                sort: '',
                distance: 0,
            });
            const response = await search.getResults();

            if (response.results.length > 0) {
                message = response.results[0].booking;

                if (addiction && addiction.current.id !== '') {
                    message.info_title = message.info_title.replace(
                        /{addiction}/gi,
                        `${addiction.current.display_name.toLowerCase()}`,
                    );
                }

                if (
                    message.info_title !== '' &&
                    (message.info_text !== '' || message.info_alt_text !== '')
                ) {
                    if (message.info_text !== '') {
                        message.info_text = await applyShortcodes(
                            message.info_text,
                        );
                    }

                    if (message.info_alt_text !== '') {
                        message.info_alt_text = await applyShortcodes(
                            message.info_alt_text,
                        );
                    }

                    setShowFranchiseMessage(true);
                }
            }
        }

        return message;
    };

    const fetchResults = async (data, page = 1) => {
        // Don't perform the search unless we have the required data
        if (
            'addiction' in data === false ||
            'session' in data === false ||
            'sessionType' in data === false
        ) {
            return;
        }

        setLoading(true);

        const voucherState =
            'voucher' in data &&
            data.voucher.current.key === 'yes';
        const type =
            'sessionType' in data &&
            (data.sessionType.current.key === 'ovp' ||
                data.sessionType.current.key === 'onetoone' ||
                data.sessionType.current.key === 'taster')
                ? `${data.sessionType.current.key}`
                : 'seminar';
        const addiction =
            'addiction' in data && data.addiction.current.id !== ''
                ? [data.addiction.current.id]
                : [];
        const online =
            'sessionType' in data &&
            (data.sessionType.current.key === 'ovp' ||
                (data.sessionType.current.key === 'live-group' &&
                    (data.sessionCount?.value > 0 || voucherState)) ||
                (data.sessionType.current.key === 'group' &&
                    data.activeTabIndex === 3) ||
                data.sessionType.current.key === 'onetoone' ||
                data.sessionType.current.key === 'taster');
        const headOfficeId = data.data.seminar_location_id;
        const showHeadOffice = false;
        const group =
            'sessionType' in data &&
            (data.sessionType.current.key === 'group' ||
                data.sessionType.current.key === 'live-group' ||
                data.sessionType.current.key === 'taster');
        const sort =
            data.sessionType.current.key === 'group'
                ? 'activeTabIndex' in data &&
                  (data.activeTabIndex === 2 || data.activeTabIndex === 3)
                    ? 'soonest'
                    : 'distance'
                : 'soonest';
        const location =
            'location' in data &&
            data.location.lng !== '' &&
            data.location.lat !== ''
                ? data.location
                : null;
        const startDate =
            'startDate' in data && data.startDate !== ''
                ? data.startDate
                : null;
        const endDate =
            'endDate' in data && data.endDate !== '' ? data.endDate : null;
        let country = '';
        const currency = data.geo.currency;
        const timeZone = data.geo.timezone;
        const voucher = 'voucher' in data && data.voucher.current.key === 'yes';

        if (
            ('sessionType' in data &&
                data.sessionType.current.key === 'live-group') ||
            (data.sessionType.current.key === 'group' &&
                data.activeTabIndex === 3)
        ) {
            country = data.geo.country;
        } else if (
            'sessionType' in data &&
            data.sessionType.current.key === 'group'
        ) {
            country =
                'location' in data && 'country' in data
                    ? data.country
                    : data.geo.country;
        }

        const defaultRanges = getDefaultRanges();

        const distance =
            'distance' in data && data.distance >= 0
                ? data.distance
                : defaultRanges.defaultDistance;

        let params = {
            type,
            addiction,
            online,
            group,
            perPage,
            page,
            sort,
            distance,
            location,
            startDate,
            endDate,
            country,
            currency,
            timeZone,
            voucher,
            headOfficeId,
            showHeadOffice,
        };
        let search = new Search(params);
        let response = await search.getResults();

        if (
            'voucher' in data == false ||
            data.voucher.current.key === 'no'
        ) {
            // No results so need to attempt an increased radius search first unless already run
            if (
                response.total === 0 &&
                'session' in data &&
                data.session.current.key === 'location'
            ) {
                // No results so need to attempt an increased radius search first unless already run
                if (
                    data.activeTabIndex === 1 &&
                    distance !== defaultRanges.maxDistance
                ) {
                    setShowExpandSearch(true);
                    setRangeDefaultDistance(defaultRanges.maxDistance);
                    handleRangeChange(defaultRanges.maxDistance);
                } else if (response.total < 3 && response.total > 0) {
                    setShowLimitedResultsUpsell(true);
                } else if (data.activeTabIndex !== 3 && response.total <= 0) {
                    setShowNoResultsOnline(true);
                }
            }
        } else {
            setShowExpandSearch(false);
            setShowNoResultsOnline(false);
            setShowLimitedResultsUpsell(false);
        }

        if (
            response.total < 3 &&
            (('sessionType' in data &&
                data.sessionType.current.key === 'live-group') ||
                (data.sessionType.current.key === 'group' &&
                    data.activeTabIndex === 3))
        ) {
            params.showHeadOffice = true;
            let search = new Search(params);
            response = await search.getResults();
        }

        setFranchiseMessage(
            await fetchFranchiseMessage(country, data.addiction),
        );
        setAvailableAppointments(response.total);
        setResults(response.results);
        setPaginationVars(response.pagination);
        setLoading(false);

        if (resultsList.current !== null) {
            resultsList.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (bookingState.addiction.current.id !== 390) {
            fetchResults(bookingState);
        } else {
            setLoading(false);
        }
    }, [
        bookingState.voucher,
        bookingState.session,
        bookingState.sessionType,
        bookingState.sessionCount?.value,
        bookingState.location?.value,
    ]);

    useEffect(() => {
        setDefaultRanges();
    }, [bookingState.country]);

    const handleTabChange = index => {
        setActiveTabIndex(index);

        let oldData = bookingState;
        let newData = {
            ...oldData,
            activeTabIndex: index,
        };
        setBookingState(newData);

        fetchResults(newData);
    };

    const handleRangeChange = value => {
        let oldData = bookingState;
        let newData = {
            ...oldData,
            distance: value,
        };
        setBookingState(newData);

        fetchResults(newData);
    };

    const handleDateSelection = (key, date) => {
        let oldData = bookingState;
        let newData = {
            ...oldData,
            [key]: date,
        };
        setBookingState(newData);

        fetchResults(newData);
    };

    const showNoResultsMessage = () => {
        if (bookingState.addiction.current.id === 390) return;

        if (activeTabIndex === 1) {
            return (
                <div className="no-results-error">
                    <NoResults
                        key="no-product-item-1"
                        primaryMessage="Unfortunately, we couldn't find any seminars within the selected distance range. Please try broadening your search."
                        primaryIcon="info"
                    />
                </div>
            );
        } else if (activeTabIndex === 2 || activeTabIndex === 3) {
            return (
                <div className="no-results-error">
                    <NoResults
                        key="no-product-item-2"
                        primaryMessage="Unfortunately, we couldn't find any seminars within the selected date range. Please try broadening your search."
                        primaryIcon="info"
                    />
                </div>
            );
        }
    };

    const showLimitedResultsUpsellMessage = () => {
        if (bookingState.addiction.current.id === 390) return;

        if (
            showLimitedResultsUpsell &&
            (activeTabIndex === 1 || activeTabIndex === 2)
        ) {
            return (
                <div className="no-results-small">
                    <NoResults
                        key="limited-product-item-1"
                        primaryMessage="You may be interested to know that we also have live online seminars available. You will benefit from the same live interactive seminar with the same success rate hosted on Zoom by our highly trained therapists."
                        primaryIcon={<SessionOnlineIcon />}
                        buttonText="View online dates"
                        onClick={() => handleTabChange(3)}
                    />
                </div>
            );
        }
    };

    const showNoResultsOnlineMessage = () => {
        if (bookingState.addiction.current.id === 390) return;

        if (showNoResultsOnline && activeTabIndex !== 3) {
            return (
                <div className="no-results-error">
                    <NoResults
                        key="no-product-item-1"
                        primaryMessage="Unfortunately, we couldn't find any seminars within the selected range. Alternatively, we offer Live Online Group Seminars. You will benefit from the same live and interactive seminar hosted on Zoom by our highly trained therapists."
                        primaryIcon="info"
                        buttonText="View online dates"
                        buttonCssClass="button--white button--no-border"
                        onClick={() => handleTabChange(3)}
                    />
                </div>
            );
        }
    };

    const showExpandSearchMessage = () => {
        if (bookingState.addiction.current.id === 390) return;

        if (showExpandSearch && activeTabIndex === 1 && !showNoResultsOnline) {
            return (
                <div className="no-results-error">
                    <NoResults
                        key="expand-search-product-item-1"
                        primaryMessage="Unfortunately, we couldn't find any seminars within your local area, so have expanded the search range."
                        primaryIcon="info"
                    />
                </div>
            );
        }
    };

    const showErrorMessages = () => {
        const typeLabel =
            bookingState.sessionType.current.key === 'ovp'
                ? 'on-demand video programme'
                : 'seminar';
        return errorMessages.length > 0 && 
            errorMessages.map((errorMessage, index) => (
                <div className="no-results-error" key={`cart-error-message-wrapper-${index}`}>
                    <NoResults
                        key={`cart-error-message-${index}`}
                        primaryMessage={errorMessage}
                        primaryIcon="info"
                    />
                </div>
            )
        );
    };

    const handleErrorMessages = errors => {
        setErrorMessages(errors);

        if (errors.length > 0 && resultsList.current !== null) {
            resultsList.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleUpsell = () => {
        let oldData = bookingState;

        if (oldData.location) {
            delete oldData.location;
        }

        // Need to set up sell flag to apply discount at checkout only if previous state was OVP
        let newData = {
            ...oldData,
            upsell: bookingState.sessionType.current.key === 'ovp', 
            session: {
                current: {
                    name: 'Online',
                    id: 2,
                    key: 'online',
                },
            },
            sessionType: {
                current: {
                    name: 'Live Group Seminar',
                    id: 1,
                    key: 'live-group',
                },
            },
            sessionCount: {
                title: '1 Person',
                value: 1,
            },
        };

        setBookingState(newData);
    };

    const handleFranchiseMessageClose = () => {
        setShowFranchiseMessage(false);
    };

    const drawResults = () => {
        if (bookingState.addiction.current.id === 390) return;

        return (
            <>
                {results.map((item, index) => (
                    <ResultsCard
                        key={'product-item-' + index}
                        item={item}
                        type={bookingState.sessionType.current.key}
                        preferType={bookingState.session.current.key}
                        handleErrorMessages={handleErrorMessages}
                        handleUpsell={handleUpsell}
                    />
                ))}
            </>
        );
    };

    const handleNextChange = () => {
        if (paginationVars.current < paginationVars.total) {
            fetchResults(bookingState, paginationVars.current + 1);
        }
    };

    const handlePrevChange = () => {
        if (
            paginationVars.current > 1 &&
            paginationVars.current <= paginationVars.total
        ) {
            fetchResults(bookingState, paginationVars.current - 1);
        }
    };

    return (
        <div className="booking__results-list" ref={resultsList}>
            {bookingState.sessionType &&
                bookingState.sessionType.current.key === 'group' &&
                ('voucher' in bookingState == false ||
                    bookingState.voucher.current.key === 'no') && (
                    <BookingTabs
                        activeTabIndex={activeTabIndex}
                        handleTabChange={handleTabChange}
                        showOnline={
                            bookingState.sessionType.current.key === 'group'
                        }
                    />
                )}
            {!showFranchiseMessage &&
                activeTabIndex === 1 &&
                bookingState.sessionType &&
                bookingState.sessionType.current.key === 'group' &&
                ('voucher' in bookingState == false ||
                    bookingState.voucher.current.key === 'no') && (
                    <div className="booking-tab-content booking-tab-content--soonest">
                        <RangeSlider
                            min={0}
                            max={rangeMaxDistance}
                            step={5}
                            measurement={'mi'}
                            defaultValue={rangeDefaultDistance}
                            handleRangeChange={handleRangeChange}
                        />
                        <ResultsFound
                            count={availableAppointments}
                            label={
                                bookingState.sessionType.current.key === 'ovp'
                                    ? 'OVP'
                                    : 'Seminar'
                            }
                        />
                    </div>
                )}
            {!showFranchiseMessage &&
                (activeTabIndex === 2 ||
                    activeTabIndex === 3 ||
                    (bookingState.sessionType &&
                        (bookingState.sessionType.current.key ===
                            'live-group' ||
                            bookingState.sessionType.current.key ===
                                'taster'))) &&
                ('voucher' in bookingState == false ||
                    bookingState.voucher.current.key === 'no') && (
                    <div className="booking-tab-content booking-tab-content--distance">
                        <DateSelection
                            handleDateSelection={handleDateSelection}
                            availableAppointments={availableAppointments}
                        />
                    </div>
                )}
            {loading && <LoadingIcon />}
            {!loading &&
                showFranchiseMessage &&
                Object.keys(franchiseMessage).length > 0 && (
                    <FranchiseMessage
                        title={franchiseMessage.info_title}
                        content={franchiseMessage.info_text}
                        altContent={franchiseMessage.info_alt_text}
                        buttonText={franchiseMessage.link_text}
                        buttonUrl={franchiseMessage.link}
                        onClick={handleFranchiseMessageClose}
                        showComponent={showFranchiseMessage}
                    />
                )}
            {!loading &&
                bookingState.addiction?.current?.id === 390 &&
                bookingState.addiction.current?.form_id && (
                    <Form
                        formId={Number(bookingState.addiction.current.form_id)}
                    />
                )}
            {!loading && showErrorMessages()}
            {!loading && showNoResultsOnlineMessage()}
            {!loading && showExpandSearchMessage()}
            {!loading &&
                !showFranchiseMessage &&
                !showNoResultsOnline &&
                (Object.keys(results).length === 0 ||
                    !bookingState.sessionType) &&
                showNoResultsMessage()}
            {!loading &&
                !showFranchiseMessage &&
                Object.keys(results).length > 0 &&
                bookingState.sessionType &&
                drawResults()}
            {!loading && showLimitedResultsUpsellMessage()}
            {!loading &&
                !showFranchiseMessage &&
                availableAppointments > perPage &&
                paginationVars && (
                    <Pagination
                        perPage={perPage}
                        totalCount={availableAppointments}
                        onPrevChange={handlePrevChange}
                        onNextChange={handleNextChange}
                        paginationVars={paginationVars}
                        showCount={false}
                    />
                )}
        </div>
    );
};
export default BookingTabsContainer;
