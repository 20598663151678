import moment from 'moment-timezone';

export function copyToClipboard(string) {
    const element = document.createElement('textarea');
    let storeContentEditable = element.contentEditable;
    let storeReadOnly = element.readOnly;

    element.value = string;
    element.contentEditable = true;
    element.readOnly = false;
    element.setAttribute('readonly', false);
    element.setAttribute('contenteditable', true);
    element.style.position = 'absolute';
    element.style.left = '-9999px';
    document.body.appendChild(element);

    const selected =
        document.getSelection().rangeCount > 0
            ? document.getSelection().getRangeAt(0)
            : false;
    element.select();
    element.setSelectionRange(0, 999999);
    document.execCommand('copy');
    document.body.removeChild(element);

    if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
    }

    element.contentEditable = storeContentEditable;
    element.readOnly = storeReadOnly;
}

export function debounce(callback, wait) {
    let timeout;
    return (...args) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), wait);
    };
}

export async function saveAddiction(addiction) {
    const args = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `action=set_addiction&security=${wp_ajax.security}&addiction=${addiction}`,
    };

    try {
        localStorage.setItem('addiction', addiction);
        const response = await fetch(wp_ajax.ajax_url, args);

        if (!response.ok && response.status !== 200) {
            throw new Error(`HTTP error: ${response.status}`);
        }

        const json = await response.json();
        return json;
    } catch (error) {
        console.error('Set Addiction Error: ', error);
    }
}

export function addVideoProgramViewLog(
    productId,
    videoId,
    orderId,
    userId,
    percentComplete,
    timeWatched,
    startDate,
    endDate,
    corporateId,
) {
    let data = new FormData();

    data.append('security', wp_ajax.security);
    data.append('product_id', productId);
    data.append('video_id', videoId);
    data.append('order_id', orderId);
    data.append('user_id', userId);
    data.append('percent_complete', percentComplete);
    data.append('time_watched', timeWatched);
    data.append('start_date', startDate);
    data.append('end_date', endDate);
    data.append('corporate_id', corporateId);

    fetch(
        `${wp_ajax.ajax_url}?action=add_video_program_view_log`,
        {
            method: 'POST',
            body: data,
        }
    )
        .then(response => response.json())
        .then(response => {
            console.log(
                'Add Video Program View Log Addiction: ',
                response.status,
                response,
            );
        })
        .catch(error => {
            console.error('Add Video Program View Log Addiction: ', error);
        });
}

export const getNextUntil = (elem, selector) => {
    const siblings = [];
    let next = elem.nextElementSibling;

    while (next) {
        if (selector && next.matches(selector)) break;
        siblings.push(next);
        next = next.nextElementSibling;
    }

    return siblings;
};

export const wrapAll = (nodes, wrapper) => {
    const parent = nodes[0].parentNode;
    const previousSibling = nodes[0].previousSibling;

    for (var i = 0; nodes.length - i; wrapper.firstChild === nodes[0] && i++)
        wrapper.appendChild(nodes[i]);

    parent.insertBefore(
        wrapper,
        previousSibling ? previousSibling.nextSibling : parent.firstChild,
    );

    return wrapper;
};

export async function getGeoCountry() {
    let country =
        document.cookie.match('(^|;)\\s*geo-country\\s*=\\s*([^;]+)')?.pop() ||
        '';
    let currency =
        document.cookie.match('(^|;)\\s*geo-currency\\s*=\\s*([^;]+)')?.pop() ||
        '';
    let timezone =
        document.cookie.match('(^|;)\\s*geo-timezone\\s*=\\s*([^;]+)')?.pop() ||
        '';

    if (country === '' || currency === '' || timezone === '') {
        await fetch(wp_ajax.ajax_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `action=get_geo_country&security=${wp_ajax.security}`,
        })
            .then(response => response.json())
            .then(response => {
                if ('country' in response.data) {
                    country = response.data.country;
                }

                if ('currency' in response.data) {
                    currency = response.data.currency;
                }

                if ('timezone' in response.data) {
                    timezone = response.data.timezone;
                }
            })
            .catch(error => {
                console.error('Get GEO Country: ', error);
            });

        if (country !== '') {
            document.cookie = `geo-country=${country}; path=/; Secure`;
        }

        if (currency !== '') {
            document.cookie = `geo-currency=${currency}; path=/; Secure`;
        }

        if (timezone !== '') {
            document.cookie = `geo-timezone=${timezone}; path=/; Secure`;
        }
    }

    return {
        country: country,
        currency: currency,
        timezone: timezone,
    };
}

export async function getGeoPrice(price, returnFormat = 'display') {
    await fetch(wp_ajax.ajax_url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `action=get_geo_price&security=${wp_ajax.security}&price=${price}`,
    })
        .then(response => response.json())
        .then(response => {
            price =
                returnFormat === 'display'
                    ? `${response.data.currencySymbol}${response.data.price}`
                    : response.data;
        })
        .catch(error => {
            console.error('Get GEO Price: ', error);
        });

    return price;
}

export async function getGeoPrices(prices, returnFormat = 'display') {
    const pricesCommaSep = prices.join(',');
    await fetch(wp_ajax.ajax_url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `action=get_geo_prices&security=${wp_ajax.security}&prices=${pricesCommaSep}`,
    })
        .then(response => response.json())
        .then(response => {
            prices =
                returnFormat === 'display'
                    ? Array.from(
                          response.data.prices,
                          price => `${price.currencySymbol}${price.price}`,
                      )
                    : response.data;
        })
        .catch(error => {
            console.error('Get GEO Prices: ', error);
        });

    return prices;
}

export async function addToCart(
    productId,
    quantity = 1,
    variationId = 0,
    upsell = false,
    extend = false,
    couponCode = '',
) {
    let data = new FormData();
    let status = false;

    data.append('security', wp_ajax.security);
    data.append('product_id', productId);
    data.append('variation_id', variationId);
    data.append('quantity', quantity);
    data.append('upsell', upsell);
    data.append('extend', extend);
    data.append('coupon_code', couponCode);

    await fetch(
        `${wp_ajax.ajax_url}?action=add_to_cart`,
        {
            method: 'POST',
            body: data,
        }
    )
        .then(response => response.json())
        .then(response => {
            status = response;
            if (typeof fbq !== 'undefined') {
                fbq('track', 'AddToCart');
            }
        })
        .catch(error => {
            console.error('Add to cart: ', error);
        });

    return status;
}

export async function checkStockAvailability(
    productId,
    variationId = 0
) {
    let data = new FormData();
    let status = false;

    data.append('security', wp_ajax.security);
    data.append('product_id', productId);
    data.append('variation_id', variationId);

    await fetch(
        `${wp_ajax.ajax_url}?action=check_stock_availability`,
        {
            method: 'POST',
            body: data,
        }
    )
        .then(response => response.json())
        .then(response => {
            status = response;
        })
        .catch(error => {
            console.error('Check stock availability: ', error);
        });

    return status;
}

export function normalizePostcode(postcode) {
    postcode = postcode.toUpperCase().trim();
    return postcode.replaceAll(/[\s\-]/g, '');
}

/**
 * This matches the backend validation WC_Validation::is_gb_postcode
 * 
 * @param {string} postcode
 * @returns 
 */
export function validatePostcodeGB(postcode) {
    // Permitted letters depend upon their position in the postcode.
    // https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom#Validation.
    const alpha1 = '[abcdefghijklmnoprstuwyz]'; // Character 1.
    const alpha2 = '[abcdefghklmnopqrstuvwxy]'; // Character 2.
    const alpha3 = '[abcdefghjkpstuw]';         // Character 3 == ABCDEFGHJKPSTUW.
    const alpha4 = '[abehmnprvwxy]';            // Character 4 == ABEHMNPRVWXY.
    const alpha5 = '[abdefghjlnpqrstuwxyz]';    // Character 5 != CIKMOV.

    let pcexp = [];

    // Expression for postcodes: AN NAA, ANN NAA, AAN NAA, and AANN NAA.
    pcexp[0] = new RegExp('^(' + alpha1 + '{1}' + alpha2 + '{0,1}[0-9]{1,2})([0-9]{1}' + alpha5 + '{2})$');

    // Expression for postcodes: ANA NAA.
     pcexp[1] = new RegExp('^(' + alpha1 + '{1}[0-9]{1}' + alpha3 + '{1})([0-9]{1}' + alpha5 + '{2})$');

    // Expression for postcodes: AANA NAA.
    pcexp[2] = new RegExp('^(' + alpha1 + '{1}' + alpha2 + '[0-9]{1}' + alpha4 + ')([0-9]{1}' + alpha5 + '{2})$');

    // Exception for the special postcode GIR 0AA.
    pcexp[3] = new RegExp('^(gir)(0aa)$');

    // Standard BFPO numbers.
    pcexp[4] = new RegExp('^(bfpo)([0-9]{1,4})$');

    // c/o BFPO numbers.
    pcexp[5] = new RegExp('^(bfpo)(c\/o[0-9]{1,3})$');

    // Load up the string to check, converting into lowercase and removing spaces.
    postcode = postcode.toLowerCase();
    postcode = postcode.replaceAll(/\s/g, '');

    // Assume we are not going to find a valid postcode.
    let valid = false;

    // Check the string against the six types of postcodes.
    for (const regexp of pcexp) {
        if (regexp.test(postcode)) {
            // Remember that we have found that the code is valid and break from loop.
            valid = true;
            break;
        }
    }

    return valid;
}

/**
 * This matches the backend validation WC_Validation::is_postcode
 * 
 * @param {string} postcode 
 * @param {string} country 
 * @returns 
 */
export function validatePostcode(postcode, country = 'GB') {
    let valid = false;

    // No need to continue validating if there are invalid characters present
    if (postcode.replaceAll(/[\s\-A-Za-z0-9]/g, '').length <= 0) {
        switch (country.toUpperCase()) {
            case 'AT':
            case 'BE':
            case 'CH':
            case 'HU':
            case 'NO':
                valid = /^([0-9]{4})$/.test(postcode);
                break;
            case 'BA':
                valid = /^([7-8]{1})([0-9]{4})$/.test(postcode);
                break;
            case 'BR':
                valid = /^([0-9]{5})([-])?([0-9]{3})$/.test(postcode);
                break;
            case 'DE':
                valid = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/.test(postcode);
                break;
            case 'DK':
                valid = /^(DK-)?([1-24-9]\d{3}|3[0-8]\d{2})$/.test(postcode);
                break;
            case 'ES':
            case 'FR':
            case 'IT':
                valid = /^([0-9]{5})$/i.test(postcode);
                break;
            case 'GB':
                valid = validatePostcodeGB(postcode);
                break;
            case 'IE':
                postcode = normalizePostcode(postcode);
                valid = /([AC-FHKNPRTV-Y]\d{2}|D6W)[0-9AC-FHKNPRTV-Y]{4}/.test(postcode);
                break;
            case 'IN':
                valid = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/.test(postcode);
                break;
            case 'JP':
                valid = /^([0-9]{3})([-]?)([0-9]{4})$/.test(postcode);
                break;
            case 'PT':
                valid = /^([0-9]{4})([-])([0-9]{3})$/.test(postcode);
                break;
            case 'PR':
            case 'US':
                valid = /^([0-9]{5})(-[0-9]{4})?$/i.test(postcode);
                break;
            case 'CA':
                // CA Postal codes cannot contain D,F,I,O,Q,U and cannot start with W or Z. https://en.wikipedia.org/wiki/Postal_codes_in_Canada#Number_of_possible_postal_codes.
                valid = /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ])([\ ])?(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/i.test(postcode);
                break;
            case 'PL':
                valid = /^([0-9]{2})([-])([0-9]{3})$/.test(postcode);
                break;
            case 'CZ':
            case 'SK':
                valid = /^([0-9]{3})(\s?)([0-9]{2})$/.test(postcode);
                break;
            case 'NL':
                valid = /^([1-9][0-9]{3})(\s?)(?!SA|SD|SS)[A-Z]{2}$/i.test(postcode);
                break;
            case 'SI':
                valid = /^([1-9][0-9]{3})$/.test(postcode);
                break;
            case 'LI':
                valid = /^(94[8-9][0-9])$/.test(postcode);
                break;
            default:
                valid = true;
                break;
        }
    }

    return valid;
}

export async function refreshTicketUserFields(
    country,
    productId,
    personId,
    values = {},
) {
    let data = new FormData();
    let html = '';

    data.append('security', wp_ajax.security);
    data.append('country', country);
    data.append('product_id', productId);
    data.append('person_id', personId);

    // We need to set the key name in post data so that WooCommerce helper can fetch from post data
    if (Object.keys(values).length > 0) {
        for (const key of Object.keys(values)) {
            data.append(key, values[key]);
        }
    }

    await fetch(`${wp_ajax.ajax_url}?action=refresh_ticket_user_fields`, {
        method: 'POST',
        body: data,
    })
        .then(response => response.json())
        .then(response => {
            html = response.html;
        })
        .catch(error => {
            console.error('Refresh Ticket User Fields: ', error);
        });

    return html;
}

export async function googleGeocodeSearch(address, region = 'UK') {
    let location = {
        value: `${address}`,
        address: '',
        country: region,
        lat: 0,
        lng: 0,
    };

    return await fetchGoogleGeocode(location);
}

const fetchGoogleGeocode = async location => {
    const apiKey = process.env.GOOGLE_MAP_API;

    try {
        const response = await fetch(
            `${process.env.GEOCODE_URL}${location.value}&key=${apiKey}&region=${location.country}`,
            {
                method: 'GET',
            },
        );
        const responseJSON = await response.json();

        if (responseJSON.status === 'ZERO_RESULTS') {
            throw new Error(responseJSON.status);
        }

        if (responseJSON.status === 'OK') {
            if (
                'results' in responseJSON &&
                responseJSON.results[0].geometry.location !== 'undefined'
            ) {
                location.address = `${responseJSON.results[0].formatted_address}`;
                location.lat = parseFloat(
                    responseJSON.results[0].geometry.location.lat,
                );
                location.lng = parseFloat(
                    responseJSON.results[0].geometry.location.lng,
                );
            }

            // Need to fetch the country from the address components
            for (
                var i = 0;
                i < responseJSON.results[0].address_components.length;
                i++
            ) {
                if (
                    responseJSON.results[0].address_components[i].types[0] ===
                    'country'
                ) {
                    location.country =
                        responseJSON.results[0].address_components[
                            i
                        ].short_name;
                }

                if (
                    responseJSON.results[0].address_components[i].types
                        .length === 2
                ) {
                    if (
                        responseJSON.results[0].address_components[i]
                            .types[0] === 'political'
                    ) {
                        location.country =
                            responseJSON.results[0].address_components[
                                i
                            ].short_name;
                    }
                }
            }
        }

        if (
            'error_message' in responseJSON &&
            responseJSON.error_message !== ''
        ) {
            console.error('Google API Error: ', responseJSON.error_message);
        }
    } catch (error) {
        console.error('Google API Error: ', error);

        // Check if location value contains the country/region code, if not retry the
        // search by updating location address with region code
        if (!location.value.includes(location.country)) {
            location.value = `${location.value},${location.country}`;

            return await fetchGoogleGeocode(location);
        }
    }

    return location;
};

export function registerAddToCartTargets() {
    const addToCartButtons = document.querySelectorAll(
        '[data-behaviour*="add-to-cart"]',
    );

    if (addToCartButtons.length > 0) {
        addToCartButtons.forEach(addToCartButton => {
            addToCartButton.addEventListener('click', async e => {
                e.preventDefault();
                const cartStatus = await addToCart(
                    addToCartButton.dataset.product,
                    addToCartButton.dataset.quantity,
                    addToCartButton.dataset.variation,
                    'upsell' in addToCartButton.dataset
                        ? addToCartButton.dataset.upsell
                        : false,
                    'extend' in addToCartButton.dataset
                        ? addToCartButton.dataset.extend
                        : false,
                );

                window.location.href = cartStatus.status === 'error'
                    ? `${wp_ajax.home_url}basket/`
                    : `${wp_ajax.home_url}checkout/`;
            });
        });
    }
}

export async function fetchGeoImage(
    id,
    size = '',
    classes = '',
    partial = true,
) {
    let form = new FormData();
    let imageData;

    form.append('security', wp_ajax.security);
    form.append('image_id', id);

    if (size) {
        form.append('size', size);
    }

    if (classes) {
        form.append('classes', classes);
    }

    if (partial) {
        form.append('partial', partial);
    }

    await fetch(`${wp_ajax.ajax_url}?action=ajax-image`, {
        method: 'POST',
        body: form,
    })
        .then(response => response.json())
        .then(data => {
            imageData = data?.html;
        })
        .catch(error => {
            console.error('Error:', error);
        });

    return imageData;
}

export async function applyShortcodes(html) {
    let form = new FormData();

    form.append('security', wp_ajax.security);
    form.append('html', html);

    await fetch(`${wp_ajax.ajax_url}?action=apply_shortcodes`, {
        method: 'POST',
        body: form,
    })
        .then(response => response.json())
        .then(data => {
            html = data?.html;
        })
        .catch(error => {
            console.error('Apply Shortcodes Error:', error);
        });

    return html;
}

export function convertToPrice(number, symbol = '£') {
    return `${symbol}${parseFloat(number).toFixed(2)}`;
}

export function discountPrice(number, percentage) {
    return parseFloat(number) * ((100 - parseFloat(percentage)) / 100);
}

export function discountTenPercent(number) {
    return discountPrice(number, 10);
}

export function getLocalTimeZone(short = false, daylightSavings = false, country = '') {
    const timeZone = moment.tz.guess();
    const now = new Date();

    // Need to make sure that we get the non-daylight savings timezone which Austraila starts on different date
    if (!daylightSavings) {
        const daylightSavingsMonth = ['AU', 'NZ'].includes(country) ? 4 : 0;
        now.setMonth(daylightSavingsMonth, 1);
    }

    const newDate = moment.tz(now, timeZone);

    return short ? newDate.format('z') : timeZone;
}

/**
 * Check if Gravity Form Section has completed all sections
 * @param {node} wrapper
 * @returns boolean if missing required form returns true else false
 */
export function checkCompletedFields(wrapper, onLoad = false) {
    const childNodes = wrapper.childNodes;
    let notFilledOut = 0;
    /**
     * loop through child nodes and verify parent gfield required
     */
    Array.from(childNodes).every(node => {
        const classes = node.classList;
        //if parent is required verify that el is filled out or selected
        //display none check is needed as multiforms apply inline styles
        if (
            (classes.contains('gfield_contains_required') ||
                classes.contains('captcha_wrapper')) &&
            node.style.display !== 'none'
        ) {
            //get all form elements
            const selects = node.querySelectorAll('select');
            const inputsCheckBoxes = node.querySelectorAll(
                '.ginput_container_checkbox',
            );
            const inputsNotCheckboxes = node.querySelectorAll(
                'input:not([type=checkbox])',
            );
            const textareas = node.querySelectorAll('textarea');
            const recaptcha = node.querySelector('.ginput_recaptcha');

            //verify recaptcha grecaptcha is function from recaptcha
            if (recaptcha && onLoad) {
                notFilledOut++;
                return false;
            } else if (recaptcha && !onLoad && grecaptcha) {
                if (grecaptcha.getResponse().length !== 0) {
                    return true;
                } else {
                    notFilledOut++;
                    return false;
                }
            }

            // verify selects
            if (selects.length) {
                selects.forEach(select => {
                    if (!select.value) {
                        notFilledOut++;
                        return false;
                    }
                    return true;
                });
            }
            //verify inputs not checkboxes
            if (inputsNotCheckboxes) {
                inputsNotCheckboxes.forEach(input => {
                    if (
                        (input.hasAttribute('required') ||
                            input.getAttribute('aria-required')) &&
                        !input.value
                    ) {
                        notFilledOut++;
                        return false;
                    }
                    return true;
                });
            }
            //verify input checkboxes
            if (inputsCheckBoxes) {
                inputsCheckBoxes.forEach(checkboxWrapper => {
                    const selected = checkboxWrapper.querySelectorAll(
                        'input[type="checkbox"]:checked',
                    );
                    if (!selected.length) {
                        notFilledOut++;
                        return false;
                    }
                    return true;
                });
            }
            //verify textareas
            if (textareas) {
                textareas.forEach(textarea => {
                    if (textarea.hasAttribute('required') && !textarea.value) {
                        notFilledOut++;
                        return false;
                    }
                    return true;
                });
            }
        }

        return true;
    });

    return notFilledOut ? true : false;
}
/**
 * Set form Section Attrs for a gravity form
 * @param {Node} section
 */
export function setFormSectionAttrs(section, onLoad = false) {
    //Check if any required fields
    let requiredFields = checkCompletedFields(section, onLoad);
    if (requiredFields) {
        section.setAttribute('data-accordion-success', false);
        section.classList.remove('completed');
        section.classList.remove('validation_error');
    } else {
        section.setAttribute('data-accordion-success', true);
        section.classList.remove('validation_error');
        section.classList.add('completed');
    }
    //Add a cross to section only if form has been submitted
    if (onLoad && section.querySelector('.gfield_error')) {
        section.setAttribute('data-accordion-success', false);
        section.classList.remove('completed');
        section.classList.add('validation_error');
    }
}

/**
 * pushHistory
 * appends or replaces a url parameter if it exists
 * @param {string} type
 * @param {many} value
 * @returns
 */
export const pushHistory = (type, value) => {
    if (!window.history) return;

    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let url = getBaseUrl();

    if (urlParams.has(type)) {
        urlParams.set(type, value);
        url += `?${urlParams}`;
    } else {
        urlParams.append(type, value);
        url += `?${urlParams}`;
    }

    window.history.pushState({ path: url }, '', url);
    return;
};

/**
 * replaceHistory
 * delete a passed in url parameter
 * it checks the query string on current page
 * @param {string} type
 * @returns
 */
export const replaceHistory = type => {
    if (!window.history) return;

    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let url = getBaseUrl();

    if (urlParams.has(type)) {
        urlParams.delete(type);
        //ensures that any other remaining params are kept
        if (urlParams.toString().length) {
            url += `?${urlParams}`;
            window.history.pushState({ path: url }, '', url);
        } else {
            window.history.pushState({ path: url }, '', url);
        }
    }

    return;
};

/**
 * getPagedFromUrl
 * get paged valued from url
 * @returns integer current paged value or 1
 */
export const getPagedFromUrl = () => {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);

    return urlParams.get('current-page')
        ? Number(urlParams.get('current-page'))
        : 1;
};

/**
 * getParamFromUrl
 * get any param from the query string
 * @param {string} param
 * @returns
 */
export const getParamFromUrl = param => {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let value = urlParams.get(param);

    return value ?? null;
};

/**
 * getBaseUrl
 * @returns string url
 */
export const getBaseUrl = () => {
    let url = `${window.location.protocol}//`;
    url += window.location.host;
    url += window.location.pathname;

    return url;
};

/**
 *
 * @param {string} timezone
 * @param {date object} date
 * @returns date
 */
export const setTimezone = (timezone, date) => {
    return new Date(date).toLocaleString('en', { timeZone: timezone });
};

export function degToRad(d) {
    return d * (Math.PI / 180);
}

export function radToDeg(r) {
    return r * (180 / Math.PI);
}

/**
 * Taken from https://stackoverflow.com/questions/53910204/elasticsearch-is-there-a-way-i-can-combine-geo-bounding-box-with-distance/53984133#53984133} 
 * 
 * @param {float} lat 
 * @param {float} lon 
 * @param {integer} bearing 
 * @param {integer} distance 
 * @returns object
 */
export function destination(lat, lon, bearing, distance) {
    const radius = 3963.19;
    const rLat = degToRad(lat);
    const rLon = degToRad(lon);
    const rBearing = degToRad(bearing);
    const rAngDist = distance / radius;

    const rLatB = Math.asin(Math.sin(rLat) * Math.cos(rAngDist) + Math.cos(rLat) * Math.sin(rAngDist) * Math.cos(rBearing));

    const rLonB = rLon + Math.atan2(Math.sin(rBearing) * Math.sin(rAngDist) * Math.cos(rLat), Math.cos(rAngDist) - Math.sin(rLat) * Math.sin(rLatB));

    return {
        lat: radToDeg(rLatB),
        lon: radToDeg(rLonB)
    }
}

export async function getCategoryMenu(category) {
    let data = new FormData();
    data.append('security', wp_ajax.security);
    data.append('category', category);

    try {
        const response = await fetch(
            `${wp_ajax.ajax_url}?action=get_category_menu`,
            {
                method: 'POST',
                body: data,
            }
        );

        if (!response.ok && response.status !== 200) {
            throw new Error(`HTTP error: ${response.status}`);
        }

        const json = await response.json();
        return json;
    } catch (error) {
        console.error('Get Category Menu Error: ', error);
    }
}

export async function loginCustomer(data) {
    let status = 'error';
    let errors = [];
    if (data instanceof FormData == false) {
        data = new FormData();
    }

    data.append('security', wp_ajax.security);

    await fetch(
        `${wp_ajax.ajax_url}?action=login_ajax`,
        {
            method: 'POST',
            body: data,
        }
    )
        .then(response => response.json())
        .then(response => {
            status = response?.status;
            errors = response?.errors;
        })
        .catch(error => {
            console.error('Login Customer: ', error);
            errors.push(error);
        });

    return {
        status,
        errors
    }
}

export async function registerCustomer(data) {
    let status = 'error';
    let errors = [];
    if (data instanceof FormData == false) {
        data = new FormData();
    }

    data.append('security', wp_ajax.security);

    await fetch(
        `${wp_ajax.ajax_url}?action=register_ajax`,
        {
            method: 'POST',
            body: data,
        }
    )
        .then(response => response.json())
        .then(response => {
            status = response?.status;
            errors = response?.errors;
        })
        .catch(error => {
            console.error('Register Customer: ', error);
            errors.push(error);
        });

    return {
        status,
        errors
    }
}
