/* eslint-disable max-len */
const fields = document.querySelectorAll('[data-behaviour="addiction-field"]');
if (fields.length >= 1) {
    fields.forEach((field) => {
        const addictions = field.querySelectorAll('[data-behaviour="addiction-field-selection"]');
        const selectBox = field.querySelector('[data-behaviour="addiction-field-select"]');

        selectBox.addEventListener('change', (e) => {
            const addiction = field.querySelector(`[data-value="${selectBox.value}"]`);
            var event = new Event('click', {
                source: 'select'
            })
            addiction.dispatchEvent(event);
        });

        addictions.forEach((addiction) => {
            addiction.addEventListener('click', (e) => {
                e.preventDefault();
                if (!addiction.classList.contains('addiction-field__selection--selected')) {
                    // clean up any already selected tabs
                    addictions.forEach((cur) => {
                        if (cur.classList.contains('addiction-field__selection--selected')) {
                            cur.classList.remove('addiction-field__selection--selected');
                        }
                    });
                    addiction.classList.add('addiction-field__selection--selected');

                    if (e.source !== 'select') {
                        selectBox.value = addiction.dataset.value;
                    }
                }
            });
        });
    });
}
