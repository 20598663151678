import React, { useEffect, useState } from 'react';
import QuoteOpenIcon from '../Icons/QuoteOpenIcon';
import QuoteCloseIcon from '../Icons/QuoteCloseIcon';
import ReviewStarIcon from '../Icons/ReviewStarIcon';

const Review = ({ review }) => {
    const [showMore, setShowMore] = useState(false);
    const [title, setTitle] = useState(null);
    const [quote, setQuote] = useState(null);
    const [testimonial, setTestimonial] = useState(null);
    const [excerpt, setExcerpt] = useState(null);
    const [country, setCountry] = useState(null);
    const [author, setAuthor] = useState(null);
    const [isCeleb, setIsCeleb] = useState(false);
    const [isCorporate, setIsCorporate] = useState(false);
    const [isMedia, setIsMedia] = useState(false);
    const [featuredImage, setFeaturedImage] = useState(null);
    const [addiction, setAddiction] = useState(null);
    const [reviews, setReviews] = useState(null);
    const [schema, setSchema] = useState(null);

    useEffect(() => {
        setShowMore(Boolean(review.show_more));
        setTitle(review.title ?? null);
        setQuote(review.testimonial_quote ?? null);
        setTestimonial(review.testimonial_text ?? null);
        setExcerpt(review.testimonial_excerpt ?? null);
        setCountry(review.country ?? null);
        setAuthor(review.author ?? null);
        setIsCeleb(review.type == 'celebrity' ?? false);
        setIsCorporate(review.testimonial_corporate ?? false);
        setIsMedia(review.testimonial_media ?? false);
        setFeaturedImage(review.featured_image ?? null);
        setAddiction(review.testimonial_addiction ?? null);
        setReviews(review.testimonial_addiction ? Number(review.testimonial_rating) : null);
        setSchema(review.schema ?? null);
    }, [review]);

    function handleShowMore() {
        setShowMore(false);
    }
    return (
        <li
            className={
                'client-reviews__review' +
                (isCeleb ? ' client-reviews__review--celeb' : '')
            }>
            {quote && (
                <h3 className="review__quote">
                    {quote.replace(/&nbsp;/g, ' ')}
                </h3>
            )}
            <div className="review__content">
                <QuoteOpenIcon />
                {excerpt && showMore && (
                    <span 
                        dangerouslySetInnerHTML={{
                            __html: excerpt.replace(/&nbsp;/g, ' '),
                        }}></span>
                )}
                {testimonial && !showMore && (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: testimonial.replace(/&nbsp;/g, ' '),
                        }}></span>
                )}
                <QuoteCloseIcon />
            </div>
            {showMore && (
                <div
                    className="review__show-more u-text-underline"
                    onClick={handleShowMore}>
                    Show full review
                </div>
            )}
            <div className="u-spacer"></div>
            <div
                className={
                    'review-info-wrapper' +
                    (isCeleb ? ' review-info-wrapper--celeb' : '')
                }>
                {(isCeleb || isCorporate || isMedia) && featuredImage && (
                    <div className="review__thumbnail">
                        <img src={featuredImage} />
                    </div>
                )}
                <div className="review__info">
                    {author && <span className="review__author">{author}</span>}
                    {country && author && ','}
                    {country && (
                        <span className="review__country">{country}</span>
                    )}
                    {addiction && (
                        <span className="review__addiction">{addiction}</span>
                    )}
                </div>
                {!isCeleb && (
                    <div className="review-stars">
                        {[...Array(reviews)].map((element, index) => (
                            <div className="review-stars__star" key={index}>
                                <ReviewStarIcon />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {schema && <script type="application/ld+json">{schema}</script>}
        </li>
    );
};

export default Review;
