const accordions = document.querySelectorAll('[data-accordion="container"]');

for (let i = 0; i < accordions.length; i++) {
    const accordion = accordions[i];
    const accordionToggle = accordion.querySelector('[data-ref="toggle"]');

    if (accordionToggle) {
        accordionToggle.addEventListener('click', function(e) {
            e.preventDefault();
            accordion.classList.toggle('accordion--active');
            const switchOn = accordion.querySelector('[data-ref="switch-on"]');
            const switchOff = accordion.querySelector('[data-ref="switch-off"]');
            if (switchOn !== null) {
                switchOn.setAttribute('data-ref', 'switch-off');
            }
            if (switchOff !== null) {
                switchOff.setAttribute('data-ref', 'switch-on');
            }
        });
    }
}

// https://codepen.io/callumbw95/pen/XWbgoqo
(window => {
    const event = new Event("accordion-close");
    const stopVideos = () => window.dispatchEvent(event) || true;
    const activeClass = 'active';
    const getContainer = target => target.closest('[data-accordion-container]');
    const getRow = target =>
        getContainer(target).querySelector(
            `[data-accordion-row="${target.dataset.accordionSelect}"]`,
        );
    const resetRows = target =>
        [
            ...document.querySelectorAll(
                `[data-accordion-container="${
                    getContainer(target).dataset.accordionContainer
                }"] [data-accordion-row]`,
            ),
        ]
            .filter(el => el !== getRow(target))
            .map(el => el.classList.remove(activeClass));
    const selectRow = target =>
        !getRow(target).classList[
            getRow(target).matches(`.${activeClass}`)
                ? toggleRowOrAlwaysOpen(target)
                : 'add'
        ](activeClass);
    const toggleRowOrAlwaysOpen = target =>
        getContainer(target).dataset.accordionAlwaysOpen ? 'add' : 'remove';
    const scrollToRow = target =>
        window.scrollBy(
            0,
            getRow(target).getBoundingClientRect().top -
                parseFloat(
                    getComputedStyle(getRow(target))['padding-top'] || 0,
                ),
        );

    return window.addEventListener(
        'click',
        ({ target: { dataset }, target }) =>
            dataset.accordionSelect &&
            resetRows(target) &&
            stopVideos() &&
            selectRow(target) &&
            dataset.accordionScrollTo &&
            scrollToRow(target),
    );
})(window);
