import React, { useContext, useEffect, useState } from 'react';
import { BookingContext } from './Context/BookingContext';
import Search from './Search/Search';
// Pickers
import AddictionPicker from './Addictions/AddictionPicker';
import SessionPicker from './SessionPicker/';
import SessionTypePicker from './SessionTypePicker/';
import SessionCountPicker from './SessionCountPicker/';
import LocationPicker from './LocationPicker/';

// Contents
import BookingTabsContainer from './BookingTabsContainer';
import NoResults from './Results/NoResults';

// Icons
import LoadingIcon from '../Icons/LoadingIcon';

const BookingContainer = () => {
    const [bookingState, setBookingState] = useContext(BookingContext);
    const [loading, setLoading] = useState(true);
    const [isFirstLoading, setFirstLoading] = useState(true);
    const [defaultContent, setDefaultContent] = useState(true);
    const [showBookingTabs, setShowBookingTabs] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState({});

    const fetchData = async () => {
        const search = new Search({
            type: 'bookingjourney',
            perPage: 1,
            page: 1,
            sort: '',
            distance: 0,
        });
        const response = await search.getResults();

        let data = {};
        if (response.results.length > 0) {
            data = response.results[0];
        }

        return data;
    };

    const fetchPaymentMethods = async () => {
        const formData = new FormData();
        formData.append('security', wp_ajax.security);

        await fetch(`${wp_ajax.ajax_url}?action=get_payment_methods`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(response => {
                setPaymentMethods(response.data.paymentmethods);
            })
            .catch(error => {
                console.log(`Error during fetch: ${error}`);
            })
    };

    const fetchBookingData = async () => {
        let oldData = bookingState;
        let newData = {
            ...oldData,
            data: await fetchData(),
        };
        setBookingState(newData);
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);

        if (bookingState.sessionType) {
            const voucherState =
                ('voucher' in bookingState && bookingState.voucher.current.key === 'yes');

            if (
                (bookingState.sessionType.current.key === 'group' &&
                    (bookingState.location !== undefined ||
                        voucherState)) ||
                (bookingState.sessionType.current.key === 'live-group' &&
                    (bookingState.sessionCount?.value > 0 || voucherState)) ||
                bookingState.sessionType.current.key === 'live-group' ||
                bookingState.sessionType.current.key === 'ovp' ||
                bookingState.sessionType.current.key === 'onetoone' ||
                bookingState.sessionType.current.key === 'taster' ||
                bookingState.addiction.current.id === 390
            ) {
                setDefaultContent(false);
                setShowBookingTabs(true);
            } else {
                setShowBookingTabs(false);
                setDefaultContent(true);
            }
        } else {
            setShowBookingTabs(false);
            setDefaultContent(true);
        }

        setLoading(false);
    }, [
        bookingState.sessionType,
        bookingState.location,
        bookingState.sessionCount?.value,
    ]);

    useEffect(() => {
        if (isFirstLoading) {
            setFirstLoading(false);
            setLoading(true);
            fetchPaymentMethods();
            fetchBookingData();
        }
    }, []);

    if (loading) return <LoadingIcon />;

    return (
        <div className="booking__container container container--large">
            <div className="booking__panel">
                <div className="booking__panel-inner">
                    <AddictionPicker />
                    <SessionPicker />
                    <SessionTypePicker />
                    <SessionCountPicker />
                    <LocationPicker />
                    {!loading && paymentMethods && Object.keys(paymentMethods).length > 0 && (
                        <div className="booking__payment-methods" dangerouslySetInnerHTML={{__html: paymentMethods}} />
                    )}
                </div>
            </div>
            <div className="booking__panel">
                <div className="booking__panel-inner">
                    {defaultContent && (
                        <NoResults
                            key="no-product-item-0"
                            primaryMessage="Please complete the options to view available sessions."
                            primaryIcon="info"
                        />
                    )}
                    {showBookingTabs && <BookingTabsContainer />}
                </div>
            </div>
        </div>
    );
};
export default BookingContainer;
