const footerMenu = document.querySelector('[data-behaviour="footer-menu"]');

if (footerMenu) {
    const footerMenuCarets = footerMenu.querySelectorAll(
        '[data-behaviour="caret"]',
    );

    if (footerMenuCarets.length > 0) {
        const desktopBP = 1128;

        footerMenuCarets.forEach(caret => {
            caret.addEventListener('click', function(event) {
                event.preventDefault();
                let menuItem = this.closest('[data-ref="menu-item"]');
                if (menuItem) menuItem.classList.toggle('open-menu');
            });
        });

        window.addEventListener('resize', function(event) {
            //@todo debounce
            if (document.documentElement.clientWidth >= desktopBP) {
                let openMenus = footerMenu.querySelectorAll('.open-menu');
                if (openMenus.length) {
                    openMenus.forEach(menu => {
                        menu.classList.remove('.open-menu');
                    });
                }
            }
        });

        const back2top = document.querySelector('[data-behaviour="back2top"]');

        if (back2top) {
            back2top.addEventListener('click', function(event) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            });
        }
    }
}
