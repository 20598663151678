window.addEventListener('load', () => {
    const liveChatLinks = document.querySelectorAll("a[href='#live-chat']");
    const liveChatButton = document.querySelector('#wo_online');
    if (liveChatButton !== null && liveChatLinks.length > 0) {
        liveChatLinks.forEach((liveChatLink) => {
            liveChatLink.addEventListener('click', (e) => {
                e.preventDefault();
                liveChatButton.click();
            });
        });
    }
});
