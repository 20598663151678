import React from "react";

const Radio = ({ title, id, name, onRadioChange, checked }) => {
    
    return (
        <div className="radio">
            <input
                type="radio"
                id={'radio_' + id}
                value={id}
                checked={checked ? 'checked' : ''}
                name={name}
                onChange={onRadioChange}
            />
            <label htmlFor={'radio_' + title.toLowerCase()}>{title}</label>
        </div>
    )
}
export default Radio