import React from "react";

const Heading = () => {
    return (
        <header className="heading u-background-teal-light">
            <div className="heading-container container container--large">
                <h1 className="heading-title">Take the first step today <a href="/contact-us/" className="button button--pink" target="_blank">Need help booking? <span className="u-text-underline">Speak to an advisor</span></a></h1>
                <h2 className="heading-subtitle">Set yourself free</h2>
            </div>
        </header>
    )
}
export default Heading;