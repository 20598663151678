import React from "react"
const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.23 14.23">
            <g transform="translate(0 7.115) rotate(-45)">
                <path d="M4.4,10.062V5.66H0V4.4H4.4V0H5.66V4.4h4.4V5.66H5.66v4.4Z" fill="#101f37"/>
            </g>
        </svg>
    )
}
export default CloseIcon