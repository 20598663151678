import React, { useContext } from "react";
import { BookingContext } from '../Context/BookingContext';
import PropTypes from "prop-types";

const proptypes = {
    addiction: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        icon: PropTypes.string,
    }),
    handleSelection: PropTypes.func.isRequired,
}

const Addiction = ({ addiction, handleSelection }) => {

    const [bookingState, setBookingState] = useContext(BookingContext);

    /**
     * @returns HTML
     */
     const innerContent = () => {
        return (
            <div className="addiction__inner">
                <div className="addiction__image">
                    {addiction.id === bookingState.addiction.current.id
                        ? <span className="addiction-icon addiction-icon--selected material-icons material-icons-outlined">check_circle</span>
                        : <img src={addiction.icon} alt={addiction.name} className="addiction-icon" />
                    }
                </div>
                <h4 className="addiction__title">{addiction.name}</h4>
            </div>
        );
    };

    return (
        <>
            {addiction.booking_journey_url && addiction.booking_journey_url !== '' ? (
                <a className={'addiction' + (addiction.id === bookingState.addiction.current.id ? ' addiction--active' : '')}
                    href={addiction.booking_journey_url}
                >{innerContent()}</a>
            ) : (
                <div className={'addiction' + (addiction.id === bookingState.addiction.current.id ? ' addiction--active' : '')}
                    onClick={() => { handleSelection(addiction) }}
                >{innerContent()}</div>
            )}
        </>
    );
}

Addiction.propTypes = proptypes;

export default Addiction;