import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const proptypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string
            ]).isRequired,
            title: PropTypes.string.isRequired,
        })
    ),
    handleSelection: PropTypes.func.isRequired,
    title: PropTypes.string,
    defaultValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
}

const Dropdown = ({options, handleSelection, title = '', defaultValue = '', supressEvent = false}) => {

    const [isFirstLoading, setFirstLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [option, setOption] = useState({value: '', title: ''});

    useEffect(() => {
        // Attempts to find the item based on the default value other 
        // set to the first option otherwise
        if (isFirstLoading) {
            setFirstLoading(false);
        }
        if (defaultValue !== '') {
            const foundDefaultOption = options.find(
                defaultOption => defaultOption.value === defaultValue
            );

            if (foundDefaultOption) {
                handleItemSelection(foundDefaultOption);
            }
        } else {
            handleItemSelection(options[0]);
        }
    }, []);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleItemSelection = (item) => {
        setOption(item);
        setIsOpen(false);
        // Need to prevent firing on first load for language selector
        if (!supressEvent || !isFirstLoading) {
            handleSelection(item);
        }
    };

    return options && (
        <div className="dropdown">
            {title && <h4 className="dropdown__title">{title}</h4>}
            <div className={'dropdown__button' + (isOpen? ' dropdown__button--active': '' )} onClick={() => { handleToggle() }}>
                <p className="dropdown__text">{option.title}</p>
                <span className="material-icons dropdown__icon">arrow_drop_down</span>
            </div>
            <div className={'dropdown__options' + (isOpen ? ' dropdown__options--active': '' )}>
                {options.map((item, index) => (
                    <div className={'dropdown__item' + (item.value == option.value ? ' dropdown__item--active' : '')}
                        onClick={() => { handleItemSelection(item) }}
                        data-change-value={'changeValue' in item ? item.changeValue : ''}
                        key={`dropdown-item-${item.value}-${item.value}-${index}`}
                    >
                        <p className="dropdown__text">{item.title}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

Dropdown.propTypes = proptypes;
export default Dropdown;