/* eslint-disable max-len */

const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
        // create element for video to play
        const canvas = document.createElement('canvas');
        const video = document.createElement('video');

        // set to autoplay so the video gets properly loaded
        video.autoplay = true;
        video.muted = true;

        // add the video to the video tag
        video.src = URL.createObjectURL(file);

        // get the initial frame
        video.onloadeddata = () => {
            const ctx = canvas.getContext('2d');

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            video.pause();
            return resolve(canvas.toDataURL('image/png'));
        };
    });
};


const fields = document.querySelectorAll('[data-behaviour="video-upload"]');
if (fields.length >= 1) {
    fields.forEach((field) => {
        const uploads = field.querySelectorAll('[type="file"]');
        uploads.forEach((upload) => {
            upload.addEventListener('change', async (e) => {
                const container = field;
                if (upload.value) {
                    container.classList.add('video-upload--input-value');
                } else {
                    container.classList.remove('video-upload--input-value');
                }
                const displays = field.querySelectorAll('[data-behaviour="input-value"]');

                displays.forEach((display) => {
                    // eslint-disable-next-line no-param-reassign, no-useless-escape
                    display.innerHTML = upload.value.replace(/^.*[\\\/]/, '');
                });

                try {
                    const thumbnail = await generateVideoThumbnail(e.target.files[0]);
                    container.style.backgroundImage = `url(${thumbnail})`;
                } catch (err) {
                    console.log(err);
                }
            });
        });
    });
}
