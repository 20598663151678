document.documentElement.className = document.documentElement.className.replace('no-js', 'js');

// See https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
let isTouch = false;
let prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    isTouch = true;
} else {
    // include the 'heartz' as a way to have a non matching MQ to help terminate the join https://git.io/vznFH
    let query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    isTouch = window.matchMedia(query).matches;
}

if (isTouch) {
    document.documentElement.className = document.documentElement.className.replace('no-touchevents', 'touchevents');
}

// Import static files to ensure webpack loads them correctly
import '../images';
import '../svgs';
// Import standard JS modules and React Components
import './modules';
import './components';
