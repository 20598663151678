(window => {
    const tableScrollCheck = wrapper => {        
        const table = getTableFromWrapper(wrapper);        
        const isScrollable = table.scrollWidth > table.getBoundingClientRect().width;

        wrapper.classList[isScrollable ? 'add' : 'remove']('scrollable-table');

        if (!isScrollable) return;

        const isAtStart = table.scrollLeft === 0;
        const isAtEnd = table.scrollLeft === table.scrollWidth - table.getBoundingClientRect().width;

        wrapper.classList[isAtStart ? 'add' : 'remove']('scrollable-table--start');
        wrapper.classList[isAtEnd ? 'add' : 'remove']('scrollable-table--end');
    }

    const tableCreateButtons = wrapper => {
        const buttonsExist = [...wrapper.querySelectorAll('.scrollable-table__button')].length > 0;
        
        if (buttonsExist) return;

        const next = document.createElement('button');
        next.classList.add('scrollable-table__button');
        next.classList.add('scrollable-table__button--next');
        next.setAttribute('data-behaviour', 'scrollable-table-next');
        
        const nextArrow = document.createElement('span');
        nextArrow.classList.add('material-icons');
        nextArrow.innerText = 'east';
        next.appendChild(nextArrow);
        wrapper.appendChild(next);
        
        const prev = document.createElement('button');
        prev.classList.add('scrollable-table__button');
        prev.classList.add('scrollable-table__button--prev');
        prev.setAttribute('data-behaviour', 'scrollable-table-prev');

        const prevArrow = document.createElement('span');
        prevArrow.classList.add('material-icons');
        prevArrow.innerText = 'west';
        prev.appendChild(prevArrow);
        wrapper.appendChild(prev);
    }

    const tableCreateInner = wrapper => {
        wrapper.innerHTML = '<div class="scrollable-table__table">'+wrapper.innerHTML+'</div>';
    }

    const scrollToEnd = ({target: button}) => {
        const table = getTableFromWrapper(button.closest('[data-behaviour="scrollable-table"]'));
        const pageWidth = table.getBoundingClientRect().width;
        const maxValue = table.scrollWidth - pageWidth;
        const isBeforeEnd = table.scrollLeft < maxValue;

        table.scrollLeft = isBeforeEnd ? table.scrollLeft + pageWidth : maxValue;
    };

    const scrollToStart = ({target: button}) => {
        const table = getTableFromWrapper(button.closest('[data-behaviour="scrollable-table"]'));
        const pageWidth = table.getBoundingClientRect().width;
        const minValue = 0;
        const isAfterStart = table.scrollLeft > minValue;

        table.scrollLeft = isAfterStart ? table.scrollLeft - pageWidth : minValue;
    };

    const getTableFromWrapper = wrapper => wrapper.querySelector('.scrollable-table__table');
    const getWrapperFromTable = table => table.closest('[data-behaviour="scrollable-table"]');

    // init load setup
    document.addEventListener('DOMContentLoaded', e => {
        [...document.querySelectorAll('[data-behaviour="scrollable-table"]')].map(wrapper => {
            tableCreateInner(wrapper);
            tableCreateButtons(wrapper);
            tableScrollCheck(wrapper);

            getTableFromWrapper(wrapper).addEventListener('scroll', ({target: table}) => tableScrollCheck(getWrapperFromTable(table)));
        });
        // Readjust on resize
        window.addEventListener('resize', [...document.querySelectorAll('[data-behaviour="scrollable-table"]')].map(wrapper => tableScrollCheck(wrapper)));
    });

    // Button Functions
    window.addEventListener('click', e => e.target.matches('[data-behaviour="scrollable-table-next"]') && scrollToEnd(e));
    window.addEventListener('click', e => e.target.matches('[data-behaviour="scrollable-table-prev"]') && scrollToStart(e));

    return window;
})(window);