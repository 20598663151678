const marketingPreferencesChanges = document.querySelectorAll('[data-behaviour="marketing-prefernces-change"]');
if (marketingPreferencesChanges.length > 0) {
    marketingPreferencesChanges.forEach((marketingPreferencesChange) => {
        marketingPreferencesChange.addEventListener('click', () => {
            fetch(wp_ajax.ajax_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `action=set_marketing_preferences&security=${wp_ajax.security}&marketing_preference=${marketingPreferencesChange.checked}`,
            })
                .then(response => response.json())
                .then(response => {
                    console.error('Set Marketing Preferences: ', response.message);
                })
                .catch(error => {
                    console.error('Set Marketing Preferences: ', error);
                });
        });
    });
}
