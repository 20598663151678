import { saveAddiction, getCategoryMenu } from './utilities';
//Width on load
let docWidth = document.documentElement.clientWidth;
//Container
const navContainer = document.querySelector(
    '[data-ref="header-menu-container"]',
);

if (navContainer) {
    //Nav Menus
    const iconMenu = navContainer.querySelector('[data-ref="icon-menu"]');
    const mobileMenu = navContainer.querySelector('[data-ref="mobile-menu"]');
    const headerMenu = navContainer.querySelector('[data-ref="primary-menu"]');
    const defaultBuyNowUrl = navContainer.dataset.refDefaultUrl;
    // Toggle
    const primaryMenuToggle = navContainer.querySelector(
        '[data-behaviour="open-primary-menu"]',
    );
    const primaryMenuMobileToggle = document.querySelector(
        '[data-behaviour="open-mobile-menu"]',
    );

    const siteSwitcher = document.querySelector(
        '[data-behaviour="site-switcher"]',
    );

    if (siteSwitcher) {
        siteSwitcher.addEventListener('click', () => {
            const dropdown = siteSwitcher.querySelector(
                '[data-ref="site-switcher-dropdown"]',
            );
            dropdown.classList.toggle('active');
        });
    }

    if (primaryMenuToggle) {
        primaryMenuToggle.addEventListener('click', function(e) {
            animateIconMenu(iconMenu);
        });
    }

    if (primaryMenuMobileToggle) {
        primaryMenuMobileToggle.addEventListener('click', function(e) {
            if (!this.classList.contains('site-header-menu__item--active')) {
                headerMenu.classList.add('u-hidden');
                mobileMenu.classList.add('menu-mobile--active');
                this.classList.add('site-header-menu__item--active');

                if (iconMenu.classList.contains('menu--active')) {
                    closeResetMenu();
                }
            } else {
                closeMobileMenu();
            }
        });
    }

    const subMenuMobileArrow = navContainer.querySelectorAll(
        '[data-behaviour="open-sub-menu"]',
    );

    subMenuMobileArrow.forEach(el => {
        el.addEventListener('click', function(e) {
            let parentLi = el.closest('[data-ref="mobile-menu-item"]');
            parentLi.classList.add('menu-item--active');
            mobileMenu.classList.add('menu-mobile--slide-left');
        });
    });

    const subMenuMobileArrowClose = navContainer.querySelectorAll(
        '[data-behaviour="close-sub-menu"]',
    );
    // this closes the mobile menu mega menu
    subMenuMobileArrowClose.forEach(el => {
        el.addEventListener('click', function(e) {
            mobileMenu.classList.remove('menu-mobile--slide-left');
            let parentLi = el.closest('.menu-item--active');

            setTimeout(() => {
                if (parentLi) parentLi.classList.remove('menu-item--active');
            }, 1000); //length of css transition
        });
    });

    const navIconsLink = navContainer.querySelectorAll(
        '[data-behaviour="update-primary-menu"]',
    );

    navIconsLink.forEach(link => {
        const primaryMenu = navContainer.querySelector(
            '[data-ref="primary-menu"]',
        );
        if (primaryMenu !== null) {
            const secondaryMenu = primaryMenu.querySelector(
                '[data-ref="menu-item-secondary"]',
            );
            const fillImage = primaryMenu.querySelector(
                '[data-ref="fill-menu-image"]',
            );
            const fillText = primaryMenu.querySelector(
                '[data-ref="fill-menu-text"]',
            );
            const fillLink = primaryMenu.querySelector(
                '[data-ref="menu-item-secondary-link"]',
            );
            const fillMenuItem = primaryMenu.querySelector(
                '[data-ref="menu-item-fillable"]',
            );
            const iconImage = link.querySelector(
                '[data-ref="icon-menu-icon"] img',
            );
            const iconText = link.querySelector('[data-ref="icon-menu-text"]');
            const bookingButtonUrl =
                defaultBuyNowUrl +
                '?addiction_id=' +
                link.getAttribute('data-ref');

            if (
                localStorage.getItem('addiction') ===
                link.getAttribute('data-ref')
            ) {
                if (fillImage !== null && iconImage !== null) {
                    fillImage.src = iconImage.src;
                }

                if (fillText !== null && iconText !== null) {
                    fillText.innerHTML = iconText.innerHTML;
                }

                if (fillLink !== null) {
                    fillLink.setAttribute('href', bookingButtonUrl);
                }

                if (primaryMenuToggle !== null) {
                    primaryMenuToggle.classList.add('u-hidden');
                }

                if (fillMenuItem !== null) {
                    fillMenuItem.classList.remove('u-hidden');
                }
            }

            link.addEventListener('click', function(e) {
                e.preventDefault();
                let setAddiction = saveAddiction(link.getAttribute('data-ref'));

                if (fillImage !== null && iconImage !== null) {
                    fillImage.src = iconImage.src;
                }

                if (fillText !== null && iconText !== null) {
                    fillText.innerHTML = iconText.innerHTML;
                }

                if (fillLink !== null) {
                    fillLink.setAttribute('href', bookingButtonUrl);
                }

                if (primaryMenuToggle !== null) {
                    primaryMenuToggle.classList.add('u-hidden');
                }

                if (iconMenu !== null) {
                    animateIconMenu(iconMenu);
                }

                if (fillMenuItem !== null) {
                    fillMenuItem.classList.remove('u-hidden');
                }

                if (secondaryMenu !== null) {
                    secondaryMenu.classList.remove('u-hidden');
                }

                setAddiction.then(() => {
                    if (link.hasAttribute('href')) {
                        window.location.href = link.getAttribute('href');
                    }
                });
            });
        }
    });

    const resetMenu = navContainer.querySelector(
        '[data-behaviour="reset-menu"]',
    );

    if (resetMenu) {
        resetMenu.addEventListener('click', function(e) {
            closeResetMenu();
        });
    }

    if (iconMenu) {
        iconMenu.addEventListener('transitionstart', function() {
            primaryMenuToggle.setAttribute('data-state', 'animating');
        });

        iconMenu.addEventListener('transitionend', function() {
            primaryMenuToggle.removeAttribute('data-state');
        });
    }
}

/**
 * Animate icon menu
 * close or open animate icon
 */
function animateIconMenu(el) {
    const primaryMenuToggle = navContainer.querySelector(
        '[data-behaviour="open-primary-menu"]',
    );
    if (el.getAttribute('data-state') == 'animating') {
        return;
    } else {
        let cheight = el.offsetHeight,
            sheight = el.scrollHeight,
            isCollapsed = !cheight;

        const wrapper = el.closest('[data-ref="header-icon-wrapper"]');

        if (isCollapsed) {
            el.classList.add('menu--active');
            primaryMenuToggle.classList.add('menu--active');
            el.style.height = `${sheight}px`;
            // have to add wrapper height to calculated element height
            // due to height not being calculated correcly on the
            // wrappers children
            wrapper.style.height = `${sheight}px`;
        } else {
            el.style.height = '0px';
            wrapper.style.height = '0px';
            primaryMenuToggle.classList.remove('menu--active');
            el.classList.remove('menu--active');
        }
    }
}

/**
 * Close and reset header menu
 * this includes the icon selector menu
 */
function closeResetMenu() {
    const iconMenu = navContainer.querySelector('[data-ref="icon-menu"]');
    const primaryMenu = navContainer.querySelector('[data-ref="primary-menu"]');
    const defaultBuyNowUrl = navContainer.dataset.refDefaultUrl;
    const primaryMenuToggle = navContainer.querySelector(
        '[data-behaviour="open-primary-menu"]',
    );
    if (primaryMenu !== null) {
        const secondaryMenu = primaryMenu.querySelector(
            '[data-ref="menu-item-secondary"]',
        );
        const fillImage = primaryMenu.querySelector(
            '[data-ref="fill-menu-image"]',
        );
        const fillText = primaryMenu.querySelector(
            '[data-ref="fill-menu-text"]',
        );
        const fillLink = primaryMenu.querySelector(
            '[data-ref="menu-item-secondary-link"]',
        );
        const fillMenuItem = primaryMenu.querySelector(
            '[data-ref="menu-item-fillable"]',
        );

        if (fillImage !== null) {
            fillImage.src = '';
        }

        if (fillText !== null) {
            fillText.innerHTML = '';
        }

        if (fillLink !== null) {
            fillLink.setAttribute('href', defaultBuyNowUrl);
        }

        if (primaryMenuToggle !== null) {
            primaryMenuToggle.classList.remove('u-hidden');
        }

        if (fillMenuItem !== null) {
            fillMenuItem.classList.add('u-hidden');
        }

        if (iconMenu !== null) {
            animateIconMenu(iconMenu);
        }

        if (secondaryMenu !== null) {
            secondaryMenu.classList.remove('u-hidden');
        }
    }
}

/**
 * Closes mobile menu
 */
function closeMobileMenu() {
    headerMenu.classList.remove('u-hidden');
    mobileMenu.classList.remove('menu-mobile--active');
    primaryMenuMobileToggle.classList.remove('site-header-menu__item--active');
    // check to see if sub-menu is open
    if (mobileMenu.classList.contains('menu-mobile--slide-left')) {
        mobileMenu.classList.remove('menu-mobile--slide-left');
    }
}

/**
 * Closes mobile menu
 */
function loadArticlesMenu() {
    const articlesDesktopMenu = document.querySelector(
        '[data-ref="articles-desktop-menu"]',
    );
    const articlesDesktopMenuToggle = document.querySelector(
        '[data-ref="articles-desktop-menu-toggle"]',
    );
    const articlesMobileMenu = document.querySelector(
        '[data-ref="articles-mobile-menu"]',
    );
    const articlesMobileMenuToggle = document.querySelector(
        '[data-ref="articles-mobile-menu-toggle"]',
    );
    const category = localStorage.getItem('addiction');
    if (
        category !== '' &&
        articlesDesktopMenu !== null &&
        articlesMobileMenu !== null
    ) {
        const getMenu = getCategoryMenu(category);
        getMenu.then(menu => {
            articlesDesktopMenu.innerHTML =
                'desktop_html' in menu ? menu.desktop_html : '';

            articlesMobileMenu.replaceChildren(
                articlesMobileMenu.firstElementChild,
            );
            articlesMobileMenu.insertAdjacentHTML(
                'beforeend',
                'mobile_html' in menu ? menu.mobile_html : '',
            );

            // Need show toggle now there are items
            if (
                menu.desktop_html !== '' &&
                articlesDesktopMenuToggle !== null
            ) {
                articlesDesktopMenuToggle.classList.add(
                    'menu-item__link--has-children',
                );
            } else {
                articlesDesktopMenuToggle.classList.remove(
                    'menu-item__link--has-children',
                );
            }

            if (menu.mobile_html !== '' && articlesMobileMenuToggle !== null) {
                articlesMobileMenuToggle.classList.remove('u-hidden');
            } else {
                articlesMobileMenuToggle.classList.add('u-hidden');
            }
        });
    }
}
loadArticlesMenu();

window.addEventListener('resize', function(e) {
    // ensure its only width not height
    if (docWidth !== document.documentElement.clientWidth) {
        //close icon menu on resize
        if (iconMenu && iconMenu.classList.contains('menu--active')) {
            closeResetMenu();
        }
        // close mobile menu on resize
        if (
            primaryMenuMobileToggle &&
            primaryMenuMobileToggle.classList.contains(
                'site-header-menu__item--active',
            )
        ) {
            closeMobileMenu();
        }
        //set to current
        docWidth = document.documentElement.clientWidth;
    }
});
