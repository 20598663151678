const openSearch = document.querySelector('[data-behaviour="open-search"]');
const searchInputMobile = document.querySelector('[data-behaviour="search-input-mobile"]');

if (openSearch) {
    openSearch.addEventListener('click', function(e) {

        if( !this.classList.contains('site-header-menu__item--active' )) {
    
            this.classList.add('site-header-menu__item--active');
            searchInputMobile.classList.add('search__mobile--active');
    
        } else {
    
            this.classList.remove('site-header-menu__item--active');
            searchInputMobile.classList.remove('search__mobile--active');
    
        }
    });
}