import React, { useState , useEffect } from "react";

import Heading from "../Heading";
import Tabs from "../Tabs/Tabs";
import LoadingIcon from "../Icons/LoadingIcon";
//Functions
import { getParamFromUrl } from "../../modules/utilities";

const NewsArticles = ({ searchQuery }) => {
    const [loading, setLoading] = useState(true);
    const [tabs, setTabs] = useState({});
    const [loadedTab, setLoadedTab] = useState(null);

    useEffect(() => {
        let getTabFromUrl = getParamFromUrl('tab');

        if (getTabFromUrl) {
            setLoadedTab(getTabFromUrl);
        }

        fetchTabs();
    }, []);

    const fetchTabs = async () => {
        const formData = new FormData();
        formData.append('security', wp_ajax.security);
        formData.append('search_query', searchQuery);

        await fetch(`${wp_ajax.ajax_url}?action=search_tabs`, {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(response => {
                if (!response.success) {
                    console.log(response.data[0].message);
                } else {
                    setTabs(response.tabs);
                }
                setLoading(false);
            })
            .catch(error => {
                console.log('Error during fetch: ' + error);
                setLoading(false);
            });
    }

    if (loading && !tabs.length)
        return (<LoadingIcon/>)

    return (
        <div>
            <Heading
                alignment={{
                    horizontal: 'center',
                    vertical: 'column-reverse',
                }}
                title={searchQuery}
                subTitle='Search results for'
            />
            <Tabs
                searchQuery={searchQuery}
                tabs={tabs}
                parentDataType="search"
                onLoadedTab={loadedTab}
            />
        </div>
    );
}
export default NewsArticles