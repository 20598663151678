/* eslint-disable max-len */
const fields = document.querySelectorAll('[data-behaviour="rating-field"]');
if (fields.length >= 1) {
    fields.forEach((field) => {
        const input = field.querySelector('input');
        const stars = field.querySelectorAll('[data-behaviour="rating-field-star"]');
        stars.forEach((star) => {
            star.addEventListener('click', (e) => {
                e.preventDefault();

                if (!star.classList.contains('rating-field__star--selected')) {
                    // clean up any already selected tabs
                    stars.forEach((cur) => {
                        if (cur.classList.contains('rating-field__star--selected')) {
                            cur.classList.remove('rating-field__star--selected');
                        }
                    });
                    star.classList.add('rating-field__star--selected');
                }

                input.setAttribute('value', star.getAttribute('data-ref'));
            });
        });
    });
}
