function replaceUrl(a, b) {
    const aTags = document.querySelectorAll('body a:not(.wpml-ls-link)');
    aTags.forEach(aTag => {
        if(aTag.getAttribute('href') && aTag.getAttribute('href').includes(a) == true && aTag.getAttribute('href').includes('/en-us/') == false && aTag.getAttribute('href').includes('/booking-journey/') == false ) {
            let href = aTag.getAttribute('href');
            let newHref = href.replace(a, a + b);
            aTag.setAttribute('href', newHref);
        }else if(aTag.getAttribute('href') && aTag.getAttribute('href').startsWith('/') == true && aTag.getAttribute('href').startsWith('//') == false && aTag.getAttribute('href').includes('/booking-journey/') == false   ) {
            let href = aTag.getAttribute('href');
            let newHref = b + href;
            aTag.setAttribute('href', newHref);
        }
    });
}

const currUrl = window.location.hostname;
const newUrl = '/en-us';

document.addEventListener("DOMContentLoaded", function() {
    if(window.location.href.includes('/en-us/') == true) {
        replaceUrl(currUrl, newUrl);
    }
});