import React, { useEffect, useContext, useState } from 'react';
import { BookingContext } from '../Context/BookingContext';
import SessionPickerItem from './SessionPickerItem';

import PanelTitle from '../PanelTitle';

// Icons
import LoadingIcon from '../../Icons/LoadingIcon';
import SessionCentreIcon from '../../Icons/SessionCentreIcon';
import SessionOnlineIcon from '../../Icons/SessionOnlineIcon';
import SessionOnetooneIcon from '../../Icons/SessionOnetooneIcon';

const SessionPicker = () => {
    const [bookingState, setBookingState] = useContext(BookingContext);
    const [firstLoading, setFirstLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showComponent, setShowComponent] = useState(false);
    const [sessions, setSessions] = useState([]);

    const sessionListData = [
        {
            id: 1,
            key: 'location',
            name: 'Attend a centre',
            icon: <SessionCentreIcon />,
        },
        {
            id: 2,
            key: 'online',
            name: 'Online',
            icon: <SessionOnlineIcon />,
        },
        {
            id: 3,
            key: 'onetoone',
            name: 'At Home',
            icon: <SessionOnetooneIcon />,
        },
    ];

    useEffect(() => {
        setLoading(true);

        if (bookingState.addiction) {
            if (
                bookingState.addiction.current.id !== null &&
                bookingState.addiction.current.id !== 390
            ) {
                setSessions(bookingState.addiction.sessionsAvailable);

                if (firstLoading) {
                    setFirstLoading(false);
                    handleSessionPreSelection();
                }

                setShowComponent(true);
            } else {
                setShowComponent(false);
            }
        } else {
            setShowComponent(false);
        }

        setLoading(false);
    }, [bookingState.addiction, bookingState.sessionType]);

    const handleSessionSelection = item => {
        let oldData = bookingState;

        if (oldData.location) {
            delete oldData.location;
        }

        let newData = {
            ...oldData,
            session: {
                current: {
                    name: item.name,
                    id: item.id,
                    key: item.key,
                },
            },
        };
        newData = {
            ...newData,
            sessionType: {
                current: {
                    name: null,
                    id: null,
                    key: null,
                },
            },
        };

        setBookingState(newData);
    };

    /**
     * handleSessionPreSelection
     * Updates current addiction in booking state based on query param
     */
    const handleSessionPreSelection = () => {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get('addiction_id') && searchParams.get('session')) {
            const sessionListItem = sessionListData.find(
                session => session.key === searchParams.get('session'),
            );

            if (sessionListItem) {
                let oldData = bookingState;
                let newData = {
                    ...oldData,
                    session: {
                        current: sessionListItem,
                    },
                };
                setBookingState(newData);
            }
        }
    };

    const sessionsList = () => {
        return sessions.map(name => {
            let dataObj = sessionListData.filter(elem => elem.key === name);

            if (typeof dataObj[0] !== undefined) {
                return (
                    <SessionPickerItem
                        item={dataObj[0]}
                        handleSelection={handleSessionSelection}
                        key={'session-picker-item-' + name}
                    />
                );
            }
        });
    };

    /**
     * @returns HTML
     */
    const helpContent = () => {
        return (
            bookingState.data.help.session_content !== '' && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: bookingState.data.help.session_content,
                    }}
                />
            )
        );
    };

    if (!showComponent) return <></>;

    return (
        <div className="session-picker">
            <PanelTitle
                title="How would you prefer your session?"
                helpContent={helpContent()}
            />
            <div className="session-picker__content">
                {loading && <LoadingIcon />}
                {!loading && sessions && (
                    <div className="session-picker__list">{sessionsList()}</div>
                )}
            </div>
            <div className="booking__seperator"></div>
        </div>
    );
};

export default SessionPicker;
