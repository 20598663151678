import React, { useContext } from 'react';
import {BookingContext} from '../Context/BookingContext';
import PropTypes from "prop-types";

// Icons
import CloseIcon from "../../Icons/CloseIcon";

const proptypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.bool
    ]).isRequired,
    content: PropTypes.string,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
    showComponent: PropTypes.bool,
};

const FranchiseMessage = ({ title, onClick, showComponent = true, content = '', altContent = '', buttonText = '', buttonUrl = '' }) => {
    const [bookingState, setBookingState] = useContext(BookingContext);

    if (!showComponent)
        return (<></>)

    return (
        <div className='franchise-message'>
            {<button
                className="franchise-message__close"
                onClick={() => onClick()}>
                <CloseIcon />
            </button>}
            <h3 className='franchise-message__title'>{title}</h3>
            {content !== '' &&
                <div
                    className='franchise-message__content'
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                />
            }
            {buttonText !== '' && buttonUrl !== '' &&
                <>
                    <a
                        className="franchise-message__button button button--with-icon-left"
                        href={buttonUrl}
                        target='_blank'>
                        <span className="material-icons">open_in_new</span> {buttonText}
                    </a>
                    <div className='franchise-message__seperator'>or</div>
                </>
            }
            {altContent !== '' &&
                <div
                    className='franchise-message__content'
                    dangerouslySetInnerHTML={{
                        __html: altContent,
                    }}
                />
            }
            {<button
                className="franchise-message__button button button--white"
                onClick={() => onClick()}>
                Stay on current site
            </button>}
        </div>
    )

}

FranchiseMessage.propTypes = proptypes;
export default FranchiseMessage;