document.body.addEventListener('click', event => {
    if (
        event.target &&
        (event.target.dataset.behaviour === 'open-modal' ||
            event.target.dataset.behaviour === 'close-modal')
    ) {
        event.preventDefault();

        const modal = document.querySelector(
            `[data-behaviour="modal"][data-target="${event.target.dataset.target}"]`,
        );

        if (modal !== null) {
            if (event.target.dataset.behaviour === 'open-modal') {
                modal.classList.add('modal--active');
                document.body.classList.add('u-lock-scrolling');

                if (
                    event.target.dataset.autoplay !== undefined &&
                    event.target.dataset.autoplay === 'true'
                ) {
                    let iframe = modal.querySelector('iframe');

                    if (iframe.src.includes('vimeo')) {
                        const vimeoPlayer = new Vimeo.Player(iframe);

                        vimeoPlayer.play();
                    }

                    if (iframe.src.includes('youtube')) {
                        iframe.src += '&autoplay=1';
                    }
                }

                const currentModal = event.target.closest('.modal');

                if (currentModal !== null) {
                    currentModal.classList.remove('modal--active');
                }
            } else {
                modal.classList.remove('modal--active');
                document.body.classList.remove('u-lock-scrolling');

                let iframe = modal.querySelector('iframe');

                if (iframe !== null) {
                    let src = iframe.src;

                    if (src.includes('&autoplay=1')) {
                        src = src.replace('&autoplay=1', '');
                    }

                    iframe.src = src;
                }
            }
        }
    }
});
