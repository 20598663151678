import { saveAddiction, loginCustomer, registerCustomer, pushHistory } from "./utilities";

const addictionChanges = document.querySelectorAll('[data-behaviour*="addiction-change"]');
if (addictionChanges.length > 0) {
    addictionChanges.forEach((addictionChange) => {
        addictionChange.addEventListener('change', () => {
            saveAddiction(addictionChange.value);
        });
    });
}

const loginAjaxForms = document.querySelectorAll('[data-behaviour="login-ajax"]');
if (loginAjaxForms.length > 0) {
    loginAjaxForms.forEach(loginAjaxForm => {
        loginAjaxForm.addEventListener('submit', async (event) => {
            event.preventDefault();
            const searchParams = new URLSearchParams(window.location.search);
            const loader = loginAjaxForm.querySelector('[data-target="loader"]');
            const errorContainer = loginAjaxForm.querySelector('[data-target="errors"]');
            const questionnaireModalContainer = loginAjaxForm.closest('[data-behaviour="modal"][data-target="questionnaire-modal"');
            const formData = new FormData(loginAjaxForm);

            if (loader !== null) {
                loader.classList.remove('u-hidden');
            }
            const response = await loginCustomer(formData);

            if (errorContainer !== null && response.errors.length > 0) {
                errorContainer.innerHTML = '<ul class="woocommerce-error" role="alert">';
                for (const error of response.errors) {
                    errorContainer.innerHTML += `<li>${error}</li>`;
                }
                errorContainer.innerHTML += '</ul>';
            }

            if (response.status == 'success') {
                if (questionnaireModalContainer !== null) {
                    const formId = searchParams.get('form_id') ?? '';
                    const productId = searchParams.get('product_id') ?? '';
                    const couponCode = searchParams.get('coupon_code') ?? '';
                    window.location.href = `${wp_ajax.home_url}/questionnaire/?form_id=${formId}&product_id=${productId}&coupon_code=${couponCode}`;
                } else {
                    errorContainer.innerHTML = '<ul class="woocommerce-info" role="alert"><li>Logged in successfully</li></ul>';
                    window.location.href = '/my-account/';
                }
            }

            if (loader !== null) {
                loader.classList.add('u-hidden');
            }
        });
    });
}

const registerAjaxForms = document.querySelectorAll('[data-behaviour="register-ajax"]');
if (registerAjaxForms.length > 0) {
    registerAjaxForms.forEach(registerAjaxForm => {
        registerAjaxForm.addEventListener('submit', async (event) => {
            const searchParams = new URLSearchParams(window.location.search);
            event.preventDefault();
            const loader = registerAjaxForm.querySelector('[data-target="loader"]');
            const errorContainer = registerAjaxForm.querySelector('[data-target="errors"]');
            const questionnaireModalContainer = registerAjaxForm.closest('[data-behaviour="modal"][data-target="questionnaire-modal');
            const formData = new FormData(registerAjaxForm);

            if (loader !== null) {
                loader.classList.remove('u-hidden');
            }
            const response = await registerCustomer(formData);

            if (errorContainer !== null && response.errors.length > 0) {
                errorContainer.innerHTML = '<ul class="woocommerce-error" role="alert">';
                for (const error of response.errors) {
                    errorContainer.innerHTML += `<li>${error}</li>`;
                }
                errorContainer.innerHTML += '</ul>';
            }

            if (response.status == 'success') {
                if (questionnaireModalContainer !== null) {
                    const formId = searchParams.get('form_id') ?? '';
                    const productId = searchParams.get('product_id') ?? '';
                    const couponCode = searchParams.get('coupon_code') ?? '';
                    window.location.href = `${wp_ajax.home_url}/questionnaire/?form_id=${formId}&product_id=${productId}&coupon_code=${couponCode}`;
                } else {
                    errorContainer.innerHTML = '<ul class="woocommerce-info" role="alert"><li>Registered successfully</li></ul>';
                    window.location.href = '/my-account/';
                }
            }

            if (loader !== null) {
                loader.classList.add('u-hidden');
            }
        });
    });
}
