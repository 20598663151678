import { registerAddToCartTargets } from './utilities';

//PRIMARY FORM in MODAL
const forms = document.querySelectorAll(
    '[data-behaviour="check-availability"]',
);

forms.forEach(form => {
    form.addEventListener('submit', function(event) {
        event.preventDefault();
        //show spinner
        let button = form.querySelector('[data-ref="modal-button"]');
        button.disabled = true;
        button.classList.add('show-icon');
        //clear errors if any
        let errorDiv = form.querySelector(
            '[data-ref="check-availability-error"]',
        );
        errorDiv.innerHTML = '';

        const formData = new FormData(form);
        const country = formData.get('country');
        const addiction = formData.get('addiction');

        if (!country || !addiction) {
            button.disabled = false;
            button.classList.remove('show-icon');
            return false; //may want some error to show
        }

        formData.append('security', wp_ajax.security);

        fetch(`${wp_ajax.ajax_url}?action=check_availability`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(response => {
                if (
                    response.status !== 'success' ||
                    (response.status == 'success' && response.html == '')
                ) {
                    errorDiv.innerHTML = response.message;
                } else if (response.html !== '') {
                    //close primary modal
                    let primaryModal = form.closest('[data-behaviour="modal"]');
                    primaryModal.classList.remove('modal--active');

                    let parent = form.closest(
                        '[data-ref="content-with-image"]',
                    );
                    let secondaryModal = parent.querySelector(
                        '[data-behaviour="modal"][data-target="secondary-modal"]',
                    );
                    //populate secondary modal content
                    secondaryModal.querySelector(
                        '[data-ref="modal-content"]',
                    ).innerHTML = response.html;
                    //open secondary modal
                    secondaryModal.classList.add('modal--active');

                    // Call the function to register the add to cart buttons, ensuring it can
                    // access newly rendered modal elements
                    registerAddToCartTargets();
                }

                button.disabled = false;
                button.classList.remove('show-icon');
            })
            .catch(error => {
                console.log(`Error during fetch: ${error}`);
            });
    });
});
