import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import TotalCount from "../Pagination/TotalCount";
import Pagination from "../Pagination/Pagination";
import NewsItem from "../Search/NewsItem";
import Review from "../ClientReviews/Review";
import VideoReview from "../ClientReviews/VideoReview";
import LoadingIcon from "../Icons/LoadingIcon";
import ServiceItem from "../Search/ServiceItem";
import VideoItem from "../Search/VideoItem";
import FAQItem from "../FAQs/FAQitem";

import { getParamFromUrl } from "../../modules/utilities";

const SearchTabContent = ({ dataType, searchQuery, scrollToParentRef, paginationState, paginationStateUpdate, onDataLoaded }) => {
    const defaultDataObject = {
        'data': [],
        'pagination': {
            'totalCount': 0,
            'perPage': 0,
            'paged': 1,
            'paginationVars': {}
        },
    }
    const [loading, setLoading] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    
    const [data, setData] = useState({
        'services': defaultDataObject,
        'faq': defaultDataObject,
        'post': defaultDataObject,
        'testimonial':defaultDataObject,
        'videos' : defaultDataObject
    });

    // if datatype changes and data length 0 fetch
    // else if data length 1 and page has changed fetch
    // else return

    useEffect(() => {
        if (data && dataType && data[dataType].data.length == 0) {
            setLoading(true);
            fetchData();
            setFirstLoad(false);
        }
        return;
        
    }, [dataType]);

    useEffect(() => {
        if (!firstLoad &&
            paginationState[dataType] &&
            paginationState[dataType].old !== paginationState[dataType].new
        ) {
            setLoading(true);
            fetchData();
        }
        return;

    }, [paginationState[dataType].new]);

    const fetchData = async () => {
        const formData = new FormData();

        if (data[dataType].pagination.paged) {
            formData.append('paged', data[dataType].pagination.paged)
        }

        formData.append('security', wp_ajax.security);
        formData.append('search_query', searchQuery);
        formData.append('search_results_tab', dataType);

        let getCatFromUrl = getParamFromUrl('cat');
        if (getCatFromUrl) {
            formData.append('search_category', getCatFromUrl);
        }

        await fetch(`${wp_ajax.ajax_url}?action=search_results`, {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(response => {
                if (!response.success) {
                    console.log(response.data[0].message);
                } else {
                    setData(setDataCache(dataType, response));
                }
                setLoading(false);
                onDataLoaded(response);
            })
            .catch(error => {
                console.log('Error during fetch: ' + error);
                setLoading(false);
            });
    }

    const setDataCache = (dataType, response) => {
        if (data[dataType] !== undefined) {
            return {
                ...data, [dataType]: {
                    'data': response.data,
                    'pagination': {
                        'totalCount': response.found_count,
                        'perPage': response.per_page,
                        'paginationVars': response.pagination,
                        'paged': data[dataType].pagination.paged 
                    }
                }
            };
        }
    };
    
    const setPaged = (dataType, paged) => {
        if (data[dataType] !== undefined) {
            return {
                ...data, [dataType]: {
                    'data': data[dataType].data,
                    'pagination': {
                        'totalCount': data[dataType].totalCount,
                        'perPage': data[dataType].per_page,
                        'paginationVars': data[dataType].paginationVars,
                        'paged': paged
                    },
                }
            }
        }
    };  

    function setContent() {
        switch (dataType) {
            case 'post':
                if (data[dataType].data.length) return (
                    <div className="article-listing__list">
                        {Object.keys(data[dataType].data).map((value, index) => (
                            <NewsItem item={data.post.data[value]} key={'new-item-' + index} />
                        ))}
                    </div>
                )
                return (
                    <div className="search-results__no-results">
                        We are unable to find any news articles for that search term please try with a different term
                    </div>
                );
                break;
            case 'testimonial':
                if (data[dataType].data.length) return (
                    <ul className="client-reviews__list">
                        {Object.keys(data[dataType].data).map((value, index) => {
                            return (data[dataType].data[value].testimonial_type == 'video' ? (
                                <VideoReview review={data[dataType].data[value]} key={'testimonial-item-' + index} />
                            ) : (
                                <Review review={data[dataType].data[value]} key={'testimonial-item-' + index} />
                            ))
                        })}
                    </ul>
                )
                return (
                    <div className="search-results__no-results">
                        We are unable to find any success stories for that search term please try with a different term
                    </div>
                );
                break;
            case 'services':
                if (data[dataType].data.length) return (
                    <ul className="service__list">
                        {Object.keys(data[dataType].data).map((value, index) => (
                            <ServiceItem service={data[dataType].data[value]} key={'service-item-' + index} />
                        ))}
                    </ul>
                )
                return (
                    <div className="search-results__no-results">
                        We are unable to find any services or products for that search term please try with a different term
                    </div>
                );
                break;
            case 'videos':
                if (data[dataType].data.length) return (
                    <ul className="video-testimonials-list">
                        {Object.keys(data[dataType].data).map((value, index) => (
                            <VideoItem testimonial={data[dataType].data[value]} key={'video-item-' + index} />
                        ))}
                    </ul>
                )
                return (
                    <div className="search-results__no-results">
                        We are unable to find any videos for that search term please try with a different ter
                    </div>
                );
                break;
            case 'faq':
                if (data[dataType].data.length) return (
                    <div className="filter-faqs-list__wrapper">
                        {Object.keys(data[dataType].data).map(key => {
                            let item = data[dataType].data[key];
                            return (<FAQItem key={key} question={item.question} answer={item.answer} defaultOpen={Number(key) === 0 ? true : false} />)
                        })}
                    </div>
                )
                return (
                    <div className="search-results__no-results">
                        We are unable to find any faqs for that search term please try with a different term
                    </div>
                );
                break;
            default:
                return <LoadingIcon />
        }
    }

    // Pagination next
    function handleNextChange() {
        let pagination = data[dataType].pagination.paginationVars;
        if (pagination.current < pagination.total) {
            setData(setPaged(dataType, pagination.current + 1));
            paginationStateUpdate(dataType, data[dataType].pagination.paged, pagination.current + 1);
        }
        scrollToParentRef();
    }
    // Pagination prev
    function handlePrevChange() {
        let paged = data[dataType].pagination.paged;

        if(paged > 1 && paged <= data[dataType].pagination.paginationVars.total) {
            setData(setPaged(dataType, paged - 1));
            paginationStateUpdate(dataType, paged, paged - 1);
        }
        scrollToParentRef();
    }

    if (loading)
        return <LoadingIcon />

    return (
        <div className="container container--large">
            <TotalCount
                count={data[dataType].pagination.totalCount}
            />
            <div className={'search-results search-results-' + dataType}>
                {setContent()}
            </div>
            {data[dataType].pagination.paginationVars && data[dataType].pagination.totalCount > 0 && dataType !== 'services' &&
                <Pagination
                    perPage={data[dataType].pagination.perPage}
                    totalCount={data[dataType].pagination.totalCount}
                    onPrevChange={handlePrevChange}
                    onNextChange={handleNextChange}
                    paginationVars={data[dataType].pagination.paginationVars}
                />
            }
        </div>
    )
}

SearchTabContent.propTypes = {
    searchQuery: PropTypes.string, //text search based query
    dataType: PropTypes.string,  //'news', 'success-stories', 'services', 'videos'
    paginationState: PropTypes.object.isRequired
}

export default SearchTabContent