import React, { useState, useEffect, useRef } from "react";

import Filters from "./Filters";
import Reviews from "./Reviews";
import Pagination from "../Pagination/Pagination";
import LoadingIcon from "../Icons/LoadingIcon";

const VideoReviews = ({ filterHeader = '' }) => {
    //Reviews
    const [reviews, setReviews] = useState(null);
    const [loading, setLoading] = useState(true);
    //Filters
    const [sortQuery, setSortQuery] = useState({ 'sort': 'newest'});
    const [addictions, setAddictions] = useState([]);
    const [customerType, setCustomerType] = useState([]);
    const [filterCount, setFilterCount] = useState(1); //one is for the radio sortQuery
    //Pagination vars
    const [totalCount, setTotalCount] = useState(0);
    const [paginationVars, setPaginationVars] = useState({});
    const [paged, setPaged] = useState(1);
    const [perPage, setPerPage] = useState(0);
    // Mobile Filters
    const [mobileFilterChange, setMobileFilterChange] = useState(false);
    const [mobileFilters, setMobileFilters] = useState(null);
    
    const wrappingRef = useRef(); // for scroll on pagination change

    useEffect(() => {
        fetchReviews();
    }, [sortQuery, addictions, customerType, paged, mobileFilterChange]);

    const formData = new FormData();
    formData.append('security', wp_ajax.security);

    if (!Object.keys(sortQuery).length == 0) {
        formData.append('sort', JSON.stringify(sortQuery));
    }

    if (addictions.length > 0) {
        formData.append('addictions', JSON.stringify(addictions));
    }

    if (customerType.length > 0) {
        formData.append('customerType', JSON.stringify(customerType));
    }

    if (paged) {
        formData.append('paged', JSON.stringify(paged));
    }

    if (mobileFilterChange) { //@todo refactor
        formData.set('sort', JSON.stringify({ 'sort': mobileFilters.sort }));
        if (formData.has('addictions')) {
            formData.set('addictions', JSON.stringify(Array.from(mobileFilters.addictions)));
        } else {
            formData.append('addictions', JSON.stringify(Array.from(mobileFilters.addictions)));
        }
        if (formData.has('customerType')) {
            formData.set('customerType', JSON.stringify(Array.from(mobileFilters.customerType)));
        } else {
            formData.append('customerType', JSON.stringify(Array.from(mobileFilters.customerType)));
        }
    }

    const fetchReviews = async () => {
        await fetch(`${wp_ajax.ajax_url}?action=client_video_reviews`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(response => {
                if (!response.success) {
                    console.log('Error ' + response.data[0].message);
                } else {
                    setReviews(response.data);
                    setLoading(false);
                    setTotalCount(response.found_count);
                    setPaginationVars(response.pagination);
                    setPerPage(response.per_page);
                }
            })
            .catch(error => {
                console.log('Error during fetch: ' + error);
            });
    };

    function handleSortChange(value) {
        setSortQuery({"sort": value});
    }

    function handleAddictionChange(value) {
        const hasAddiction = addictions.includes(value);
        if (hasAddiction) {
            const prevState = addictions;
            const filterAddictions = prevState.filter(item => item !== value);
            setAddictions(filterAddictions);
            setFilterCount(filterCount - 1);
        } else {
            let addictionsArray = [...addictions, value];
            setAddictions(addictionsArray);
            setFilterCount(filterCount + 1);
        }
    }

    const handleAddictionPreChange = (data) => {        
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get('addiction')) {
            const addictionSearchParams = searchParams.get('addiction').split(',');
            const addictionFilters = Object.entries(data).filter(
                addiction => addictionSearchParams.includes(addiction[1].toLowerCase())
            );

            if (addictionFilters.length > 0) {
                // Need to fetch the value which is the first index in the array
                setAddictions(addictionFilters.map(addictionFilter => addictionFilter[0]));
                setFilterCount(filterCount + addictionFilters.length);
            }
        }
    };

    //@todo refactor
    function handleCustomerTypeChange(value) {
        const hasCustomerType = customerType.includes(value);
        if (hasCustomerType) {
            const prevState = customerType;
            const filterCustomerType = prevState.filter(item => item !== value);
            setCustomerType(filterCustomerType);
            setFilterCount(filterCount - 1);
        } else {
            let customerTypeArray = [...customerType, value];
            setCustomerType(customerTypeArray);
            setFilterCount(filterCount + 1);
        }
    }

    const handleCustomerTypePreChange = (data) => {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get('customer')) {
            const customerSearchParams = searchParams.get('customer').split(',');
            const customerFilters = Object.entries(data).filter(
                customer => customerSearchParams.includes(customer[1].toLowerCase())
            );

            if (customerFilters.length > 0) {
                // Need to fetch the value which is the first index in the array
                setCustomerType(customerFilters.map(customerFilter => customerFilter[0]));
                setFilterCount(filterCount + customerFilters.length);
            }
        }
    };

    // Pagination next
    function handleNextChange() {
        if (paginationVars.current < paginationVars.total) {
            setPaged(paginationVars.current + 1);
        }
        wrappingRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    // Pagination prev
    function handlePrevChange(value) {
        if (paged > 1 && paged <= paginationVars.total) {
            setPaged(paged - 1);
        }
        wrappingRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    function handleApplyFilters(filters) {
        setMobileFilters(filters);
        setMobileFilterChange(true);
    }

    if (loading)
        return <LoadingIcon/>

    return (
        <div className="client-reviews__wrapper" ref={wrappingRef}>
            <Filters
                onSortChange={handleSortChange}
                onAddictionChange={handleAddictionChange}
                onAddictionPreChange={handleAddictionPreChange}
                onCustomerTypeChange={handleCustomerTypeChange}
                onCustomerTypePreChange={handleCustomerTypePreChange}
                sortQuery={sortQuery}
                filterCount={filterCount}
                filterHeader={filterHeader}
                applyMobileFilters={handleApplyFilters}
            />
            <Reviews
                data={reviews}
				count={totalCount}
				videoReview={true}
            />
            {paginationVars &&
                <Pagination
                    perPage={perPage}
                    totalCount={totalCount}
                    onPrevChange={handlePrevChange}
                    onNextChange={handleNextChange}
                    paginationVars={paginationVars}
                />
            }
        </div>
    );
};

export default VideoReviews;