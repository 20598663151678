import React from "react";
import Tab from '../../Tabs/Tab';
import PropTypes from 'prop-types';

const proptypes = {
    activeTabIndex: PropTypes.number.isRequired,
    handleTabChange: PropTypes.func.isRequired,
    showOnline: PropTypes.bool
}

const BookingTabs = ({activeTabIndex, handleTabChange, showOnline = false}) => {

    const tabTitle = (subtitle, title) => {
        return (
            <div className="booking-tabs__title-wrap">
                <span className="booking-tabs__subtitle">{subtitle}</span>
                <h4 className="booking-tabs__title">{title}</h4>
            </div>
        )
    }

    return (
        <div className="booking-tabs">
            <div className="tabs__tab-wrapper">
                <div className="tabs__tab-wrapper-inner">
                    <Tab
                        tabTitle={tabTitle('Sort by', 'Distance')}
                        index={1}
                        active={activeTabIndex === 1 ? true : false}
                        changeTab={handleTabChange}
                    />
                    <Tab
                        tabTitle={tabTitle('Sort by', 'Soonest')}
                        index={2}
                        active={activeTabIndex === 2 ? true : false}
                        changeTab={handleTabChange}
                    />
                    {showOnline && (
                        <Tab
                            tabTitle={tabTitle('Sort by', 'Online')}
                            index={3}
                            active={activeTabIndex === 3 ? true : false}
                            changeTab={handleTabChange}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
BookingTabs.propTypes = proptypes;
export default BookingTabs;