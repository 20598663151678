import React, { useEffect, useContext, useState } from 'react';
import { BookingContext } from '../Context/BookingContext';
import Dropdown from '../../Utilities/Dropdown';
import PanelTitle from '../PanelTitle';

const SessionCountPicker = () => {
    const data = [
        {
            value: 1,
            title: '1 Person',
        },
        {
            value: 2,
            title: '2 People',
        },
    ];

    const [bookingState, setBookingState] = useContext(BookingContext);
    const [firstLoading, setFirstLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showComponent, setShowComponent] = useState(false);

    useEffect(() => {
        setLoading(true);

        if (bookingState.sessionType) {
            const onlineLiveGroup =
                bookingState.session.current.key === 'online' &&
                bookingState.sessionType.current.key === 'live-group';
            const locationGroup =
                bookingState.session.current.key === 'location' &&
                bookingState.sessionType.current.key === 'group';
            const voucherState =
                'voucher' in bookingState == false ||
                bookingState.voucher.current.key === 'no';

            if (
                (onlineLiveGroup && voucherState) ||
                (locationGroup && voucherState)
            ) {
                if (firstLoading) {
                    handleSessionCountSelection(data[0]);
                    setFirstLoading(false);
                    handleSessionCountPreSelection();
                }

                setShowComponent(true);
            } else {
                setShowComponent(false);
            }
        } else {
            setShowComponent(false);
        }

        setLoading(false);
    }, [bookingState.voucher, bookingState.sessionType]);

    /**
     * @returns HTML
     */
    const helpContent = () => {
        return (
            bookingState.data.help.session_count_content !== '' && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: bookingState.data.help.session_count_content,
                    }}
                />
            )
        );
    };

    const handleSessionCountSelection = item => {
        let oldData = bookingState;
        let newData = {
            ...oldData,
            sessionCount: {
                title: item.title,
                value: item.value,
            },
        };
        setBookingState(newData);
    };

    /**
     * handleSessionCountPreSelection
     * Updates current addiction in booking state based on query param
     */
    const handleSessionCountPreSelection = () => {
        const searchParams = new URLSearchParams(window.location.search);
        if (
            searchParams.get('addiction_id') &&
            searchParams.get('session') &&
            searchParams.get('session_type') &&
            searchParams.get('session_count')
        ) {
            const sessionCountItem = data.find(
                sessionCount => sessionCount.value == searchParams.get('session_count'),
            );

            if (sessionCountItem) {
                let oldData = bookingState;
                let newData = {
                    ...oldData,
                    sessionCount: sessionCountItem,
                };
                setBookingState(newData);
            }
        }
    };

    if (!showComponent) return <></>;

    return (
        <div className="session-count-picker u-relative">
            <PanelTitle
                title="How many people are you booking for?"
                helpContent={helpContent()}
            />
            {loading && <LoadingIcon />}
            {!loading && data && (
                <Dropdown
                    options={data}
                    handleSelection={handleSessionCountSelection}
                    defaultValue={'sessionCount' in bookingState ? bookingState.sessionCount.value : 1}
                />
            )}
            <div className="booking__seperator"></div>
        </div>
    );
};

export default SessionCountPicker;
