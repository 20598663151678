const openFAQ = document.querySelectorAll('[data-behaviour="open-faq"]');

openFAQ.forEach(button => {
    button.addEventListener('click', function (e) {

        const answer = this.closest('.frequently-asked-questions__tile');

        if ( answer.classList.contains('frequently-asked-questions__tile--open') ) {
            answer.classList.remove('frequently-asked-questions__tile--open');
        } else {
            answer.classList.add('frequently-asked-questions__tile--open');
        }

    });
});