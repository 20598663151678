export function getRemainingTime(deadline) {
    const deadlineDate = new Date(deadline);
    const total = Date.parse(deadlineDate) - Date.parse(new Date());
    const days = getTimeUnit(total / (1000 * 60 * 60 * 24)),
        hours = getTimeUnit((total / (1000 * 60 * 60)) % 24),
        minutes = getTimeUnit((total / 1000 / 60) % 60),
        seconds = getTimeUnit((total / 1000) % 60);

    return {
        total: total,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
    };
}

export function getTimeUnit(timeCalc) {
    return timeCalc > 0 ? Math.floor(timeCalc) : 0;
}

export function updateTimerValue(value) {
    return ('0' + value).slice(-2);
}

export function initializeTimer(countdown, deadline) {
    let timeInterval,
        days = countdown.querySelector('[data-target="countdown-days"]'),
        hours = countdown.querySelector('[data-target="countdown-hours"]'),
        minutes = countdown.querySelector('[data-target="countdown-minutes"]'),
        seconds = countdown.querySelector('[data-target="countdown-seconds"]');

    if (days !== null && hours !== null && minutes !== null && seconds !== null) {
        function updateTimer() {
            const timer = getRemainingTime(deadline);

            days.innerHTML = updateTimerValue(timer.days);
            hours.innerHTML = updateTimerValue(timer.hours);
            minutes.innerHTML = updateTimerValue(timer.minutes);
            seconds.innerHTML = updateTimerValue(timer.seconds);

            if (timer.total <= 0) {
                clearInterval(timeInterval);
            }
        }

        updateTimer();
        timeInterval = setInterval(updateTimer, 1000);
    }
}

export function initializeTimers() {
    const countdownTimers = document.querySelectorAll('[data-behaviour="countdown-timer"]');
    if (countdownTimers.length > 0) {
        countdownTimers.forEach(countdownTimer => {
            initializeTimer(countdownTimer, countdownTimer.dataset.timer);
        });
    }
}

initializeTimers();
