import React from 'react';
import PropTypes from 'prop-types';
//PropTypes
const proptypes = {
    button: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};
/**
 * Button
 * @todo make into a href and give options
 * @param {proptypes} param0
 * @returns
 */
const Button = ({ button, handleSubmit, cssClass = 'button-wrapper' }) => {
    return (
        <div className={cssClass}>
            <button className={cssClass + '__button'} onClick={handleSubmit}>
                {button.text}
            </button>
        </div>
    );
};

Button.propTypes = proptypes;
export default Button;
