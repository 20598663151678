import React, { useEffect, useContext, useRef, useState } from 'react';
import { BookingContext } from '../Context/BookingContext';
import Dropdown from '../../Utilities/Dropdown';
import LoadingIcon from '../../Icons/LoadingIcon';
import PanelTitle from '../PanelTitle';
import {
    googleGeocodeSearch,
    validatePostcode,
} from '../../../modules/utilities';

const LocationPicker = () => {
    const [bookingState, setBookingState] = useContext(BookingContext);
    const [loading, setLoading] = useState(true);
    const [firstLoading, setFirstLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showComponent, setShowComponent] = useState(false);
    const [locationValue, setLocationValue] = useState('');
    const [locationName, setLocationName] = useState('postcode');
    const [locationPlaceholder, setLocationPlaceholder] = useState('W1G 6BP');
    const [validateLocation, setValidateLocation] = useState(true);
    const [countryValue, setCountryValue] = useState('gb');

    useEffect(() => {
        setLoading(true);
        //hide if no count person
        if (bookingState.sessionType) {
            if (
                bookingState.session.current.key === 'location' &&
                bookingState.sessionType.current.key === 'group' &&
                bookingState.sessionCount?.value > 0 &&
                ('voucher' in bookingState == false ||
                    bookingState.voucher.current.key === 'no')
            ) {
                setShowComponent(true);
            } else {
                setShowComponent(false);
            }
        } else {
            setShowComponent(false);
        }

        setLoading(false);
    }, [
        bookingState.voucher,
        bookingState.sessionType,
        bookingState.sessionCount?.value,
    ]);

    useEffect(() => {
        if (firstLoading) {
            setFirstLoading(false);
            setLoading(true);
            handleLocationPreSelection();
        }
    }, []);

    const validate = async () => {
        if (locationValue === '') {
            setError('Please enter a value');
        } else if (
            validateLocation &&
            !validatePostcode(locationValue, countryValue)
        ) {
            setError(`Please enter a valid ${locationName}`);
        } else {
            setError(null);

            let location = await googleGeocodeSearch(
                locationValue,
                countryValue === 'gb' ? 'UK' : countryValue.toUpperCase(),
            );

            let oldData = bookingState;
            let newData = {
                ...oldData,
                location: location,
                country: countryValue.toUpperCase()
            };
            setBookingState(newData);
        }
    };

    /**
     * handleLocationPreSelection
     * Updates current addiction in booking state based on query param
     */
    const handleLocationPreSelection = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        if (
            searchParams.get('addiction_id') &&
            searchParams.get('session') &&
            searchParams.get('session_type') &&
            searchParams.get('postcode')
        ) {
            let country = searchParams.get('country');
            if (country === null) {
                country = 'gb';
            }
            setLocationValue(searchParams.get('postcode'));
            setCountryValue(country);
            updateLocationLabels(country);
            await validate();
        } else if ('geo' in bookingState) {
            setCountryValue(bookingState.geo.country.toLowerCase());
            updateLocationLabels(bookingState.geo.country.toLowerCase());
        }
        setLoading(false);
    };

    const handleCountrySelection = item => {
        setCountryValue(item.value);
        updateLocationLabels(item.value);
        if (locationValue !== '') {
            validate();
        }
    };

    const updateLocationLabels = country => {
        const countryData = bookingState.countryData[country.toUpperCase()];
        if (typeof countryData !== 'undefined') {
            if ('postcode' in countryData) {
                // No postcode for this country so need to disable validate
                if (
                    'hidden' in countryData.postcode &&
                    countryData.postcode.hidden
                ) {
                    setValidateLocation(false);
                    setLocationName('city');
                    setLocationPlaceholder('City');
                } else if (
                    'label' in countryData.postcode &&
                    countryData.postcode.label === 'ZIP'
                ) {
                    setLocationName('zip code');
                    setLocationPlaceholder('Zip code');
                } else {
                    setLocationName('post code');
                    setLocationPlaceholder('Post code');
                }
            }
        }
    };

    const getCountryData = () => {
        let countries = [];
        if ('countries' in bookingState.data) {
            for (let i = 0; i < bookingState.data.countries.length; i++) {
                countries.push({
                    value: bookingState.data.countries[i].slug,
                    title: bookingState.data.countries[i].title,
                });
            }
        }
        return countries;
    };

    if (!showComponent) return <></>;

    if (loading) return <LoadingIcon />;

    return (
        <div className="location-picker">
            <PanelTitle title={`Enter your ${locationName}`} />
            {error && <span className="location-picker__error">{error}</span>}
            <Dropdown
                options={getCountryData()}
                handleSelection={handleCountrySelection}
                defaultValue={countryValue}
            />
            <div className="location-picker__search">
                <input
                    type="text"
                    placeholder={`E.g. ${locationPlaceholder}`}
                    defaultValue={locationValue}
                    onChange={event => setLocationValue(event.target.value)}
                />
                <button
                    className="btn location-picker__button"
                    onClick={() => validate()}>
                    Find Seminars
                </button>
            </div>
        </div>
    );
};

export default LocationPicker;
