// WRAP ALL FORM SECTIONS
for(var i = 1; i <= 10; i++) {
    $('.gravity-form-sections .section-'+ i +'').wrapAll('<div data-section-count="'+ i +'" class="form-section__wrap" /> <div class="form-section__inner-wrap" />')
}

// MOVE SECTION HEADER OUT OF INNER WRAP
$('.gravity-form-sections .gfield_html_formatted.header').each(function() {
    const get_section_number = $(this).parent('.form-section__inner-wrap').parent('.form-section__wrap').attr('data-section-count')
    $(this).prependTo( $(this).parent('.form-section__inner-wrap').parent('.form-section__wrap') );
    $('<span>'+ get_section_number +'</span>').prependTo( $(this) );
});

// ASSIGN REQUIRED FIELDS COUNT TO SECTION
$('.gravity-form-sections .form-section__wrap').each(function() {
    const get_section_count = $(this).attr('data-section-count')
    const required_fields_count = $('[data-section-count="'+ get_section_count +'"] .form-section__inner-wrap .gfield_contains_required').length;
    $(this).attr('data-required-fields-count', required_fields_count);
});

$('.gravity-form-sections .gfield input, .gravity-form-sections .gfield select, .gravity-form-sections .gfield textarea').change(function(){
    let required_count = 0
    let get_section_count = $(this).parent('.ginput_container').parent('.gfield').parent('.form-section__inner-wrap').parent('.form-section__wrap').attr('data-section-count')
    const get_required_fields_count = $(this).parent('.ginput_container').parent('.gfield').parent('.form-section__inner-wrap').parent('.form-section__wrap').attr('data-required-fields-count')
    let total_sections_count = $('.form-section__wrap').length;
    total_sections_count--;

    $('[data-section-count="'+ get_section_count +'"] input, [data-section-count="'+ get_section_count +'"] textarea, [data-section-count="'+ get_section_count +'"] select')
    .each(function() {
        var value = $(this).val();

        if ( value !== '') {
            required_count++
        }
    });

    if ( required_count == get_required_fields_count ) {

        $('[data-section-count="'+ get_section_count +'"]').addClass('section-complete')

        if ( get_section_count == total_sections_count ) {
            $('.gravity-form-sections_wrapper').addClass('form-complete');
        }

        get_section_count++;

        $('[data-section-count="'+ get_section_count +'"]').addClass('show-section')
    }

});

// // ES6 
// function wrap(el, wrapper) {
//     if (!el || !wrapper) return;
//     el.parentNode.insertBefore(wrapper, el);
//     wrapper.appendChild(el);
// }

// // WRAP ALL FORM SECTIONS
// for(var i = 1; i <= 10; i++) wrap(document.querySelector(`.gravity-form-sections .section-${i}`), '<div data-section-count="'+ i +'" class="form-section__wrap" /> <div class="form-section__inner-wrap" />');

// // MOVE SECTION HEADER OUT OF INNER WRAP
// [...document.querySelectorAll('.gravity-form-sections .gfield_html_formatted.header')].map(el => {
//     const { sectionCount } = el.closest('.form-section__wrap').dataset;
//     el.closest('.form-section__wrap').prepend(el);
//     el.prepend(`<span>${sectionCount}</span>`);
// });

// // ASSIGN REQUIRED FIELDS COUNT TO SECTION
// [...document.querySelectorAll('.gravity-form-sections .form-section__wrap')].map((el) => {
//     const sectionCount = el.dataset;
//     const requiredFieldsCount = $(`[data-section-count="${sectionCount}"] .form-section__inner-wrap .gfield_contains_required`).length;
//     el.setAttribute('data-required-fields-count', requiredFieldsCount);
// });

// document.addEventListener('change', e => {
//     if (!(e.target.matches('.gravity-form-sections .gfield input') || e.target.matches('.gravity-form-sections .gfield select') || e.target.matches('.gravity-form-sections .gfield textarea'))) return;

//     let {sectionCount, requiredFieldsCount} = e.target.closest('.form-section__wrap').dataset
//     let totalSectionsCount = document.querySelectorAll('.form-section__wrap').length - 1;
//     const inputs = [...document.querySelectorAll(`[data-section-count="${get_section_count}"] input, [data-section-count="${get_section_count}"] textarea, [data-section-count="${get_section_count}"] select`)];
//     const requiredCount = inputs.reduce((carry, el) => carry += el.value !== '' ? 1 : 0, 0);

//     if ( requiredCount == requiredFieldsCount ) {
//         document.querySelector(`[data-section-count="${get_section_count}"]`).classList.add('section-complete');

//         if ( sectionCount == totalSectionsCount ) document.querySelector('.gravity-form-sections_wrapper').classList.add('form-complete');

//         sectionCount++;
//         document.querySelector(`[data-section-count="${sectionCount}"]`).classList.add('show-section');
//     }

// });

