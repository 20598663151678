import { wrapAll, getNextUntil, setFormSectionAttrs } from './utilities';

// add .gform-accordion to the form classes to make this work
[...document.querySelectorAll('.gform-accordion')].map((accordion, x) => {
    accordion.setAttribute('data-accordion-container', x);

    [...accordion.querySelectorAll('.gfield.gsection')].map((section, y) => {
        // add counter
        const title = section.querySelector('.gsection_title');
        const counter = document.createElement('span');
        counter.classList.add('gsection_counter');
        counter.innerText = y + 1;
        title.prepend(counter);
        title.setAttribute('data-accordion-select', y);
        title.setAttribute('data-accordion-scroll-to', y);

        // wrap section fields
        const wrapper = document.createElement('div');
        wrapper.classList.add('gsection_wrapper');
        wrapper.setAttribute('data-accordion-success', false);
        wrapper.setAttribute('data-accordion-row', y);

        // set first to active
        if (y === 0) wrapper.classList.add('active');
        // wrap all fields and the section in the wrapper
        wrapAll(
            [section, ...getNextUntil(section, '.gfield.gsection')],
            wrapper,
        );

        return true;
    });

    /**
     * Validate form sections with a tick if they are completed.
     * This only checks required fields.
     */
    const wrapperSections = accordion.querySelectorAll('[data-accordion-row]');
    if (accordion.closest('.gform_validation_error')) {
        //On load event
        window.addEventListener('load', () => {
            wrapperSections.forEach(accordionSection => {
                setFormSectionAttrs(accordionSection, true);
            });
        });
    }
    //On form change event
    accordion.addEventListener('change', () => {
        wrapperSections.forEach(accordionSection => {
            setFormSectionAttrs(accordionSection);
        });
    });
});