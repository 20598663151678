import React, { useEffect, useState } from "react";
import { initializeTimers } from '../../modules/countdown-timer';

const CountdownTimer = ({ date }) => {
    const [countdownDate, setCountdownDate] = useState(date);    

    useEffect(() => {
        initializeTimers();
    }, [countdownDate]);

    return countdownDate && (
        <div className="countdown-timer" data-behaviour="countdown-timer" data-timer={countdownDate}>
            <div className="countdown-timer__unit">
                <span className="countdown-timer__value" data-target="countdown-days">00</span>
                <h4 className="countdown-timer__name">Days</h4>
            </div>
            <div className="countdown-timer__unit">
                <span className="countdown-timer__value" data-target="countdown-hours">00</span>
                <h4 className="countdown-timer__name">Hours</h4>
            </div>
            <div className="countdown-timer__unit">
                <span className="countdown-timer__value" data-target="countdown-minutes">00</span>
                <h4 className="countdown-timer__name">Minutes</h4>
            </div>
            <div className="countdown-timer__unit">
                <span className="countdown-timer__value" data-target="countdown-seconds">00</span>
                <h4 className="countdown-timer__name">Seconds</h4>
            </div>
        </div>
    );
}

export default CountdownTimer;
