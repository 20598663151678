import React from "react";
import PropTypes from "prop-types";
const proptypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired
}
const TestimonialCard = ({quote, author, image}) => {

    return (
        <div className="results-card__testimonial">
            {image && 
                <div className="results-card__testimonial-image">
                    <img src={image} />
                </div>
            }
            <div className="results-card__testimonial-content">
                <h4 className="results-card__testimonial-quote">"{quote}"</h4>
                <p className="results-card__testimonial-author">{author}</p>
            </div>
        </div>
    )
}
TestimonialCard.proptypes = proptypes;
export default TestimonialCard;