import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { replaceHistory, pushHistory } from "../../modules/utilities";
import { deleteFromSet, addToSet, areEqualSets } from "../Utilities/Functions";

import LoadingIcon from "../Icons/LoadingIcon";
import Pagination from "../Pagination/Pagination";
import Checkbox from "../FormElements/Checkbox";
import FAQItem from "./FAQitem";

const proptypes = {
    dataType: PropTypes.string,  //'live-seminar', 'ovp' etc.
    tabs: PropTypes.object,
    paginationState: PropTypes.object
}

const FAQsTabContent = ({ dataType, tabs, scrollToParentRef, paginationState, paginationStateUpdate, onDataLoaded }) => {
    // Loading
    const [loading, setLoading] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [dataLoading, setDataLoading] = useState(false); //only fires on desktop when filters change
    //Filters
    const [openFilters, setOpenFilters] = useState(false);// for mobile
    const [disableFilters, setDisableFilters] = useState(true); // for mobile
    const [filterCount, setFilterCount] = useState(0);
    const [schema, setSchema] = useState({});

    const defaultDataObject = {
        'data': [],
        'addictions': [],
        'cached': false,
        'pagination': {
            'totalCount': 0,
            'perPage': 0,
            'paged': paginationState[dataType].new ?? 1,
            'paginationVars': {}
        },
    }

    const createDefaultDataState = () => {
        let data = {};

        Object.keys(tabs).forEach(key => {
            data = {
                ...data, [key]: defaultDataObject
            }
        })
        return data;
    }

    const [data, setData] = useState(createDefaultDataState());

    const createDefaultFilters = () => {
        let data = {};

        Object.keys(tabs).forEach(key => {
            data = {
                ...data, [key]: {
                    'old': new Set(),
                    'new': new Set()
                }
            }
        })
        return data;
    }

    const [filterSelection, setFilterSelection] = useState(createDefaultFilters());

    /**
     * Observes if element is visible on page
     */
    let observer = new IntersectionObserver(function (entries) {
        if (entries[0]['intersectionRatio'] == 0) {
            // element is hidden
            setDisableFilters(false);
        } else {
            // element is visible
            setDisableFilters(true);
        }
    }, { root: document.documentElement });

    //Change of tab update
    useEffect(() => {
        if (data && dataType && data[dataType].data.length == 0 && !data[dataType].cached) {
            setLoading(true);
            fetchData(true);
            setFirstLoad(false);
        } else if (
            data &&
            dataType &&
            data[dataType].cached &&
            paginationState[dataType].new &&
            paginationState[dataType].new !== 1
        ) {
            //updates url when there is a cached value > 1
            pushHistory('current-page', paginationState[dataType].new)
        }

        return;

    }, [dataType]);
    //Paginattion new state update
    useEffect(() => {
        if (!firstLoad &&
            paginationState[dataType] &&
            paginationState[dataType].old !== paginationState[dataType].new
        ) {
            setDataLoading(true);
            fetchData();
        }
        return;

    }, [paginationState[dataType].new]);

    // Active filters update
    useEffect(() => {
        const filtersEqual = areEqualSets(filterSelection[dataType].old, filterSelection[dataType].new);

        if (!firstLoad && !dataLoading && !disableFilters && !filtersEqual) {
            setDataLoading(true);
            fetchData();

            setFilterSelection({
                ...filterSelection, [dataType]: {
                    'old': new Set(filterSelection[dataType].new),
                    'new': new Set(filterSelection[dataType].new)
                }
            })
        }

        return;
    }, [filterSelection[dataType].new.size]); // backup data[dataType].activeAddictions.size

    /**
     * fetchs data ie. FAQ's
     */
    const fetchData = async (tabChange = false) => {

        const formData = new FormData();
        let paged;

        if (paginationState[dataType].old !== paginationState[dataType].new) {
            paged = paginationState[dataType].new;
            formData.set('paged', paged);
        }

        formData.set('security', wp_ajax.security);
        formData.set('datatype', dataType);
        formData.set('addictions', JSON.stringify(Array.from(filterSelection[dataType].new)));

        await fetch(`${wp_ajax.ajax_url}?action=faq_search_results`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(response => {
                if (!response.success) {
                    console.log(response.data[0].message);
                } else {
                    setData(setDataCache(dataType, response));
                    setSchema(response.schema);
                    setDataLoading(false);
                    setLoading(false);
                    observer.observe(document.querySelector(".filter-faqs__dropdown"));
                    onDataLoaded(response);

                    if (tabChange && paginationState[dataType].old == paginationState[dataType].new)
                        paged = paginationState[dataType].new;

                    if (!paged || paged == 1) {
                        replaceHistory('current-page');
                    } else {
                        pushHistory('current-page', Number(paged));
                    }

                }
            })
            .catch(error => {
                console.log('Error during fetch: ' + error);
            });
    }

    /**
     * Set data cache
     * Caches data after every request to save on additional load
     * 
     * @param {string} dataType Tab string
     * @param {object} response response obj from server
     * @returns 
     */
    const setDataCache = (dataType, response) => {
        if (data[dataType] !== undefined) {
            return {
                ...data, [dataType]: {
                    'data': response.data,
                    'addictions': response.addictions,
                    'cached': true,
                    'pagination': {
                        'totalCount': response.found_count,
                        'perPage': response.per_page,
                        'paginationVars': response.pagination,
                        'paged': data[dataType].pagination.paged
                    }
                }
            };
        }
    };

    /**
     * Updates pagination of paged value
     * 
     * @param {string} dataType Tab string
     * @param {integer} paged Current page
     * @returns 
     */
    const setPaged = (dataType, paged) => {
        if (data[dataType] !== undefined) {
            return {
                ...data, [dataType]: {
                    ...data[dataType],
                    'pagination': {
                        ...data[dataType].pagination,
                        'paged': paged
                    }
                }
            }
        }
    };

    /**
     * Handles dropdown click on mobile
     */
    function handleDropDownClick() {
        setOpenFilters(prevState => !prevState);
    }

    /**
     * Fires when you check uncheck a filter item
     * @param {object} event 
     */
    function onFilterChange(event) {
        // prevent changing of addicitions whilst has already been clicked / loading results
        if (dataLoading) {
            event.target.checked = !event.target.checked;
            return;
        }

        let value = event.target.value;
        onFilterItemChange('activeAddictions', Number(value));
    }
    /**
     * OnfilterItemChange
     * 
     * Fires when you check/uncheck a box in filters
     * updates state to set activeFilters to the term id values
     * 
     * @param {number} value Integer of term id 
     */
    function onFilterItemChange(dataName, value) {

        if (typeof value !== 'number') return;

        let current = filterSelection[dataType];

        if (current) {
            let oldSet = new Set(current.new);
            let newSet = new Set(current.new);
            if (newSet.has(value)) {
                deleteFromSet(newSet, value);
            } else {
                addToSet(newSet, value);
            }
            setFilterCount(newSet.size);
            setFilterSelection({
                ...filterSelection, [dataType]: {
                    'old': oldSet,
                    'new': new Set(newSet)
                }
            })
        }
    }

    /**
     * Uncheck all checkboxes
     * onfilterchange handles the state
     */
    function resetFilters() {
        document.querySelectorAll('.filter-faqs__filters-list input[type=checkbox]').forEach(el => el.checked = false);
        setFilterCount(0);
    }

    /**
     * Set content
     * Loop through datatype and set FAQItems
     */
    function setContent() {
        return (
            <div className="filter-faqs-list__wrapper">
                {Object.keys(data[dataType].data).map(key => {
                    let item = data[dataType].data[key];
                    return (<FAQItem key={key} question={item.question} answer={item.answer} defaultOpen={Number(key) === 0 ? true : false} />)
                })}
            </div>
        )
    }

    // Pagination next
    function handleNextChange() {
        let pagination = data[dataType].pagination.paginationVars;
        if (pagination.current < pagination.total) {
            setData(setPaged(dataType, pagination.current + 1));
            paginationStateUpdate(dataType, data[dataType].pagination.paged, pagination.current + 1);
        }
        scrollToParentRef();
    }
    // Pagination prev
    function handlePrevChange() {
        let paged = data[dataType].pagination.paged;

        if (paged > 1 && paged <= data[dataType].pagination.paginationVars.total) {
            setData(setPaged(dataType, paged - 1));
            paginationStateUpdate(dataType, paged, paged - 1);
        }
        scrollToParentRef();
    }

    if (loading)
        return <LoadingIcon />

    return (
        <div className="filter-faqs__content-wrapper container container--large">
            <div className="filter-faqs__filters">
                <div className="filter-faqs__dropdown" onClick={handleDropDownClick}>
                    <h5 className="filter-faqs__dropdown-title">Choose an addiction<span className="filter-faqs__filter-count">{filterCount}</span></h5>
                    <span className="material-icons">{(openFilters ? 'close' : 'sort')}</span>
                </div>
                <div className={'filter-faqs__filters-list' + (openFilters ? ' filter-faqs__filters-list--open' : '')}>
                    <div className="triangle-top"></div>
                    <h3 className="filter-faqs__filters-heading">Filter by addiction</h3>
                    <div className="standard-form">
                        {Object.keys(data[dataType].addictions).map(key => {
                            let addiction = data[dataType].addictions[key];
                            let initialChecked = filterSelection[dataType].new.has(addiction.id);
                            return (
                                <Checkbox
                                    key={'addiction-filter-' + dataType + '-' + key}
                                    initialChecked={initialChecked}
                                    title={addiction.name}
                                    id={addiction.id}
                                    onChange={onFilterChange}
                                />
                            )
                        })}
                    </div>
                    <div className="filter-faqs__buttons">
                        <button className="button" onClick={() => !setOpenFilters(false) && fetchData()}>Apply filters</button>
                        <button onClick={resetFilters}>Reset</button>
                    </div>
                </div>
            </div>
            <div className={'filter-faqs__content filter-faqs__content-' + dataType}>
                {dataLoading && <LoadingIcon />}
                {!dataLoading &&
                    <>
                        {setContent()}
                        {schema && <script type="application/ld+json">{schema}</script>}
                        {data[dataType].pagination.paginationVars &&
                            <Pagination
                                perPage={data[dataType].pagination.perPage}
                                totalCount={data[dataType].pagination.totalCount}
                                onPrevChange={handlePrevChange}
                                onNextChange={handleNextChange}
                                paginationVars={data[dataType].pagination.paginationVars}
                            />
                        }
                    </>
                }
            </div>
        </div>
    )
}

FAQsTabContent.propTypes = proptypes;

export default FAQsTabContent