import Search from './Search';

export default class Location extends Search {
    /**
     *
     * @param {Object} options - The options to be passed to the query
     * @param {string} options.type - The type/index of search to be performed
     * @param {array} options.addiction - The addiction(s) (id) to be searched
     * @param {boolean} options.online - Whether or not to search online seminars
     * @param {boolean} options.group - Whether or not to search group seminars
     * @param {number} options.perPage - The number of results to return per page
     * @param {number} options.page - The page number to return
     * @param {string} options.sort - The sortby method to return the results in, either 'soonest' or 'distance'
     * @param {number} options.distance - The distance to search from the geolocation provided via options.location
     * @param {object} options.location - The geolocation lat & lng hash to search with
     * @param {string} options.startDate - The start date to search for
     * @param {string} options.endDate - The end date to search for
     * @param {number} options.multiSiteId - The multisite id to prefix document ids with
     * @param {string} headOfficeId - The id of the head office online location to search
     * @param {boolean} showHeadOffice - The whether search for the head office location
     */
    constructor(options) {
        super(options);

        this.searchEndpoint = `${process.env.ES_ENDPOINT}/location/_search/`;
    }
}
