import React, { lazy, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//Styles for all components
lazy(() => import('./Stylesheets/style.scss'));
//Partials
import Button from './Partials/Button';
import LoadingIcon from './Partials/Loading';
//Field List
const TextField = lazy(() => import('./Components/TextField'));
const TextareaField = lazy(() => import('./Components/TextareaField'));
const NumberField = lazy(() => import('./Components/NumberField'));
const CheckboxFields = lazy(() => import('./Components/CheckboxFields'));
const RadioFields = lazy(() => import('./Components/RadioFields'));
const SelectField = lazy(() => import('./Components/SelectField'));
const HiddenField = lazy(() => import('./Components/HiddenField'));
//functions
import {
    formatFieldId,
    getCheckboxDefaultValue,
    getRadioDefaultValue,
    getSelectDefaultValue,
} from './Utilities/Helper';
//PropTypes
const proptypes = {
    formId: PropTypes.number.isRequired,
};

/**
 * Form Component
 * @returns
 */
const Form = ({ formId, hasBorder = true, showTag = true }) => {
    const publicKey = process.env.GF_FORMS_PUBLIC_KEY;
    const [form, setForm] = useState({});
    const [loading, setLoading] = useState(true);
    const [formState, setFormState] = useState({});
    const [errors, setErrors] = useState({});
    const [confirmationMessage, setConfirmationMessage] = useState('');

    //components need to be referenced in this way to dynamically load them below
    const components = {
        text: TextField,
        textarea: TextareaField,
        number: NumberField,
        checkbox: CheckboxFields,
        radio: RadioFields,
        select: SelectField,
        hidden: HiddenField,
    };

    //Fetch the form
    useEffect(() => {
        fetchForm(formId, publicKey);
    }, []);

    /**
     * Fetch form request
     * @param {integer} formID
     * @param {string} key
     */
    const fetchForm = async (formID, key) => {
        const formData = new FormData();
        formData.append('security', wp_ajax.security);
        formData.append('form', Number(formID));
        formData.append('public_key', key);

        await fetch(`${wp_ajax.ajax_url}?action=get_form`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(response => {
                if (!response.status == 'success') {
                    console.log('Error ' + response.data[0].message);
                } else {
                    setFormState(setInitialFormState(response?.data?.fields));
                    setForm(response.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                console.log(`Error during fetch: ${error}`);
            });
    };

    /**
     * Set initial form state
     * @param {array} fields -- array of field objects
     */
    const setInitialFormState = fields => {
        let defaultObject = {};

        Object.keys(fields).map(fieldIndex => {
            const field = fields[fieldIndex];
            const inputName = `input_${formatFieldId(field.id)}`;

            switch (field.type) {
                case 'text':
                case 'hidden':
                case 'number':
                    if (field?.defaultValue)
                        defaultObject[inputName] = field.defaultValue;
                    break;
                case 'radio':
                    let radioValue = getRadioDefaultValue(field?.choices);
                    if (radioValue) defaultObject[inputName] = radioValue;
                    break;
                case 'checkbox':
                    let checkboxValue = getCheckboxDefaultValue(field?.inputs);
                    if (checkboxValue)
                        defaultObject[checkboxValue.name] = checkboxValue.value;
                    break;
                case 'select':
                    let selectValue = getSelectDefaultValue(field?.choices);
                    if (selectValue) defaultObject[inputName] = selectValue;
                    break;
                default:
                    break;
            }
        });

        return defaultObject;
    };

    /**
     * Handle Form change and update form state
     * @todo implement Memo for errors to prevent/update component
     * @param {int} fieldId
     * @param {string} value
     */
    const handleFormChange = (fieldId, value) => {
        let newState;

        if (value === formState[fieldId]) {
            let currentState = formState;
            delete currentState[fieldId];
            newState = currentState;
        } else {
            newState = {
                ...formState,
                [fieldId]: value,
            };

            //This removes errors if they exist on the on change event
            if (errors[fieldId]) {
                //find error for fDeldID
                //if error exists update set errors
                let currentErrors = errors;
                delete currentErrors[fieldId];
                setErrors({ ...currentErrors });
            }
        }
        setFormState(newState);
    };

    /**
     * Handle errors
     * Parse to all components
     * @param {object} validation_errors
     */
    const handleErrors = validation_errors => {
        let formErrors = {};
        for (const [key, value] of Object.entries(validation_errors)) {
            formErrors[`input_${key}`] = value;
        }
        setErrors(formErrors);
    };

    /**
     * Submit Form
     * @todo confirmation - verify page and redirect on confirmations implement
     * @param {object} e
     */
    const submit = async e => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('security', wp_ajax.security);
        formData.append('form', Number(form.id));
        formData.append('public_key', publicKey);
        formData.append('form_data', JSON.stringify(formState));

        await fetch(`${wp_ajax.ajax_url}?action=submit_form`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(response => {
                if (!response.status == 'success') {
                    console.log('Error ' + response.data[0].message);
                } else {
                    if (response.data.is_valid === false) {
                        handleErrors(response.data.validation_messages);
                    } else {
                        switch (response.data.confirmation_type) {
                            case 'message':
                                setConfirmationMessage(
                                    response.data.confirmation_message,
                                );
                                break;
                            //@todo recieve and add the below GF Notifications
                            // case 'redirect':
                            // 	console.log(response.data);
                            // 	break;
                            // case 'page':
                            // 	console.log(response.data);
                            // 	break
                            default:
                                break;
                        }
                    }
                }
            })
            .catch(error => {
                console.log(`Error during fetch: ${error}`);
            });
    };

    /**
     * Render fields
     * @returns
     */
    const renderFields = () => {
        return form?.fields?.map(field => {
            const Component =
                field?.type in components ? components[field.type] : null;

            if (Component === null) {
                console.error('Field component not found', field);
                return <></>;
            }

            return (
                <Suspense key={`suspense_key_${field.id}`} fallback={''}>
                    <Component
                        field={field}
                        key={`field_key_${field.id}`}
                        onChange={handleFormChange}
                        errors={errors}
                    />
                </Suspense>
            );
        });
    };

    if (loading) return <LoadingIcon />;

    return (
        <div
            className={`form-container${
                hasBorder ? '' : ' form-container--no-border'
            }`}>
            {showTag && (
                <div className="form-container__label">
                    <span className="material-icons material-icons-outlined form-container__label-icon">
                        verified_user
                    </span>
                    <span className="form-container__label-text">
                        Complete Privacy
                    </span>
                </div>
            )}
            {form.title && <h3 className="form-title">{form.title}</h3>}
            {form.description && (
                <p className="form-description">{form.description}</p>
            )}
            {!confirmationMessage && (
                <form
                    className="form"
                    onSubmit={e => {
                        e.preventDefault();
                    }}>
                    {renderFields()}
                    {form.button && (
                        <Button button={form.button} handleSubmit={submit} />
                    )}
                </form>
            )}
            {confirmationMessage && (
                <div
                    className="form-confirmation"
                    dangerouslySetInnerHTML={{
                        __html: confirmationMessage,
                    }}></div>
            )}
        </div>
    );
};
Form.propTypes = proptypes;
export default Form;
