import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ListItem from './ListItem';

const proptypes = {
    item: PropTypes.object.isRequired,
};

const IconList = ({ item }) => {
    const [locationDropDownState, setLocationDropDownState] = useState(false);

    const locationString = () => {
        let title = item.seminarLocation.display_title !== ''
            ? `${item.seminarLocation.display_title}`
            : `${item.seminarLocation.title}`;
        if (
            item.location !== null &&
            typeof item.location === 'object' &&
            'distance' in item.location &&
            item.location.distance !== '' &&
            item.location.measurement !== ''
        ) {
            title += ` (${item.location.distance}${item.location.measurement})`;
        }
        return (
            <>
                {title}
                {!item.online &&
                    <span
                        className={
                            'material-icons results-card__dropdown-button' +
                            (locationDropDownState
                                ? ' results-card__dropdown-button--active'
                                : '')
                        }
                        onClick={() =>
                            setLocationDropDownState(!locationDropDownState)
                        }>
                        arrow_drop_down
                    </span>
                }
            </>
        );
    };

    const locationDropDownContent = () => {
        return (
            <div className="results-card__dropdown-content">
                <ul className="results-card__address">
                    {item.seminarLocation.address_line_1 && (
                        <li>{item.seminarLocation.address_line_1},</li>
                    )}
                    {item.seminarLocation.address_line_2 && (
                        <li>{item.seminarLocation.address_line_2},</li>
                    )}
                    {item.seminarLocation.city && (
                        <li>{item.seminarLocation.city},</li>
                    )}
                    {item.seminarLocation.state &&
                        item.seminarLocation.state !==
                            item.seminarLocation.city && (
                            <li>{item.seminarLocation.state},</li>
                        )}
                    {item.seminarLocation.post_code && (
                        <li>{item.seminarLocation.post_code}</li>
                    )}
                </ul>
                <a
                    href={item.seminarLocation.permalink + '#location-map'}
                    className="results-card__link"
                    target="_blank">
                    View on Map
                </a>
                {item.seminarLocation.note && (
                    <p className="results-card__note">
                        {item.seminarLocation.note}
                    </p>
                )}
            </div>
        );
    };

    return (
        <ul className="results-card__list">
            {item.time && <ListItem icon="schedule" text={item.time} />}
            {item.group && <ListItem icon="groups" text="Group Seminar" />}
            {item.seminarLocation && typeof item.seminarLocation === 'object' && typeof item.location === 'object' && (
                <ListItem
                    icon={item.online ? 'laptop' : 'pin_drop'}
                    text={locationString()}
                    dropDownContent={!item.online ? locationDropDownContent() : ''}
                    dropDownActive={locationDropDownState}
                />
            )}
        </ul>
    );
};
IconList.proptypes = proptypes;
export default IconList;
