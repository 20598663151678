import React from "react";

const Tab = ({ tabTitle, index, active, changeTab }) => {

    return (
        <div
            className={'tabs__tab-panel' + (active ? ' tabs__tab-panel--selected' : '')}
            onClick={() => changeTab(index)}
        >
            <div className="tabs__tab-title">
                { tabTitle }
            </div>
        </div>
    )

}
export default Tab