import { debounce } from "./utilities";
const chooseProgrammeTitles = document.querySelectorAll('[data-ref="choose-programme-carousel-slider"]');

chooseProgrammeTitles.forEach(section => {
    const titlesInSection = section.querySelectorAll('[data-behaviour="choose-programme-height"]');
    let heightToSet = [];

    if (titlesInSection.length) {
        titlesInSection.forEach(el => { heightToSet.push(el.offsetHeight) })
        document.documentElement.style.setProperty('--programme-min-height', getHeightToSet(heightToSet) + 'px');
        
        window.addEventListener('resize', debounce(() => {
            heightToSet = [];
            document.documentElement.style.setProperty('--programme-min-height', '0' + 'px');
            titlesInSection.forEach(el => { heightToSet.push(el.offsetHeight) })
            document.documentElement.style.setProperty('--programme-min-height', getHeightToSet(heightToSet) + 'px');
        }), 300);
    }
});

function getHeightToSet(arr) {
    return Math.max(...arr);
}