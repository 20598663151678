import React, { useState } from 'react';
import Modal from '../Utilities/Modal';
import YoutubeEmbed from '../Utilities/YoutubeEmbed';
import VimeoEmbed from '../Utilities/VimeoEmbed';

const VideoItem = ({ testimonial }) => {
    const [showModal, setShowModal] = useState(false);

    const modalContent = () => 
        testimonial.embed.includes('vimeo') ? (
            <VimeoEmbed url={testimonial.embed} active={showModal} />
        ) : (
            <YoutubeEmbed url={testimonial.embed} active={showModal} />
        );

    function handleModalChange() {
        setShowModal(!showModal);
    }

    function handleModalClose() {
        const videos = document.querySelectorAll('.youtube-iframe');

        videos.forEach(video => {
            video.contentWindow.postMessage(
                '{"event":"command","func":"stopVideo","args":""}',
                '*',
            );
        });

        setShowModal(false);
    }

    return (
        <li className="video__item">
            <div className="video__item-image">
                {testimonial.thumbnail && <img src={testimonial.thumbnail} />}
                <span
                    className="video__item-icon material-icons"
                    onClick={() => handleModalChange()}>
                    play_circle_outline
                </span>
            </div>
            <div className="video__item-content">
                <div className="video__item-post-info">
                    {'name' in testimonial.primary_category && (
                        <div className="video__item-taxonomy-terms">
                            <a href={testimonial.primary_category.url}>{testimonial.primary_category.name}</a>
                        </div>
                    )}

                    <div className="video__item-date-wrap">
                        <span>
                            {testimonial.date}
                        </span>
                    </div>
                </div>
                {testimonial.quote && (
                    <h5 className="video__item-quote">{testimonial.quote}</h5>
                )}
                {testimonial.caption && (
                    <h5 className="video__item-caption">
                        {testimonial.caption.length > 150 ? testimonial.caption.slice(0, 150)+'...' : testimonial.caption}
                    </h5>
                )}                
            </div>
            {testimonial.embed !== null && showModal && <Modal
                handleShow={showModal}
                content={modalContent()}
                handleModalClose={handleModalClose}></Modal>}
        </li>
    );
};

export default VideoItem;
