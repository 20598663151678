/**
 * Format Field ID
 * @param {string} fieldId
 * @returns
 */
export const formatFieldId = fieldId => {
    return String(fieldId).replace('.', '_');
};
/**
 * Get radio default value
 * @param {object} choices
 * @returns mixed
 */
export const getRadioDefaultValue = choices => {
    let value;
    Object.keys(choices).forEach(choiceKey => {
        const choice = choices[choiceKey];
        if (choice.isSelected) value = choice?.value;
    });
    return value ?? null;
};
/**
 * Get checkbox default value
 * @param {object} choices
 * @returns mixed
 */
export const getCheckboxDefaultValue = choices => {
    let value;
    Object.keys(choices).forEach(choiceKey => {
        const choice = choices[choiceKey];
        if (choice.isSelected) {
            const inputName = `input_${formatFieldId(choice.id)}`;
            value = {
                value: choice.value,
                name: inputName,
            };
        }
    });
    return !value ? null : value;
};
/**
 * Get select default value
 * @param {object} choices
 * @returns mixed
 */
export const getSelectDefaultValue = choices => {
    let value;
    Object.keys(choices).forEach(choiceKey => {
        const choice = choices[choiceKey];
        if (choice.isSelected) value = choice?.value;
    });
    return value ?? null;
};
