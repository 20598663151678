import { setTimezone } from './utilities';

[...document.querySelectorAll('[data-opening-time]')].map(el => {
    let { openingTime, closingTime, timezone } = el.dataset;
    if (!timezone) timezone = 'UTC';

    const days = JSON.parse(el.dataset.days.replace(',]', ']'));
    const week = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
    ];

    let currentDate = new Date(
        new Date().toLocaleString('en', { timeZone: timezone }),
    );

    let startDate = new Date(currentDate.getTime());
    let endDate = new Date(currentDate.getTime());

    const day = week[currentDate.getDay() - 1];

    startDate.setHours(openingTime.split(':')[0]);
    startDate.setMinutes(openingTime.split(':')[1]);

    endDate.setHours(closingTime.split(':')[0]);
    endDate.setMinutes(closingTime.split(':')[1]);

    startDate = new Date(setTimezone(timezone, startDate));
    endDate = new Date(setTimezone(timezone, endDate));

    const isOpenTime = startDate < currentDate && endDate > currentDate;
    const isOpenDay = days.indexOf(day) > -1;

    el.classList.add(
        el.dataset.class + '--' + (isOpenTime && isOpenDay ? 'open' : 'closed'),
    );
});
