import React from "react";
import PropTypes from "prop-types";

const proptypes = {
    price: PropTypes.string.isRequired,
    originalPrice: PropTypes.string,
    itemID: PropTypes.number.isRequired,
    itemVariationID: PropTypes.number,
    quantity: PropTypes.number,
    onClick: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.bool
    ]).isRequired,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
    fullWidth: PropTypes.bool,
    couponCode: PropTypes.string,
    priceOverride: PropTypes.string,
    overrideText: PropTypes.string,
    overrideOriginalText: PropTypes.string,
    upsellCouponCode: PropTypes.string
}

const Price = ({ price, originalPrice = '', itemID, itemVariationID = 0, quantity = 1, onClick = false, buttonText = 'Book', buttonUrl = '', fullWidth = false, couponCode = '', priceOverride = '', overrideText = '', overrideOriginalText = '', upsellCouponCode = '' }) => {

    const productId = itemVariationID > 0 ? itemVariationID : itemID;

    const displayPrice = () => {
        let displayOriginalPrice = originalPrice;
        let displayPrice = price;

        if (priceOverride !== '') {
            displayOriginalPrice = overrideOriginalText;
            displayPrice = overrideText;
        } else if (parseFloat(price.replace(/[^\d\.]/g, '')) === 0) {
            displayPrice = 'Free';
            displayOriginalPrice = '';
        } 

        return displayOriginalPrice !== '' && displayOriginalPrice !== displayPrice
            ? <>
                <span className="results-card__price-original">{displayOriginalPrice}</span>
                <span className="results-card__price-sale">{displayPrice}</span>
            </>
            : displayPrice
    };

    return (
        <>
            {!onClick && buttonUrl !== '' && originalPrice !== '' && originalPrice !== price && (couponCode !== '' || upsellCouponCode !== '') &&
                <p>Please quote code {couponCode !== '' ? couponCode : upsellCouponCode} for the discount.</p>
            }
            <div className={'results-card__price' + (fullWidth ? ' results-card__price--full' : '')}>
                <div className="results-card__price-count" data-target='purchase-price'>
                    {displayPrice()}
                </div>
                {!onClick && buttonUrl !== '' &&
                    <a
                        className="results-card__price-button"
                        href={buttonUrl}
                        target='_blank'
                        data-product={itemID}
                        data-variation={itemVariationID}>
                        <span className="material-icons">open_in_new</span> {buttonText}
                    </a>
                }
                {onClick &&
                    <a
                        className="results-card__price-button"
                        href={`${wp_ajax.home_url}basket/?add-to-cart=${productId}&quantity=${quantity}&coupon_code=${couponCode}`}
                        data-target='purchase-button'
                        data-product={itemID}
                        data-variation={itemVariationID}
                        onClick={(button) => { button.preventDefault(); onClick(itemID, button.target.dataset.variation, couponCode); }}>
                        {buttonText}
                    </a>
                }
            </div>
        </>
    )
}

Price.proptypes = proptypes;
export default Price;