
import { getGeoCountry, getGeoPrices, fetchGeoImage } from "./utilities";

document.body.addEventListener('click', (event) => {
    if (event.target && event.target.parentElement &&
        event.target.parentElement.classList.contains('wg-li') &&
        !event.target.parentElement.classList.contains('wgcurrent')
    ) {
        // Need to make sure that we set the language in a cookie before 
        // WEGOT redirects to language page
        const date = new Date();
        date.setTime(date.getTime() + 2592000000);

        document.cookie = 'language-choice=' + event.target.parentElement.dataset.codeLanguage + '; path=/; Secure; expires=' + date.toUTCString();
    }
});

window.addEventListener('load', async () => {
    const geoCountry = await getGeoCountry();

    const geoPrices = document.querySelectorAll('[data-behaviour*="geo-price"]');
    if (geoPrices.length > 0) {
        let prices = Array.from(geoPrices, geoPrice => geoPrice.innerHTML.replace(/,/g, ''))
        prices = await getGeoPrices(prices);
        if (prices.length > 0) {
            geoPrices.forEach((geoPrice, index) => {
                if (index in prices) {
                    geoPrice.innerHTML = prices[index];
                }
            });
        }
    }

    const ajaxImages = document.querySelectorAll('[data-ref="ajax-image"]');
    if (ajaxImages.length > 0) {
        let observer = new IntersectionObserver((entries, observer) => {
            let matched = false;
            entries.forEach(async entry => {
                const countries = entry.target.dataset.countries.replace(/\s/g, '').split(',');
                if (entry.target.dataset.id && (
                        countries.includes(geoCountry.country.toUpperCase()) || 
                        (countries.includes('GB') && !matched)
                    )
                ) {
                    matched = true;
                    let imageHtml = await fetchGeoImage(entry.target.dataset.id, entry.target.dataset.size ?? '', entry.target.dataset.classes ?? '', entry.target.dataset.partial ?? true);
                    let parent = entry.target.closest('[data-ref="ajax-image-parent"]');
                    parent.innerHTML = imageHtml;
                    observer.unobserve(entry.target);
                }
            });
        }, { rootMargin: "0px 0px -200px 0px" });

        ajaxImages.forEach(img => { observer.observe(img) });
    }
});
