import React, { useState } from "react";
import Checkbox from "../FormElements/Checkbox";
import Radio from "../FormElements/Radio";

//@todo change to just Props
const Accordion = ({ title, filters, type = 'checkbox', sortQuery, onSortChange, onCheckboxChange, defaultOpen = true, canCollapse = true }) => {
    
    const [open, setOpen] = useState(defaultOpen); // for the accordion action

    const showAccordion = () => setOpen(!open);

    function handleSortChange(event) {
        let value = event.target.value;
        onSortChange(value);
    }

    function handleCheckboxChange(event) {
        let value = event.target.value;
        onCheckboxChange(value);
    }

    function isCheckboxChecked(value) {
        let isChecked = false;
        const searchParams = new URLSearchParams(window.location.search);
        if (title === 'Addiction Type' && searchParams.get('addiction')) {
            const addictionSearchParams = searchParams.get('addiction').split(',');
            isChecked = addictionSearchParams.includes(value.toLowerCase());
        } else if (title === 'Customer Type' && searchParams.get('customer')) {
            const customerSearchParams = searchParams.get('customer').split(',');
            isChecked = customerSearchParams.includes(value.toLowerCase());
        }
        return isChecked;
    }

    const accordionContent = () => {
        if (type === 'checkbox') {
            return (
                Object.keys(filters).map((id, index) => (
                    <Checkbox
                        title={filters[id]}
                        id={id}
                        key={index}
                        onChange={handleCheckboxChange}
                        initialChecked={isCheckboxChecked(filters[id], index)}
                    />
                ))
            )
        } else if (type === 'radio') {
            return (
                Object.keys(filters).map((id, index) => (
                    <Radio
                        title={filters[id]}
                        id={id}
                        key={index}
                        name={'radio_group'}
                        onRadioChange={handleSortChange}
                        checked={id === sortQuery.sort ? true : false}
                    />
                ))
            )
        }
    };

    return (
        <div className="accordion">
            <div className="accordion__heading" onClick={showAccordion}>
                <div className="accordion__title">{title}</div>
                {canCollapse && <span className={"material-icons" + (open ? ' u-rotate-180' : '')}>expand_more</span>}
            </div>
            <div className={'accordion-content' + (open ? ' accordion-content--open' : '')}>
                <div className="standard-form review-filter-form">
                    {accordionContent()}
                </div>
                <div className="u-spacer"></div>
            </div>
        </div>
    );
};

export default Accordion;