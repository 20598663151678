import { registerAddToCartTargets } from './utilities';

// product
document.addEventListener('click', e => {
    if (
        !e.target.matches('[data-behaviour]') &&
        !e.target.matches('[data-behaviour="product-toggle-more"]')
    )
        return;

    const product = e.target.closest('.checkout-product');
    if (!product) return;

    const isOpen = e.target.matches('.checkout-product__toggle--open');

    [...product.querySelectorAll('[data-checkout-product-toggle]')].map(el =>
        el.classList[isOpen ? 'add' : 'remove']('hidden'),
    );
    e.target.classList[isOpen ? 'remove' : 'add'](
        'checkout-product__toggle--open',
    );
});

document.addEventListener(
    'click',
    e =>
        e.target.matches('[data-behaviour]') &&
        e.target.matches('[data-behaviour="toggle-content"]') &&
        document
            .querySelector(e.target.dataset.toggleContent)
            .classList.toggle('hidden'),
);

registerAddToCartTargets();

const checkoutFormErrors = document.querySelector('.woocommerce-notices-wrapper');
if (checkoutFormErrors !== null && checkoutFormErrors.childNodes.length > 0) {
    checkoutFormErrors.scrollIntoView({ behavior: 'smooth' });
}

const applyCoupons = document.querySelectorAll('[data-behaviour*="apply-coupon"]');
if (applyCoupons.length > 0) {
    applyCoupons.forEach((applyCoupon) => {
        applyCoupon.addEventListener('submit', event => {
            const couponField = applyCoupon.querySelector('[data-target="coupon-field"]');
            if (couponField !== null) {
                event.preventDefault();

                const couponLoader = applyCoupon.querySelector('[data-target="apply-coupon-loader"]');
                if (couponLoader !== null) {
                    couponLoader.classList.remove('u-hidden');
                }

                let data = new FormData();
                data.append('security', wp_ajax.security);
                data.append('coupon_code', couponField.value);

                fetch(
                    `${wp_ajax.ajax_url}?action=apply_coupon`,
                    {
                        method: 'POST',
                        body: data,
                    }
                )
                .then(response => response.json())
                .then(response => {
                    if (couponLoader !== null) {
                        couponLoader.classList.add('u-hidden');
                    }

                    if (typeof jQuery != 'undefined' && jQuery !== null) {
                        jQuery(document.body).trigger('update_checkout');
                    }
                })
                .catch(error => {
                    console.error('Apply coupon: ', error);

                    if (couponLoader !== null) {
                        couponLoader.classList.add('u-hidden');
                    }
                });
            }
        });
    });
}
