import React, { useEffect, useState } from "react";
//Alignmnet center, right
const Heading = ({ title, subTitle, alignment }) => {
    const [classes, setClasses] = useState('');

    useEffect(() => {
        let tempClasses = [];
        if (alignment.horizontal === 'center' || alignment.horizontal === 'right') {
            tempClasses.push('u-text-' + alignment.horizontal);
        }
        if (alignment.vertical === 'column-reverse' || alignment.vertical === 'column') {
            tempClasses.push('u-flex u-flex--' + alignment.vertical);
        }
        setClasses(tempClasses.join(' '));
    }, []);


    return (
        <header className={classes}>
            {title && <h1 className="heading-title">{title}</h1>}
            {subTitle && <h5 className="heading-subtitle">{subTitle}</h5>}
        </header>
    )

}
export default Heading