/* eslint-disable max-len */
import easydropdown from 'easydropdown';

const styledSelects = document.querySelectorAll('[data-select="styled"], .ginput_address_country select');
if (styledSelects.length >= 1) {
    styledSelects.forEach(select => {
        easydropdown(select);
    });
}

const reorderSelects = document.querySelectorAll('[data-reorder="true"]');
if (reorderSelects.length >= 1) {
    reorderSelects.forEach((value) => {
        value.addEventListener('change', function () {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('order', this.value);
            window.location.search = urlParams;
        });
    });
}

const buttons = document.querySelectorAll('[data-behaviour="dropdown-trigger"]');
if (buttons.length > 0) {
    buttons.forEach((button) => {
        button.addEventListener("click", function (e) {
            e.preventDefault();
            button.focus();
        });
    });
}
