export const defaultObject = {
    data: {
        help: {
            voucher_content: '',
            session_content: '',
            session_type_content: '',
            session_count_content: '',
        },
    },
    geo: {
        country: 'GB',
        currency: 'GBP',
        timezone: 'GMT',
    },
    supportedTimeZones: [
        'UTC',
        'GMT',
        'BST',
        'CET',
        'CEST',
        'PST',
        'PDT',
        'MST',
        'MDT',
        'CST',
        'CDT',
        'EST',
        'EDT',
        'IST',
        'AWST',
        'AWDT',
        'ACST',
        'ACDT',
        'AEST',
        'AEDT',
        'NZST',
        'NZDT'
    ],
    standardTimeZones: {
        'UTC': 'UTC',
        'BST':'GMT',
        'CEST': 'CET',
        'PDT': 'PST',
        'MDT': 'MST',
        'CDT': 'CST',
        'EDT': 'EST',
        'IST': 'IST',
        'AWDT': 'AWST',
        'ACDT': 'ACST',
        'AEDT': 'AEST',
        'NZDT': 'NZST',
    },
    addiction: {
        current: {
            name: null,
            display_name: null,
            id: null,
            form_id: null,
        },
    },
    voucher: {
        current: {
            name: 'No',
            id: 2,
            key: 'no',
        },
    },
    session: {
        current: {
            name: null,
            id: null,
            key: null,
        },
    },
    sessionType: {
        current: {
            name: null,
            id: null,
            key: null,
        },
    },
    country: 'GB',
    activeTabIndex: 1,
    upsell: false,
    upsellDiscount: 10,
    early_bird_discount: 'Early Bird Pricing Available:',
    countryData: {
        // Taken from get_country_locale() in wp-content/plugins/woocommerce/includes/class-wc-countries.php
        AE: {
            postcode: {
                required: false,
                hidden: true,
            },
            state: {
                required: false,
            },
        },
        AF: {
            state: {
                required: false,
            },
        },
        AO: {
            postcode: {
                required: false,
                hidden: true,
            },
            state: {
                label: 'Province',
            },
        },
        AT: {
            postcode: {
                priority: 65,
            },
            state: {
                required: false,
            },
        },
        AU: {
            city: {
                label: 'Suburb',
            },
            postcode: {
                label: 'Postcode',
            },
            state: {
                label: 'State',
            },
        },
        AX: {
            postcode: {
                priority: 65,
            },
            state: {
                required: false,
            },
        },
        BA: {
            postcode: {
                priority: 65,
            },
            state: {
                label: 'Canton',
                required: false,
                hidden: true,
            },
        },
        BD: {
            postcode: {
                required: false,
            },
            state: {
                label: 'District',
            },
        },
        BE: {
            postcode: {
                priority: 65,
            },
            state: {
                required: false,
                label: 'Province',
            },
        },
        BH: {
            postcode: {
                required: false,
            },
            state: {
                required: false,
            },
        },
        BI: {
            state: {
                required: false,
            },
        },
        BO: {
            postcode: {
                required: false,
                hidden: true,
            },
        },
        BS: {
            postcode: {
                required: false,
                hidden: true,
            },
        },
        CA: {
            postcode: {
                label: 'Postal code',
            },
            state: {
                label: 'Province',
            },
        },
        CH: {
            postcode: {
                priority: 65,
            },
            state: {
                label: 'Canton',
                required: false,
            },
        },
        CL: {
            city: {
                required: true,
            },
            postcode: {
                required: false,
            },
            state: {
                label: 'Region',
            },
        },
        CN: {
            state: {
                label: 'Province',
            },
        },
        CO: {
            postcode: {
                required: false,
            },
        },
        CW: {
            postcode: {
                required: false,
                hidden: true,
            },
            state: {
                required: false,
            },
        },
        CZ: {
            state: {
                required: false,
            },
        },
        DE: {
            postcode: {
                priority: 65,
            },
            state: {
                required: false,
                hidden: true,
            },
        },
        DK: {
            postcode: {
                priority: 65,
            },
            state: {
                required: false,
                hidden: true,
            },
        },
        EE: {
            postcode: {
                priority: 65,
            },
            state: {
                required: false,
            },
        },
        FI: {
            postcode: {
                priority: 65,
            },
            state: {
                required: false,
            },
        },
        FR: {
            postcode: {
                priority: 65,
            },
            state: {
                required: false,
            },
        },
        GH: {
            postcode: {
                required: false,
            },
            state: {
                label: 'Region',
            },
        },
        GP: {
            state: {
                required: false,
            },
        },
        GF: {
            state: {
                required: false,
            },
        },
        GR: {
            state: {
                required: false,
            },
        },
        GT: {
            postcode: {
                required: false,
                hidden: true,
            },
            state: {
                label: 'Department',
            },
        },
        HK: {
            postcode: {
                required: false,
            },
            city: {
                label: 'Town / District',
            },
            state: {
                label: 'Region',
            },
        },
        HU: {
            last_name: {
                class: ['form-row-first'],
                priority: 10,
            },
            first_name: {
                class: ['form-row-last'],
                priority: 20,
            },
            postcode: {
                class: ['form-row-first', 'address-field'],
                priority: 65,
            },
            city: {
                class: ['form-row-last', 'address-field'],
            },
            address_1: {
                priority: 71,
            },
            address_2: {
                priority: 72,
            },
            state: {
                label: 'County',
            },
        },
        ID: {
            state: {
                label: 'Province',
            },
        },
        IE: {
            postcode: {
                required: false,
                label: 'Eircode',
            },
            state: {
                label: 'County',
            },
        },
        IS: {
            postcode: {
                priority: 65,
            },
            state: {
                required: false,
            },
        },
        IL: {
            postcode: {
                priority: 65,
            },
            state: {
                required: false,
            },
        },
        IM: {
            state: {
                required: false,
            },
        },
        IN: {
            postcode: {
                label: 'Pin code',
            },
            state: {
                label: 'State',
            },
        },
        IT: {
            postcode: {
                priority: 65,
            },
            state: {
                required: true,
                label: 'Province',
            },
        },
        JM: {
            city: {
                label: 'Town / City / Post Office',
            },
            postcode: {
                required: false,
                label: 'Postal Code',
            },
            state: {
                required: true,
                label: 'Parish',
            },
        },
        JP: {
            last_name: {
                class: ['form-row-first'],
                priority: 10,
            },
            first_name: {
                class: ['form-row-last'],
                priority: 20,
            },
            postcode: {
                class: ['form-row-first', 'address-field'],
                priority: 65,
            },
            state: {
                label: 'Prefecture',
                class: ['form-row-last', 'address-field'],
                priority: 66,
            },
            city: {
                priority: 67,
            },
            address_1: {
                priority: 68,
            },
            address_2: {
                priority: 69,
            },
        },
        KR: {
            state: {
                required: false,
            },
        },
        KW: {
            state: {
                required: false,
            },
        },
        LV: {
            state: {
                label: 'Municipality',
                required: false,
            },
        },
        LB: {
            state: {
                required: false,
            },
        },
        MQ: {
            state: {
                required: false,
            },
        },
        MT: {
            state: {
                required: false,
            },
        },
        MZ: {
            postcode: {
                required: false,
                hidden: true,
            },
            state: {
                label: 'Province',
            },
        },
        NL: {
            postcode: {
                priority: 65,
            },
            state: {
                required: false,
                label: 'Province',
            },
        },
        NG: {
            postcode: {
                label: 'Postcode',
                required: false,
                hidden: true,
            },
            state: {
                label: 'State',
            },
        },
        NZ: {
            postcode: {
                label: 'Postcode',
            },
            state: {
                required: false,
                label: 'Region',
            },
        },
        NO: {
            postcode: {
                priority: 65,
            },
            state: {
                required: false,
            },
        },
        NP: {
            state: {
                label: 'State / Zone',
            },
            postcode: {
                required: false,
            },
        },
        PL: {
            postcode: {
                priority: 65,
            },
            state: {
                required: false,
            },
        },
        PR: {
            city: {
                label: 'Municipality',
            },
            state: {
                required: false,
                hidden: true,
            },
        },
        PT: {
            state: {
                required: false,
            },
        },
        RE: {
            state: {
                required: false,
            },
        },
        RO: {
            state: {
                label: 'County',
                required: true,
            },
        },
        RS: {
            city: {
                required: true,
            },
            postcode: {
                required: true,
            },
            state: {
                label: 'District',
                required: false,
            },
        },
        SG: {
            state: {
                required: false,
            },
            city: {
                required: false,
            },
        },
        SK: {
            postcode: {
                priority: 65,
            },
            state: {
                required: false,
            },
        },
        SI: {
            postcode: {
                priority: 65,
            },
            state: {
                required: false,
            },
        },
        SR: {
            postcode: {
                required: false,
                hidden: true,
            },
        },
        ES: {
            postcode: {
                priority: 65,
            },
            state: {
                label: 'Province',
            },
        },
        LI: {
            postcode: {
                priority: 65,
            },
            state: {
                label: 'Municipality',
                required: false,
            },
        },
        LK: {
            state: {
                required: false,
            },
        },
        LU: {
            state: {
                required: false,
            },
        },
        MD: {
            state: {
                label: 'Municipality / District',
            },
        },
        SE: {
            postcode: {
                priority: 65,
            },
            state: {
                required: false,
                hidden: true,
            },
        },
        TR: {
            postcode: {
                priority: 65,
            },
            state: {
                label: 'Province',
            },
        },
        UG: {
            postcode: {
                required: false,
                hidden: true,
            },
            city: {
                label: 'Town / Village',
                required: true,
            },
            state: {
                label: 'District',
                required: true,
            },
        },
        US: {
            postcode: {
                label: 'ZIP',
            },
            state: {
                label: 'State',
            },
        },
        GB: {
            postcode: {
                label: 'Postcode',
            },
            state: {
                label: 'County',
                required: false,
            },
        },
        ST: {
            postcode: {
                required: false,
                hidden: true,
            },
            state: {
                label: 'District',
            },
        },
        VN: {
            state: {
                required: false,
                hidden: true,
            },
            postcode: {
                priority: 65,
                required: false,
                hidden: false,
            },
            address_2: {
                required: false,
                hidden: true,
            },
        },
        WS: {
            postcode: {
                required: false,
                hidden: true,
            },
        },
        YT: {
            state: {
                required: false,
            },
        },
        ZA: {
            state: {
                label: 'Province',
            },
        },
        ZW: {
            postcode: {
                required: false,
                hidden: true,
            },
        },
    },
};
