const contentToggles = document.querySelectorAll('[data-behaviour*="content-toggle"]');
if (contentToggles.length > 0) {
    contentToggles.forEach(contentToggle => {
        contentToggle.addEventListener('click', () => {
            const oldAltText = contentToggle.innerHTML;
            contentToggle.innerHTML = contentToggle.dataset.altText;
            contentToggle.dataset.altText = oldAltText;

            const contentToggleTargets = document.querySelectorAll(`[data-content-toggle-target="${contentToggle.dataset.target}"]`);
            if (contentToggleTargets.length > 0) {
                contentToggleTargets.forEach(contentToggleTarget => contentToggleTarget.classList.toggle('u-hidden'));
            }
        });
    });
}
