import React from "react";
import Review from './Review';
import VideoReview from "./VideoReview";
import TotalCount from "../Pagination/TotalCount";

const Reviews = ({ data, count, videoReview = false }) => {

    return (
        <div className="client-reviews-list__wrapper">
            <TotalCount count={count} />
            {data.length > 0 && (
                <ul className={'client-reviews__list' + (videoReview ? ' client-reviews__list--videos' : '')}>
                    {
                        data.map((review, index) => (
                            review.testimonial_type == 'video' ? (
                                <VideoReview review={review} key={index} />
                            ) : (
                                <Review review={review} key={index} />
                            )
                        ))
                    }
                </ul>
            )}
            {data.length <= 0 && <div className="client-reviews__no-results">
                <h3>No results found</h3>
            </div>
            }
        </div>
    )
}

export default Reviews