import React, {
    useRef,
    useEffect,
    useState,
    Children,
    isValidElement,
    cloneElement,
} from 'react';
import { createCustomEqual } from 'fast-equals';

const deepCompareEqualsForMaps = createCustomEqual(deepEqual => (a, b) => {
    if (a instanceof google.maps.LatLng || b instanceof google.maps.LatLng) {
        return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    return deepEqual(a, b);
});

const useDeepCompareMemoize = value => {
    const ref = useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
};

const useDeepCompareEffectForMaps = (callback, dependencies) => {
    useEffect(callback, dependencies.map(useDeepCompareMemoize));
};

const Map = ({ onClick, onIdle, children, style, ...options }) => {
    const ref = useRef(null);
    const [map, setMap] = useState();
    const styles = [
        {
            featureType: 'poi.business',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
        },
    ];

    useDeepCompareEffectForMaps(() => {
        if (map) {
            options.styles = styles;
            map.setOptions(options);
        }
    }, [map, options]);

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map]);

    useEffect(() => {
        if (map && children.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            children.map(child => {
                bounds.extend({
                    lat: child.props.position.lat,
                    lng: child.props.position.lng,
                });
            });
            map.fitBounds(bounds);
        }
    }, [map, children]);

    useEffect(() => {
        if (map) {
            ['click', 'idle'].forEach(eventName =>
                google.maps.event.clearListeners(map, eventName),
            );

            if (onClick) {
                map.addListener('click', onClick);
            }

            if (onIdle) {
                map.addListener('idle', () => onIdle(map));
            }
        }
    }, [map, onClick, onIdle]);

    return (
        <>
            <div ref={ref} style={style} />
            {Children.map(children, child => {
                if (isValidElement(child)) {
                    // set the map prop on the child component
                    return cloneElement(child, { map });
                }

                return <></>;
            })}
        </>
    );
};

export default Map;
