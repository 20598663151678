import React, { useState } from "react";
import PropTypes from "prop-types";

const proptypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            featured: PropTypes.bool
        })
    )
};

const Summary = ({ items, title = "What's included?", hideTitle = false }) => {
    const [showSummary, setShowSummary] = useState(hideTitle ? true : false);

    const summaryList = items.map((object, index) => 
        <li
            key={'summary-list-' + index}
            className={'results-card__summary-item' + (object.featured ? ' u-text-underline' : '')}>
            <p className="results-card__summary-item-title">{object.text}</p>
        </li>
    )

    return items.length > 0 && (
        <div className="results-card__summary">
            {!hideTitle &&
                <h4
                    className={'results-card__summary-title' + (showSummary ? ' u-hidden' : '')}
                    onClick={() => { setShowSummary(true) }}
                >{title}</h4>
            }
            {showSummary &&
                <ul className="results-card__summary-list">
                    {summaryList}
                </ul>        
            }
        </div>
    );
}

Summary.propTypes = proptypes;

export default Summary