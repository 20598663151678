import React from "react";

const ResultsCardListItem = ({ icon, iconClass = 'material-icons', text, dropDownContent = '', dropDownActive = false }) => {
    return (
        <li className="results-card__list-item">
            <div className="results-card__header">
                <span className={iconClass + ' results-card__list-icon'}>{icon}</span>
                <h4 className="results-card__list-text">{text}</h4>
            </div>
            {dropDownContent &&
                <div className={'results-card__dropdown' + (dropDownActive ? ' results-card__dropdown--active' : '')}>
                    {dropDownContent}
                </div>
            }
        </li>
    )
}

export default ResultsCardListItem;