/* eslint-disable max-len */
const fields = document.querySelectorAll('[data-behaviour="image-upload"]');
if (fields.length >= 1) {
    fields.forEach((field) => {
        const uploads = field.querySelectorAll('[type="file"]');
        uploads.forEach((upload) => {
            upload.addEventListener('change', () => {
                const container = field;
                if (upload.value) {
                    container.classList.add('image-upload--input-value');
                } else {
                    container.classList.remove('image-upload--input-value');
                }
                const displays = field.querySelectorAll('[data-behaviour="input-value"]');

                displays.forEach((display) => {
                    // eslint-disable-next-line no-param-reassign, no-useless-escape
                    display.innerHTML = upload.value.replace(/^.*[\\\/]/, '');
                });
            });
        });
    });
}
