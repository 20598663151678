import Query from './Query';

export default class Search {
    // @todo move the formating of data into decotorator class for each type
    /**
     *
     * @param {Object} options - The options to be passed to the query
     * @param {string} options.type - The type/index of search to be performed
     * @param {array} options.addiction - The addiction(s) (id) to be searched
     * @param {boolean} options.online - Whether or not to search online seminars
     * @param {boolean} options.group - Whether or not to search group seminars
     * @param {number} options.perPage - The number of results to return per page
     * @param {number} options.page - The page number to return
     * @param {string} options.sort - The sortby method to return the results in, either 'soonest' or 'distance'
     * @param {number} options.distance - The distance to search from the geolocation provided via options.location
     * @param {object} options.location - The geolocation lat & lng hash to search with
     * @param {string} options.startDate - The start date to search for
     * @param {string} options.endDate - The end date to search for
     * @param {string} options.country - The country (slug) to be searched
     * @param {string} options.currency - The currency code to look up correct GEO price
     * @param {string} options.timeZone - The short time zone name
     * @param {array} options.centreLocations - The centre locations (id) to be searched
     * @param {boolean} options.voucher - Whether or not to search voucher products
     * @param {number} options.multiSiteId - The multisite id to prefix document ids with
     * @param {string} headOfficeId - The id of the head office online location to search
     * @param {boolean} showHeadOffice - The whether search for the head office location
     */
    constructor({
        type = '',
        addiction = [],
        online = null,
        group = false,
        perPage = 10,
        page = 1,
        sort = 'distance',
        distance = 200,
        location = null,
        startDate = null,
        endDate = null,
        country = '',
        currency = 'GBP',
        timeZone = 'GMT',
        centreLocations = [],
        voucher = null,
        multiSiteId = 1,
        headOfficeId = '',
        showHeadOffice = false,
    }) {
        this.type = type;
        this.isProduct = ['seminar', 'ovp', 'onetoone', 'taster'].includes(
            type,
        );
        this.addiction = addiction;
        this.online = online;
        this.group = group;
        this.perPage = perPage;
        this.page = page;
        this.sort = sort;
        this.distance = distance;
        this.location = location;
        this.startDate = startDate;
        this.endDate = endDate;
        this.country = country;
        this.currency = currency;
        this.timeZone = timeZone;
        this.centreLocations = centreLocations;
        this.voucher = voucher;
        this.multiSiteId = multiSiteId;
        this.headOfficeId = headOfficeId;
        this.showHeadOffice = showHeadOffice;
        this.query = null;
        this.results = {
            total: 0,
            results: [],
            pagination: {
                current: page,
                total: 0,
                next: false,
                prev: false,
            },
        };
        this.searchEndpoint = `${process.env.ES_ENDPOINT}/_search/`;

        if (type !== '') {
            this.query =
                JSON.stringify(
                    new Query(
                        this.type,
                        this.addiction,
                        this.online,
                        this.perPage,
                        this.page,
                        this.sort,
                        this.distance,
                        this.location,
                        this.startDate,
                        this.endDate,
                        this.country,
                        this.centreLocations,
                        this.voucher,
                        this.multiSiteId,
                        this.headOfficeId,
                        this.showHeadOffice,
                    ),
                ) + '\n';
        }
    }

    //@todo improve structure for formating suggest split each type into seperate decorator classes
    async formatResults(results) {
        if ('hits' in results) {
            const currentDate = new Date();
            this.results.total = results.hits.total.value;
            for (let i = 0; i < results.hits.hits.length; i++) {
                results.hits.hits[i]['_source'].group = this.group;

                // Need to format the distance and add measure for display
                if (
                    results.hits.hits[i]['_source'].location !== null &&
                    typeof results.hits.hits[i]['_source'].location === 'object'
                ) {
                    results.hits.hits[i]['_source'].location.distance =
                        'fields' in results.hits.hits[i]
                            ? Math.floor(
                                  results.hits.hits[i].fields.distance[0],
                              )
                            : '';
                    results.hits.hits[i]['_source'].location.measurement = 'mi';
                }

                if (this.isProduct) {
                    // We need to fetch the correct price based on the users currency
                    if (
                        results.hits.hits[i]['_source'].prices !== null &&
                        typeof results.hits.hits[i]['_source'].prices ===
                            'object' &&
                        this.currency in results.hits.hits[i]['_source'].prices
                    ) {
                        results.hits.hits[i]['_source'].price =
                            results.hits.hits[i]['_source'].prices[
                                this.currency
                            ];
                    }

                    if (
                        results.hits.hits[i]['_source'].regularPrices !==
                            null &&
                        typeof results.hits.hits[i]['_source'].regularPrices ===
                            'object' &&
                        this.currency in
                            results.hits.hits[i]['_source'].regularPrices
                    ) {
                        results.hits.hits[i]['_source'].regularPrice =
                            results.hits.hits[i]['_source'].regularPrices[
                                this.currency
                            ];
                    }

                    if (
                        results.hits.hits[i]['_source'].salePrices !== null &&
                        typeof results.hits.hits[i]['_source'].salePrices ===
                            'object' &&
                        this.currency in
                            results.hits.hits[i]['_source'].salePrices
                    ) {
                        results.hits.hits[i]['_source'].salePrice =
                            results.hits.hits[i]['_source'].salePrices[
                                this.currency
                            ];
                    }

                    // Make the dates are stored as a date object and need to stripe any stored timezone to prevent date being adjusted
                    if (
                        results.hits.hits[i]['_source'].saleStartDate !==
                            null &&
                        results.hits.hits[i]['_source'].saleStartDate !== ''
                    ) {
                        results.hits.hits[i][
                            '_source'
                        ].saleStartDate = new Date(
                            results.hits.hits[i][
                                '_source'
                            ].saleStartDate.replace(/[-+]\d+\:\d+$/, ''),
                        );
                    }

                    if (
                        results.hits.hits[i]['_source'].saleEndDate !== null &&
                        results.hits.hits[i]['_source'].saleEndDate !== ''
                    ) {
                        results.hits.hits[i]['_source'].saleEndDate = new Date(
                            results.hits.hits[i]['_source'].saleEndDate.replace(
                                /[-+]\d+\:\d+$/,
                                '',
                            ),
                        );
                    }

                    if (this.type === 'seminar' || this.type === 'taster') {
                        if (
                            results.hits.hits[i]['_source'].start_date !== '' &&
                            results.hits.hits[i]['_source'].start_date !== null
                        ) {
                            // Make the dates are stored as a date object and need to stripe any stored timezone to prevent date being adjusted
                            results.hits.hits[i][
                                '_source'
                            ].start_date = new Date(
                                results.hits.hits[i][
                                    '_source'
                                ].start_date.replace(/[-+]\d+\:\d+$/, ''),
                            );
                        }

                        if (
                            results.hits.hits[i]['_source'].start_date !==
                                null &&
                            results.hits.hits[i]['_source'].start_date !== ''
                        ) {
                            // Make the dates are stored as a date object and need to stripe any stored timezone to prevent date being adjusted
                            results.hits.hits[i]['_source'].end_date = new Date(
                                results.hits.hits[i][
                                    '_source'
                                ].end_date.replace(/[-+]\d+\:\d+$/, ''),
                            );
                        }

                        // If fallback to UTC then use the seminar timezone instead
                        if (this.timeZone === 'UTC') {
                            this.timeZone =
                                results.hits.hits[i]['_source']
                                    .date_time_zone !== null &&
                                results.hits.hits[i]['_source']
                                    .date_time_zone !== ''
                                    ? results.hits.hits[i]['_source']
                                          .date_time_zone
                                    : 'GMT';

                            // If still UTC display UK time instead
                            if (this.timeZone === 'UTC') {
                                this.timeZone = 'GMT';
                            }
                        }

                        // We need to fetch the correct time based on the users timezone
                        if (
                            results.hits.hits[i]['_source'].times !== null &&
                            results.hits.hits[i]['_source'].times !== '' &&
                            this.timeZone in
                                results.hits.hits[i]['_source'].times
                        ) {
                            results.hits.hits[i]['_source'].time =
                                results.hits.hits[i]['_source'].times[
                                    this.timeZone
                                ];
                        }
                    }

                    if (
                        this.type === 'ovp' &&
                        results.hits.hits[i]['_source'].children !== null &&
                        Array.isArray(
                            results.hits.hits[i]['_source'].children,
                        ) &&
                        results.hits.hits[i]['_source'].children.length > 0
                    ) {
                        for (
                            let j = 0;
                            j < results.hits.hits[i]['_source'].children.length;
                            j++
                        ) {
                            // We need to fetch the correct price based on the users currency
                            if (
                                results.hits.hits[i]['_source'].children[j]
                                    .prices !== null &&
                                typeof results.hits.hits[i]['_source'].children[
                                    j
                                ].prices === 'object' &&
                                this.currency in
                                    results.hits.hits[i]['_source'].children[j]
                                        .prices
                            ) {
                                results.hits.hits[i]['_source'].children[
                                    j
                                ].price =
                                    results.hits.hits[i]['_source'].children[
                                        j
                                    ].prices[this.currency];
                            }

                            if (
                                results.hits.hits[i]['_source'].children[j]
                                    .regularPrices !== null &&
                                typeof results.hits.hits[i]['_source'].children[
                                    j
                                ].regularPrices === 'object' &&
                                this.currency in
                                    results.hits.hits[i]['_source'].children[j]
                                        .regularPrices
                            ) {
                                results.hits.hits[i]['_source'].children[
                                    j
                                ].regularPrice =
                                    results.hits.hits[i]['_source'].children[
                                        j
                                    ].regularPrices[this.currency];
                            }

                            if (
                                results.hits.hits[i]['_source'].children[j]
                                    .salePrices !== null &&
                                typeof results.hits.hits[i]['_source'].children[
                                    j
                                ].salePrices === 'object' &&
                                this.currency in
                                    results.hits.hits[i]['_source'].children[j]
                                        .salePrices
                            ) {
                                results.hits.hits[i]['_source'].children[
                                    j
                                ].salePrice =
                                    results.hits.hits[i]['_source'].children[
                                        j
                                    ].salePrices[this.currency];
                            }

                            // Make the dates are stored as a date object and need to stripe any stored timezone to prevent date being adjusted
                            if (
                                results.hits.hits[i]['_source'].children[j]
                                    .saleStartDate !== null &&
                                results.hits.hits[i]['_source'].children[j]
                                    .saleStartDate !== ''
                            ) {
                                results.hits.hits[i]['_source'].children[
                                    j
                                ].saleStartDate = new Date(
                                    results.hits.hits[i]['_source'].children[
                                        j
                                    ].saleStartDate.replace(
                                        /[-+]\d+\:\d+$/,
                                        '',
                                    ),
                                );
                            }

                            if (
                                results.hits.hits[i]['_source'].children[j]
                                    .saleEndDate !== null &&
                                results.hits.hits[i]['_source'].children[j]
                                    .saleEndDate !== ''
                            ) {
                                results.hits.hits[i]['_source'].children[
                                    j
                                ].saleEndDate = new Date(
                                    results.hits.hits[i]['_source'].children[
                                        j
                                    ].saleEndDate.replace(/[-+]\d+\:\d+$/, ''),
                                );
                            }

                            // To improve performance we need to fetch all the upgrade OVP GEO prices
                            if (
                                results.hits.hits[i]['_source'].children[j]
                                    .upgrade_access !== null &&
                                Array.isArray(
                                    results.hits.hits[i]['_source'].children[j]
                                        .upgrade_access,
                                ) &&
                                results.hits.hits[i]['_source'].children[j]
                                    .upgrade_access.length > 0
                            ) {
                                for (
                                    let k = 0;
                                    k <
                                    results.hits.hits[i]['_source'].children[j]
                                        .upgrade_access.length;
                                    k++
                                ) {
                                    if (
                                        results.hits.hits[i]['_source']
                                            .children[j].upgrade_access[k]
                                            .prices !== null &&
                                        typeof results.hits.hits[i]['_source']
                                            .children[j].upgrade_access[k]
                                            .prices === 'object' &&
                                        this.currency in
                                            results.hits.hits[i]['_source']
                                                .children[j].upgrade_access[k]
                                                .prices
                                    ) {
                                        results.hits.hits[i][
                                            '_source'
                                        ].children[j].upgrade_access[k].price =
                                            results.hits.hits[i][
                                                '_source'
                                            ].children[j].upgrade_access[
                                                k
                                            ].prices[this.currency];

                                        // Need to set the change value to the matched price
                                        results.hits.hits[i][
                                            '_source'
                                        ].children[j].upgrade_access[
                                            k
                                        ].changeValue =
                                            results.hits.hits[i][
                                                '_source'
                                            ].children[j].upgrade_access[
                                                k
                                            ].price;
                                    }

                                    if (
                                        results.hits.hits[i]['_source']
                                            .children[j].upgrade_access[k]
                                            .regularPrices !== null &&
                                        typeof results.hits.hits[i]['_source']
                                            .children[j].upgrade_access[k]
                                            .regularPrices === 'object' &&
                                        this.currency in
                                            results.hits.hits[i]['_source']
                                                .children[j].upgrade_access[k]
                                                .regularPrices
                                    ) {
                                        results.hits.hits[i][
                                            '_source'
                                        ].children[j].upgrade_access[
                                            k
                                        ].regularPrice =
                                            results.hits.hits[i][
                                                '_source'
                                            ].children[j].upgrade_access[
                                                k
                                            ].regularPrices[this.currency];
                                    }

                                    if (
                                        results.hits.hits[i]['_source']
                                            .children[j].upgrade_access[k]
                                            .salePrices !== null &&
                                        typeof results.hits.hits[i]['_source']
                                            .children[j].upgrade_access[k]
                                            .salePrices === 'object' &&
                                        this.currency in
                                            results.hits.hits[i]['_source']
                                                .children[j].upgrade_access[k]
                                                .salePrices
                                    ) {
                                        results.hits.hits[i][
                                            '_source'
                                        ].children[j].upgrade_access[
                                            k
                                        ].salePrice =
                                            results.hits.hits[i][
                                                '_source'
                                            ].children[j].upgrade_access[
                                                k
                                            ].salePrices[this.currency];
                                    }

                                    // Make the dates are stored as a date object and need to stripe any stored timezone to prevent date being adjusted
                                    if (
                                        results.hits.hits[i]['_source']
                                            .children[j].upgrade_access[k]
                                            .saleStartDate !== null &&
                                        results.hits.hits[i]['_source']
                                            .children[j].upgrade_access[k]
                                            .saleStartDate !== ''
                                    ) {
                                        results.hits.hits[i][
                                            '_source'
                                        ].children[j].upgrade_access[
                                            k
                                        ].saleStartDate = new Date(
                                            results.hits.hits[i][
                                                '_source'
                                            ].children[j].upgrade_access[
                                                k
                                            ].saleStartDate.replace(
                                                /[-+]\d+\:\d+$/,
                                                '',
                                            ),
                                        );
                                    }

                                    if (
                                        results.hits.hits[i]['_source']
                                            .children[j].upgrade_access[k]
                                            .saleEndDate !== null &&
                                        results.hits.hits[i]['_source']
                                            .children[j].upgrade_access[k]
                                            .saleEndDate !== ''
                                    ) {
                                        results.hits.hits[i][
                                            '_source'
                                        ].children[j].upgrade_access[
                                            k
                                        ].saleEndDate = new Date(
                                            results.hits.hits[i][
                                                '_source'
                                            ].children[j].upgrade_access[
                                                k
                                            ].saleEndDate.replace(
                                                /[-+]\d+\:\d+$/,
                                                '',
                                            ),
                                        );

                                        // We also need to set the high posible date level sale date as it is set outside the loop
                                        // as selection the variations will not update the count down timer
                                        if (
                                            results.hits.hits[i]['_source']
                                                .children[j]
                                                .saleEndDate instanceof
                                                Date ==
                                                false ||
                                            (results.hits.hits[i]['_source']
                                                .children[j]
                                                .saleEndDate instanceof Date &&
                                                results.hits.hits[i]['_source']
                                                    .children[j].upgrade_access[
                                                    k
                                                ].saleEndDate >
                                                    results.hits.hits[i][
                                                        '_source'
                                                    ].children[j].saleEndDate)
                                        ) {
                                            results.hits.hits[i][
                                                '_source'
                                            ].children[j].saleEndDate =
                                                results.hits.hits[i][
                                                    '_source'
                                                ].children[j].upgrade_access[
                                                    k
                                                ].saleEndDate;
                                        }
                                    }

                                    // If the sale price is still set then need to set it back to original
                                    results.hits.hits[i]['_source'].children[
                                        j
                                    ].upgrade_access[k].hideSaleTimer = false;
                                    if (
                                        results.hits.hits[i]['_source']
                                            .children[j].upgrade_access[k]
                                            .saleEndDate instanceof Date &&
                                        currentDate >
                                            results.hits.hits[i]['_source']
                                                .children[j].upgrade_access[k]
                                                .saleEndDate
                                    ) {
                                        results.hits.hits[i][
                                            '_source'
                                        ].children[j].upgrade_access[
                                            k
                                        ].hideSaleTimer = true;
                                        if (
                                            parseFloat(
                                                results.hits.hits[i][
                                                    '_source'
                                                ].children[j].upgrade_access[
                                                    k
                                                ].salePrice.replace(
                                                    /[^0-9.-]+/g,
                                                    '',
                                                ),
                                            ) > 0
                                        ) {
                                            results.hits.hits[i][
                                                '_source'
                                            ].children[j].upgrade_access[
                                                k
                                            ].price =
                                                results.hits.hits[i][
                                                    '_source'
                                                ].children[j].upgrade_access[
                                                    k
                                                ].regularPrice;
                                            results.hits.hits[i][
                                                '_source'
                                            ].children[j].upgrade_access[
                                                k
                                            ].prices =
                                                results.hits.hits[i][
                                                    '_source'
                                                ].children[j].upgrade_access[
                                                    k
                                                ].regularPrices;
                                        }
                                    }

                                    // We need to fetch the difference from the lowest price to the current
                                    results.hits.hits[i]['_source'].children[
                                        j
                                    ].upgrade_access[k].upgradePrice = Math.max(
                                        0,
                                        parseFloat(
                                            results.hits.hits[i][
                                                '_source'
                                            ].children[j].upgrade_access[
                                                k
                                            ].price.replace(/[^0-9.-]+/g, ''),
                                        ) -
                                            parseFloat(
                                                results.hits.hits[i][
                                                    '_source'
                                                ].children[j].price.replace(
                                                    /[^0-9.-]+/g,
                                                    '',
                                                ),
                                            ),
                                    );

                                    // Need to fetch the matched currency symbol and append to the price
                                    results.hits.hits[i]['_source'].children[
                                        j
                                    ].upgrade_access[
                                        k
                                    ].upgradePrice = `${results.hits.hits[i][
                                        '_source'
                                    ].children[j].upgrade_access[
                                        k
                                    ].price.replace(/\d+([,.]\d+)?/g, '')}${
                                        results.hits.hits[i]['_source']
                                            .children[j].upgrade_access[k]
                                            .upgradePrice
                                    }`;

                                    if (
                                        results.hits.hits[i]['_source']
                                            .children[j].upgrade_access.length >
                                        1
                                    ) {
                                        results.hits.hits[i][
                                            '_source'
                                        ].children[j].upgrade_access[
                                            k
                                        ].title = `${results.hits.hits[i]['_source'].children[j].upgrade_access[k].value} days (+${results.hits.hits[i]['_source'].children[j].upgrade_access[k].upgradePrice})
                                            `;
                                    }
                                }
                            }

                            // If the sale price is still set then need to set it back to original
                            results.hits.hits[i]['_source'].children[
                                j
                            ].hideSaleTimer = false;
                            if (
                                results.hits.hits[i]['_source'].children[j]
                                    .saleEndDate instanceof Date &&
                                currentDate >
                                    results.hits.hits[i]['_source'].children[j]
                                        .saleEndDate
                            ) {
                                results.hits.hits[i]['_source'].children[
                                    j
                                ].hideSaleTimer = true;
                                if (
                                    parseFloat(
                                        results.hits.hits[i][
                                            '_source'
                                        ].children[j].salePrice.replace(
                                            /[^0-9.-]+/g,
                                            '',
                                        ),
                                    ) > 0
                                ) {
                                    results.hits.hits[i]['_source'].children[
                                        j
                                    ].price =
                                        results.hits.hits[i][
                                            '_source'
                                        ].children[j].regularPrice;
                                    results.hits.hits[i]['_source'].children[
                                        j
                                    ].prices =
                                        results.hits.hits[i][
                                            '_source'
                                        ].children[j].regularPrices;
                                }
                            }
                        }
                    }

                    // To improve performance we need to fetch all the upgrade OVP GEO prices
                    if (
                        results.hits.hits[i]['_source'].upgrade_language !==
                            null &&
                        Array.isArray(
                            results.hits.hits[i]['_source'].upgrade_language,
                        ) &&
                        results.hits.hits[i]['_source'].upgrade_language
                            .length > 0
                    ) {
                        for (
                            let j = 0;
                            j <
                            results.hits.hits[i]['_source'].upgrade_language
                                .length;
                            j++
                        ) {
                            if (
                                results.hits.hits[i]['_source']
                                    .upgrade_language[j].prices !== null &&
                                typeof results.hits.hits[i]['_source']
                                    .upgrade_language[j].prices === 'object' &&
                                this.currency in
                                    results.hits.hits[i]['_source']
                                        .upgrade_language[j].prices
                            ) {
                                results.hits.hits[i][
                                    '_source'
                                ].upgrade_language[j].price =
                                    results.hits.hits[i][
                                        '_source'
                                    ].upgrade_language[j].prices[this.currency];

                                // Need to set the change value to the matched price
                                results.hits.hits[i][
                                    '_source'
                                ].upgrade_language[j].changeValue =
                                    results.hits.hits[i][
                                        '_source'
                                    ].upgrade_language[j].price;
                            }

                            if (
                                results.hits.hits[i]['_source']
                                    .upgrade_language[j].regularPrices !==
                                    null &&
                                typeof results.hits.hits[i]['_source']
                                    .upgrade_language[j].regularPrices ===
                                    'object' &&
                                this.currency in
                                    results.hits.hits[i]['_source']
                                        .upgrade_language[j].regularPrices
                            ) {
                                results.hits.hits[i][
                                    '_source'
                                ].upgrade_language[j].regularPrice =
                                    results.hits.hits[i][
                                        '_source'
                                    ].upgrade_language[j].regularPrices[
                                        this.currency
                                    ];
                            }

                            if (
                                results.hits.hits[i]['_source']
                                    .upgrade_language[j].salePrices !== null &&
                                typeof results.hits.hits[i]['_source']
                                    .upgrade_language[j].salePrices ===
                                    'object' &&
                                this.currency in
                                    results.hits.hits[i]['_source']
                                        .upgrade_language[j].salePrices
                            ) {
                                results.hits.hits[i][
                                    '_source'
                                ].upgrade_language[j].salePrice =
                                    results.hits.hits[i][
                                        '_source'
                                    ].upgrade_language[j].salePrices[
                                        this.currency
                                    ];
                            }

                            // Make the dates are stored as a date object and need to stripe any stored timezone to prevent date being adjusted
                            if (
                                results.hits.hits[i]['_source']
                                    .upgrade_language[j].saleStartDate !==
                                    null &&
                                results.hits.hits[i]['_source']
                                    .upgrade_language[j].saleStartDate !== ''
                            ) {
                                results.hits.hits[i][
                                    '_source'
                                ].upgrade_language[j].saleStartDate = new Date(
                                    results.hits.hits[i][
                                        '_source'
                                    ].upgrade_language[j].saleStartDate.replace(
                                        /[-+]\d+\:\d+$/,
                                        '',
                                    ),
                                );
                            }

                            if (
                                results.hits.hits[i]['_source']
                                    .upgrade_language[j].saleEndDate !== null &&
                                results.hits.hits[i]['_source']
                                    .upgrade_language[j].saleEndDate !== ''
                            ) {
                                results.hits.hits[i][
                                    '_source'
                                ].upgrade_language[j].saleEndDate = new Date(
                                    results.hits.hits[i][
                                        '_source'
                                    ].upgrade_language[j].saleEndDate.replace(
                                        /[-+]\d+\:\d+$/,
                                        '',
                                    ),
                                );

                                // We also need to set the high posible date level sale date as it is set outside the loop
                                // as selection the variations will not update the count down timer
                                if (
                                    results.hits.hits[i]['_source']
                                        .saleEndDate instanceof
                                        Date ==
                                        false ||
                                    (results.hits.hits[i]['_source']
                                        .saleEndDate instanceof Date &&
                                        results.hits.hits[i]['_source']
                                            .saleEndDate >
                                            results.hits.hits[i]['_source']
                                                .upgrade_language[j]
                                                .saleEndDate)
                                ) {
                                    results.hits.hits[i][
                                        '_source'
                                    ].saleEndDate =
                                        results.hits.hits[i][
                                            '_source'
                                        ].upgrade_language[j].saleEndDate;
                                }
                            }

                            // If the sale price is still set then need to set it back to original
                            results.hits.hits[i]['_source'].upgrade_language[
                                j
                            ].hideSaleTimer = false;
                            if (
                                results.hits.hits[i]['_source']
                                    .upgrade_language[j].saleEndDate instanceof
                                    Date &&
                                currentDate >
                                    results.hits.hits[i]['_source']
                                        .upgrade_language[j].saleEndDate
                            ) {
                                results.hits.hits[i][
                                    '_source'
                                ].upgrade_language[j].hideSaleTimer = true;
                                if (
                                    parseFloat(
                                        results.hits.hits[i][
                                            '_source'
                                        ].upgrade_language[j].salePrice.replace(
                                            /[^0-9.-]+/g,
                                            '',
                                        ),
                                    ) > 0
                                ) {
                                    results.hits.hits[i][
                                        '_source'
                                    ].upgrade_language[j].price =
                                        results.hits.hits[i][
                                            '_source'
                                        ].upgrade_language[j].regularPrice;
                                    results.hits.hits[i][
                                        '_source'
                                    ].upgrade_language[j].prices =
                                        results.hits.hits[i][
                                            '_source'
                                        ].upgrade_language[j].regularPrices;
                                }
                            }

                            // We need to fetch the difference from the lowest price to the current
                            results.hits.hits[i]['_source'].upgrade_language[
                                j
                            ].upgradePrice = Math.max(
                                0,
                                parseFloat(
                                    results.hits.hits[i][
                                        '_source'
                                    ].upgrade_language[j].price.replace(
                                        /[^0-9.-]+/g,
                                        '',
                                    ),
                                ) -
                                    parseFloat(
                                        results.hits.hits[i][
                                            '_source'
                                        ].price.replace(/[^0-9.-]+/g, ''),
                                    ),
                            );

                            // Need to fetch the matched currency symbol and append to the price
                            results.hits.hits[i]['_source'].upgrade_language[
                                j
                            ].upgradePrice = `${results.hits.hits[i][
                                '_source'
                            ].upgrade_language[j].price.replace(
                                /\d+([,.]\d+)?/g,
                                '',
                            )}${
                                results.hits.hits[i]['_source']
                                    .upgrade_language[j].upgradePrice
                            }`;

                            if (
                                results.hits.hits[i]['_source'].upgrade_language
                                    .length > 1
                            ) {
                                results.hits.hits[i][
                                    '_source'
                                ].upgrade_language[
                                    j
                                ].title = `${results.hits.hits[i]['_source'].upgrade_language[j].title}
                                        (+${results.hits.hits[i]['_source'].upgrade_language[j].upgradePrice})
                                    `;
                            }
                        }
                    }

                    // If the sale price is still set then need to set it back to original
                    results.hits.hits[i]['_source'].hideSaleTimer = false;
                    if (
                        results.hits.hits[i]['_source'].saleEndDate instanceof
                            Date &&
                        currentDate >
                            results.hits.hits[i]['_source'].saleEndDate
                    ) {
                        results.hits.hits[i]['_source'].hideSaleTimer = true;
                        if (
                            parseFloat(
                                results.hits.hits[i][
                                    '_source'
                                ].salePrice.replace(/[^0-9.-]+/g, ''),
                            ) > 0
                        ) {
                            results.hits.hits[i]['_source'].price =
                                results.hits.hits[i]['_source'].regularPrice;
                            results.hits.hits[i]['_source'].prices =
                                results.hits.hits[i]['_source'].regularPrices;
                        }
                    }
                }

                this.results.results[i] = results.hits.hits[i]['_source'];
            }

            this.results.pagination.total =
                Math.ceil(this.results.total / this.perPage) || 1;
            this.results.pagination.prev = this.results.pagination.current > 1;
            this.results.pagination.next =
                this.results.pagination.current < this.results.pagination.total;
        }

        return this.results;
    }

    async getResults() {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        const esAuth = process.env.ES_AUTH;

        if (esAuth) {
            headers.append('Authorization', `Basic ${esAuth}`);
        }

        const results = await fetch(this.searchEndpoint, {
            method: 'POST',
            headers: headers,
            body: this.query,
        }).then(response => response.json());

        return this.formatResults(results);
    }
}
