import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Pin from '../../../images/pin.png';

const propTypes = {
    options: PropTypes.object,
};

const Marker = options => {
    const [marker, setMarker] = React.useState();

    useEffect(() => {
        if (!marker) {
            const mapMarker = new google.maps.Marker({
                position: options.position,
                map: options.map,
                title: options?.markerData?.display_title !== ''
                    ? options?.markerData?.display_title
                    : options?.markerData?.title,
                icon: {
                    url: Pin,
                    scaledSize: new window.google.maps.Size(35, 35),
                },
            });

            setMarker(mapMarker);

            if (options.hasOwnProperty('handleMarkerInitialisation')) {
                options.map.addListener('idle', () => options.handleMarkerInitialisation(mapMarker, options.map));
            }
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        let listener;

        if (marker) {
            if (options.hasOwnProperty('handleActiveInfoWindow')) {
                listener = marker.addListener('click', () => {
                    options.handleActiveInfoWindow(
                        marker,
                        options.map,
                        options.markerData,
                        options.position,
                    );
                });
            } else {
                marker.setOptions(options);
            }
        }

        return () => {
            if (marker) {
                google.maps.event.removeListener(listener);
            }
        };
    }, [marker, options]);

    return null;
};

Marker.propTypes = propTypes;

export default Marker;
