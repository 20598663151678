import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const VimeoEmbed = ({ url, active, autoplay = true }) => {
    const vimeoParser = url => {
        const match = url.match(/(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/);

        return match && match[3].length ? match[3] : false;
    };

    const [videoId, setVideoId] = useState(
        `${vimeoParser(url)}`,
    );

    useEffect(() => {
        if (active) {
            const player = new Vimeo.Player(`vimeo-embed-${videoId}`, {
                url: url,
                width: 853,
                height: 480,
                autoplay: autoplay
            });
        }
    }, [active]);

    return (
        <div id={`vimeo-embed-${videoId}`} className="u-video-embed"></div>
    );
};

VimeoEmbed.propTypes = {
    url: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
};

export default VimeoEmbed;
