import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const YoutubeEmbed = ({ url, active, autoplay = false }) => {
    const youtubeParser = url => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = url.match(regExp);

        return match && match[7].length == 11 ? match[7] : false;
    };

    const [videoSrc, setVideoSrc] = useState(
        `https://www.youtube.com/embed/${youtubeParser(url)}?enablejsapi=1${autoplay ? '&autoplay=1' : ''}`,
    );

    useEffect(() => {
        if (active) {
            const currentVideoSrc = videoSrc;

            setVideoSrc(`${currentVideoSrc}&autoplay=1`);
        }
    }, [active]);

    return (
        <div className="u-video-embed">
            <iframe
                className="youtube-iframe"
                width="853"
                height="480"
                src={videoSrc}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>
    );
};

YoutubeEmbed.propTypes = {
    url: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
};

export default YoutubeEmbed;
