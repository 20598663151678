import { addToCart, convertToPrice, discountTenPercent } from './utilities';

// Update price
document.addEventListener('change', e => {
    if (!e.target.matches('[data-behaviour="upsell-select"]')) return;

    const option = e.target.querySelector(`option[value="${e.target.value}"]`);
    const price = option?.dataset?.price ? option.dataset.price : false;
    const currency = e.target.dataset.currencySymbol || '£';

    document.querySelector('[data-behaviour="upsell-price"]').innerHTML =
        price != '' ? convertToPrice(discountTenPercent(price), currency) : 'N/A';
});

// Add to cart button
document.addEventListener('click', async e => {
    if (!e.target.matches('[data-behaviour="upsell-add-to-cart"]')) return;
    e.preventDefault();

    const product = document.querySelector('[data-behaviour="upsell-select"]');
    if (product) {
        const selectedOption = product.options[product.selectedIndex];
        if (selectedOption) {
            const cartStatus = await addToCart(
                selectedOption.value,
                1,
                selectedOption.dataset?.variation,
                true
            );

            window.location.href = cartStatus.status === 'error'
                ? `${wp_ajax.home_url}basket/`
                : `${wp_ajax.home_url}checkout/`;
        }
    }
});
