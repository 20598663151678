import React from "react";

const NewsItem = ({ item }) => {

    return (
        <div className="article-listing__preview-container">
            <div className="article-preview article-preview--standard_altern article-preview--small-text u-hover-state">
                <div className="article-preview__columns article-preview__columns--standard_altern">
                    <div className="article-preview__column article-preview__column--standard_altern">
                        <a href={item.url} alt="article url">
                            <div className="article-preview__image-wrap u-image-absolute">
                                {item.thumbnail && <img src={item.thumbnail} title={item.title}/>}
                            </div>
                        </a>
                    </div>

                    <div className="article-preview__column article-preview__column--standard_altern">
                        <div className="article-preview__post-info article-preview__post-info--standard_altern">
                            {'name' in item.primary_category && (
                                <div className="article-preview__taxonomy-terms">
                                    <a href={item.primary_category.url}>{item.primary_category.name}</a>
                                </div>
                            )}

                            <div className="article-preview__date-wrap">
                                <span>
                                    {item.date}
                                </span>
                            </div>
                        </div>

                        <div className="article-preview__title-wrap">
                            <a href={item.url} alt="article url">
                                <h3 className="article-preview__title">
                                    {item.title}
                                </h3>
                            </a>
                        </div>

                        <div className="article-preview__description-wrap">
                            {item.excerpt.length > 150 ? item.excerpt.slice(0, 150)+'...' : item.excerpt}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsItem