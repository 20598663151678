import React from "react";
import PropTypes from "prop-types";
const proptypes = {
    price: PropTypes.string.isRequired, //@todo may have to change to object for price and currency
    text: PropTypes.string
}
const FromPrice = ({ price, text = 'From' }) => {

    return (
        <div className="results-card__from-price">
            <span className="results-card__from-price-text">{text}</span>
            <span className="results-card__from-price-price">{price}</span>
        </div>
    )
}

FromPrice.proptypes = proptypes;
export default FromPrice;