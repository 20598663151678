import React, { useEffect, useContext, useState } from 'react';
import { BookingContext } from '../Context/BookingContext';
import PanelTitle from '../PanelTitle';
import SessionTypeItem from '../SessionTypePicker/SessionTypeItem';

// Icons
import LoadingIcon from '../../Icons/LoadingIcon';
import SessionOnlineIcon from '../../Icons/SessionOnlineIcon';
import SessionOnetooneIcon from '../../Icons/SessionOnetooneIcon';
import SessionTypeLiveGroupIcon from '../../Icons/SessionTypeLiveGroupIcon';

const SessionTypePicker = () => {
    const [bookingState, setBookingState] = useContext(BookingContext);
    const [firstLoading, setFirstLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showComponent, setShowComponent] = useState(false);
    const [sessionTypes, setSessionTypes] = useState([]);

    const sessionTypeListData = [
        {
            id: 1,
            key: 'live-group',
            name: 'Live Group Seminar',
            icon: <SessionTypeLiveGroupIcon />,
        },
        {
            id: 2,
            key: 'group',
            name: 'Group Seminar',
            icon: <SessionTypeLiveGroupIcon />,
        },
        {
            id: 3,
            key: 'onetoone',
            name: 'One-to-One',
            icon: <SessionOnetooneIcon />,
        },
        {
            id: 4,
            key: 'ovp',
            name: 'On-demand video programmes',
            icon: <SessionOnlineIcon />,
        },
        {
            id: 5,
            key: 'taster',
            name: 'Live Group Seminar Taster',
            icon: <SessionTypeLiveGroupIcon />,
        },
    ];

    useEffect(() => {
        setLoading(true);

        if (bookingState.session) {
            if (bookingState.session.current.id !== null) {
                setSessionTypes(bookingState.addiction.sessionTypesAvailable);

                if (firstLoading) {
                    setFirstLoading(false);
                    handleSessionTypePreSelection();
                }

                setShowComponent(true);
            } else {
                setShowComponent(false);
            }
        } else {
            setShowComponent(false);
        }

        setLoading(false);
    }, [bookingState.session]);

    const handleSessionTypeSelection = item => {
        let oldData = bookingState;

        if (oldData.location) {
            delete oldData.location;
        }

        let newData = {
            ...oldData,
            sessionType: {
                current: {
                    name: item.name,
                    id: item.id,
                    key: item.key,
                },
            },
        };

        setBookingState(newData);
    };

    /**
     * handleSessionTypePreSelection
     * Updates current addiction in booking state based on query param
     */
    const handleSessionTypePreSelection = () => {
        setFirstLoading(false);
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get('addiction_id') && searchParams.get('session') && searchParams.get('session_type')) {
            const sessionTypeListItem = sessionTypeListData.find(
                sessionType => sessionType.key === searchParams.get('session_type')
            );

            if (sessionTypeListItem) {
                let oldData = bookingState;
                let newData = {
                    ...oldData,
                    sessionType: {
                        current: sessionTypeListItem,
                    },
                };
                setBookingState(newData);
            }
        }
    };

    /**
     * Session Type List
     */
    const sessionTypeList = () => {
        const sessionCentre = ['group', 'onetoone'];
        const sessionOnline = ['live-group', 'onetoone', 'ovp', 'taster'];
        const sessionOnetoone = ['onetoone'];

        let dataSelected = bookingState.session.current.key;

        let sessionTypeData = [];

        switch (dataSelected) {
            case 'location':
                sessionTypeData = sessionCentre;
                break;
            case 'online':
                sessionTypeData = sessionOnline;
                break;
            case 'onetoone':
                sessionTypeData = sessionOnetoone;
                break;
            default:
                break;
        }

        sessionTypeData = sessionTypeData.filter(
            elem => sessionTypes.includes(elem)
        );

        if (sessionTypeData) {
            return sessionTypeData.map(key => {
                let dataObj = sessionTypeListData.filter(
                    elem => elem.key === key,
                );

                if (typeof dataObj[0] !== undefined) {
                    return (
                        <SessionTypeItem
                            item={dataObj[0]}
                            handleSelection={handleSessionTypeSelection}
                            key={'session-type-item-' + dataObj[0].id}
                        />
                    );
                }
            });
        }
        return;
    };

    /**
     * @returns HTML
     */
    const helpContent = () => {
        return (
            bookingState.data.help.session_type_content !== '' && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: bookingState.data.help.session_type_content,
                    }}
                />
            )
        );
    };

    if (!showComponent) return <></>;

    return (
        <div className="session-type-picker u-relative">
            <PanelTitle
                title="What type of session?"
                helpContent={helpContent()}
            />
            <div className="session-type-picker__content session-picker__content">
                {loading && <LoadingIcon />}
                {!loading && bookingState.session && (
                    <div className="session-picker__list">
                        {sessionTypeList()}
                    </div>
                )}
            </div>
            <div className="booking__seperator"></div>
        </div>
    );
};

export default SessionTypePicker;
