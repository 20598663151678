import React from "react";
import PropTypes from "prop-types";

const proptypes = {
    count: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
};

const ResultsFound = ({ count, label }) => {
    return (
        <div className="results-found">
            <span className="results-found__count">{count}</span>
            <span className="results-found__label">{label}</span>
        </div>
    )
} 

ResultsFound.propTypes = proptypes;
export default ResultsFound;