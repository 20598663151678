import React, { useState } from "react";
import PropTypes from "prop-types";

const proptypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired
};

const Tag = ({icon, text}) => {
    return (
        <div className="results-card__tag">
            <span className="material-icons material-icons-outlined results-card__tag-icon">{icon}</span>
            <span className="results-card__tag-text">{text}</span>
        </div>
    );
}

Tag.propTypes = proptypes;

export default Tag