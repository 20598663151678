import React, { useEffect, useState } from 'react';
import { getGeoPrice } from '../../modules/utilities';

const ServiceItem = ({ service }) => {
    const [itemPrice, setItemPrice] = useState(service.price);

    useEffect(() => {
        fetchItemPrice();
    }, []);

    const itemLinks = () => {
        return (
            <div className="service__item-links">
                <a href={service.book_now_url} className="button button--pink button--full-width">Book Now</a>
                {service.more_info &&
                    <a href={service.more_info} className="u-text-underline u-text-center">More Info</a>
                }
            </div>
        )
    }

    const itemFeatures = () => {
        return (
            <ul className="service__item-features">
                {
                    Object.keys(service.service_features_list).map((key, index) => (
                        <li className="service__item-feature" key={index}>
                            <span className="material-icons service__item-tick">check_circle</span>
                            <p>{service.service_features_list[key].feature}</p>
                        </li>
                    ))
                }
            </ul>
        )
    }

    const fetchItemPrice = async () => {
        const price = await getGeoPrice(service.price);
        setItemPrice(service.price.replace(/\[price\](.*)\[\/price\]/i, price));
    }

    return (
        <li className="service__item">
            <div className="service__item-heading">
                <div className="service__item-heading-inner">
                    {service.icon && <img className="service__item-icon" src={service.icon} alt={ 'Icon ' + service.title} />}
                    <h3 className="service__item-title u-text-center">{service.title}</h3>
                    <h4 className="service__item-price u-text-center">{itemPrice}</h4>
                    {itemLinks()}
                </div>
            </div>
            <div className="service__item-content">
                <div className="service__item-content-inner">
                    {service.secondary_title && <h4 className="service__item-sub-title">{service.secondary_title}</h4>}
                    {service.intro_text && <p className="service__item-intro">{service.intro_text}</p>}
                    {itemFeatures()}
                    {itemLinks()}
                </div>
            </div>
        </li>
    )
}

export default ServiceItem