import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    totalCount: PropTypes.number,
    onNextChange: PropTypes.func,
    onPrevChange: PropTypes.func,
    paginationVars: PropTypes.object,
    perPage: PropTypes.number,
    showCount: PropTypes.bool,
};

const Pagination = ({
    totalCount,
    onNextChange,
    onPrevChange,
    paginationVars,
    perPage,
    showCount = true,
}) => {
    const [reviewEnd, setReviewEnd] = useState(0);
    const [reviewStart, setReviewStart] = useState(0);

    useEffect(() => {
        if (totalCount !== 0) {
            let tempReviewEnd =
                perPage * paginationVars.current > totalCount
                    ? totalCount
                    : perPage * paginationVars.current;
            setReviewEnd(tempReviewEnd);

            let tempReviewStart =
                perPage * paginationVars.current - perPage == 0
                    ? 1
                    : perPage * paginationVars.current - perPage + 1;
            setReviewStart(tempReviewStart);
        } else {
            setReviewStart(0);
            setReviewEnd(0);
        }
    }, [perPage, paginationVars.current, totalCount]);

    function handlePrevPagination() {
        onPrevChange();
    }
    function handleNextPagination() {
        onNextChange();
    }

    return (
        <div className="pagination__wrapper">
            {showCount && (
                <>
                    <div className="pagination__spacer"></div>
                    <p className="u-text-center">
                        You've seen {reviewStart}-{reviewEnd} of {totalCount}
                    </p>
                </>
            )}
            {paginationVars.total > 1 && (
                <nav className="pagination">
                    <a
                        {... paginationVars.prev ? {rel:"prev"} : {}}
                        onClick={handlePrevPagination}
                        className={
                            'button button--with-icon pagination__prev' +
                            (paginationVars.prev ? '' : ' button--disabled')
                        }>
                        <span className="material-icons">arrow_right_alt</span> Prev
                    </a>
                    <a
                         {... paginationVars.next ? {rel:"next"} : {}}
                        onClick={handleNextPagination}
                        className={
                            'button button--with-icon pagination__next' +
                            (paginationVars.next ? '' : ' button--disabled')
                        }>
                        Next <span className="material-icons">arrow_right_alt</span>
                    </a>
                </nav>
            )}
        </div>
    );
};

Pagination.propTypes = propTypes;

export default Pagination;
