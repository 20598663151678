import React from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';

import Map from './Map';
import Marker from './Marker';
import PropTypes from 'prop-types';

const propTypes = {
    geopoint: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    }),
};

const LocationMap = ({ geopoint }) => {
    const zoom = 17;
    const center = geopoint;

    const mapRender = status => {
        if (status === 'LOADING') {
            return null;
        }

        const zoomControlOptions = {
            position: google.maps.ControlPosition.LEFT_BOTTOM,
        };

        return (
            <Map
                center={center}
                zoom={zoom}
                style={{ height: '100%', width: '100%' }}
                disableDefaultUI={true}
                zoomControl={true}
                zoomControlOptions={zoomControlOptions}>
                <Marker
                    position={{
                        lat: geopoint.lat,
                        lng: geopoint.lng,
                    }}
                />
            </Map>
        );
    };

    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                position: 'absolute',
            }}>
            <Wrapper
                apiKey={process.env.GOOGLE_MAP_API}
                render={mapRender}></Wrapper>
        </div>
    );
};

LocationMap.propTypes = propTypes;

export default LocationMap;
