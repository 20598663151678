// SET FUNCTIONS
export const addToSet = (set, valueToAdd) => {
    return set.add(valueToAdd);
}
export const deleteFromSet = (set, valueToRemove) => {
    return set.delete(valueToRemove);
}
/**
 * Compares two sets
 * values can be ordered differently
 * @param {Set} setA 
 * @param {Set} setB 
 * @returns bool
 */
export const areEqualSets = (setA, setB) => {
    if (setA.size !== setB.size) return false;
    for (let a of setA) if (!setB.has(a)) return false;
    return true;
}