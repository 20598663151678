import React from "react";

const TotalCount = ({ count }) => {

    return (
        <div className="total-count u-text-right">
            {count} Items
        </div>
    )
}
export default TotalCount