import { copyToClipboard } from "./utilities";

const copylink = document.querySelectorAll('[data-behaviour="copy-link');

if (copylink.length > 0) {
    copylink.forEach(el => {
        el.addEventListener('click', function (event) {
            event.preventDefault();

            if (el.hasAttribute('href')) {
                copyToClipboard(el.getAttribute('href'));
                // @todo add message showing that it has been copied
            }
        });
    });
}