import React, { useEffect, useRef, useState } from "react";
import Tab from "./Tab";
import SearchTabContent from "../Search/SearchTabContent";
import FAQsTabContent from "../FAQs/FAQsTabContent";
import Swiper, { Navigation } from 'swiper';
//Functions
import { getPagedFromUrl, getParamFromUrl, pushHistory, debounce } from "../../modules/utilities";

Swiper.use([Navigation]);

const Tabs = ({ tabs, searchQuery, parentDataType, onLoadedTab = null }) => {

    useEffect(() => {
        let swiper = undefined;

        const prev = document.querySelector('[data-ref="tab-slider-prev"]');
        const next = document.querySelector('[data-ref="tab-slider-next"]');

        const enableSwiper = () => {
            swiper = new Swiper('[data-ref="tab-slider"]', {
                direction: 'horizontal',
                slidesPerView: 2.2,
                spaceBetween: 10,
                // Navigation arrows
                navigation: {
                    nextEl: next,
                    prevEl: prev,
                },
            });
        }

        if (document.body.clientWidth < 769) {
            enableSwiper();
        }

        window.addEventListener('resize', debounce(() => {
            if (document.body.clientWidth < 769 && swiper == undefined) {
                enableSwiper();
            } else if (document.body.clientWidth > 768 && swiper !== undefined) {
                swiper.destroy(true, true);
                swiper = undefined;
            }
        }), 300);
    }, [])

    //Verify loaded tab (from url) is indexed in tabs list
    let initialTabIndex = onLoadedTab ? Object.keys(tabs).indexOf(onLoadedTab) : 0;
    if (initialTabIndex == -1) initialTabIndex = 0;

    const [currentTabIndex, setCurrentTabIndex] = useState(initialTabIndex);
    const [initialLoad, setInitialLoad] = useState(false);
    const wrappingRef = useRef(); // for scroll on pagination change
    let currentPageUrl = getPagedFromUrl();
    const defaultPaged = (tabKey) => (
        {
            'old': 1,
            'new': currentPageUrl !== 1 && getParamFromUrl('tab') == tabKey ? currentPageUrl : 1
        }
    );

    const createDefaultPaginationState = () => {
        let pagination = {};

        Object.keys(tabs).forEach(key => {
            pagination = {
                ...pagination, [key]: defaultPaged(key)
            }
        })
        return pagination;
    }

    const [paginationState, setPaginationState] = useState(createDefaultPaginationState())

    function handleTabChange(index) {
        if (currentTabIndex !== index) {
            let previousDataType = Object.keys(tabs)[index];
            let newPaged = paginationState[previousDataType].new;

            setCurrentTabIndex(index);
            setPaginationState(updatePaginationState(previousDataType, newPaged, newPaged));
            pushHistory('tab', Object.keys(tabs)[index]);
        }
    }

    const updatePaginationState = (dataType, oldPaged, newPaged) => {
        return {
            ...paginationState, [dataType]: {
                'old': oldPaged,
                'new': newPaged
            }
        }
    }

    const setPaginationFromChild = (dataType, oldPaged, newPaged) => {
        setPaginationState(updatePaginationState(dataType, oldPaged, newPaged));
    }

    function handleScrollTabChange() {
        wrappingRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    const tabContent = () => {
        if (tabs) {
            let dataType;

            if (onLoadedTab &&
                !initialLoad &&
                onLoadedTab in tabs
            ) {
                dataType = onLoadedTab;
            } else {
                dataType = Object.keys(tabs)[currentTabIndex];
            }

            if (dataType && parentDataType === 'search') {
                return (
                    <SearchTabContent
                        dataType={dataType}
                        searchQuery={searchQuery}
                        scrollToParentRef={handleScrollTabChange}
                        paginationState={paginationState}
                        paginationStateUpdate={setPaginationFromChild}
                        onDataLoaded={handleTabDataLoad}
                    />
                )
            } else if (dataType && parentDataType === 'faq') {
                return (
                    <FAQsTabContent
                        dataType={dataType}
                        tabs={tabs}
                        paginationState={paginationState}
                        scrollToParentRef={handleScrollTabChange}
                        paginationStateUpdate={setPaginationFromChild}
                        onDataLoaded={handleTabDataLoad}
                    />
                )
            }
        }
    }

    const tabTitle = (title) => {
        return (
            <h4 className="tabs__tab-title">{title}</h4>
        )
    }

    const handleTabDataLoad = ({ data }) => {
        if (initialLoad) return;
        if (data.length == 0) setCurrentTabIndex(currentTabIndex + 1);
        setInitialLoad(true);
    }

    return (
        <div className="tabs">
            <div className="tabs__tab-wrapper" ref={wrappingRef}>
                <div data-ref="tab-slider-prev" className="tab-previous"><span className="material-icons">chevron_left</span></div>
                <div data-ref="tab-slider-next" className="tab-next"><span className="material-icons">chevron_right</span></div>
                <div className="tabs__tab-wrapper-inner swiper" data-ref="tab-slider">
                    <div className="swiper-wrapper">
                        {Object.keys(tabs).map((key, index) => (
                            <div className="swiper-slide" key={'tab-key-' + index}>
                                <Tab
                                    tabTitle={tabTitle(tabs[key])}
                                    index={index}
                                    active={currentTabIndex === index ? true : false}
                                    changeTab={handleTabChange}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="tabs__content-wrapper">
                {tabContent()}
            </div>
        </div>
    )
}
export default Tabs