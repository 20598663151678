import React, { useEffect, useContext, useRef, useState } from 'react';
import { BookingContext } from '../Context/BookingContext';

// Components
import LoadingIcon from '../../Icons/LoadingIcon';
import PanelTitle from '../PanelTitle';
import Addiction from './Addiction';

// Slider
import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);

const AddictionPicker = () => {
    const [bookingState, setBookingState] = useContext(BookingContext);
    const [loading, setLoading] = useState(true);
    const [addictions, setAddictions] = useState({});
    const [addictionsVisible, setAddictionsVisible] = useState(true);

    const fetchAddictions = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('security', wp_ajax.security);

        await fetch(`${wp_ajax.ajax_url}?action=get_addictions`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(response => {
                setAddictions(response.data.addictions);
                handleAddictionPreSelection(response.data.addictions);
                setLoading(false);
            })
            .catch(error => {
                console.log(`Error during fetch: ${error}`);
            });
    };

    useEffect(() => {
        fetchAddictions();
    }, []);

    /**
     * handleAddictionSelection
     * Updates current addiction in booking state
     * @param {object} item
     */
    const handleAddictionSelection = item => {
        const searchParams = new URLSearchParams(window.location.search);
        const voucherParam = searchParams.get('voucher');
        let oldData = bookingState;

        if (oldData.sessionCount) {
            delete oldData.location;
        }

        if (oldData.location) {
            delete oldData.location;
        }

        let newData = {
            ...oldData,
            addiction: {
                current: {
                    name: item.name,
                    display_name: item.display_name,
                    id: item.id,
                    form_id: item.form_id,
                },
                vouchersAvailable: item.vouchers_available,
                sessionsAvailable: item.sessions_available,
                sessionTypesAvailable: item.session_types_available,
                money_back_guarantee: item.money_back_guarantee,
                featured_testimonial: item.featured_testimonial,
                booking_journey_url: item.booking_journey_url,
            },
        };
        newData = {
            ...newData,
            voucher: {
                current: {
                    name: voucherParam === 'yes' ? 'Yes' : 'No',
                    id: voucherParam === 'yes' ? 1 : 2,
                    key: voucherParam === 'yes' ? 'yes' : 'no',
                },
            },
        };
        newData = {
            ...newData,
            session: {
                current: {
                    name: null,
                    id: null,
                    key: null,
                },
            },
        };
        newData = {
            ...newData,
            sessionType: {
                current: {
                    name: null,
                    id: null,
                    key: null,
                },
            },
        };

        setBookingState(newData);
        toggleActiveAddictions();
    };

    /**
     * handleAddictionPreSelection
     * Updates current addiction in booking state based on query param
     */
    const handleAddictionPreSelection = data => {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get('addiction_id')) {
            const addictionItem = data.find(
                addiction =>
                    addiction.slug === searchParams.get('addiction_id'),
            );

            if (addictionItem) {
                handleAddictionSelection(addictionItem);
            }
        }
    };

    const toggleActiveAddictions = () => {
        setAddictionsVisible(addictionsVisible ? false : true);
    };
    const addictionsList = () => {
        let addictionListData = addictions;
        if (!addictionsVisible && bookingState.addiction) {
            const addictionItem = addictionListData.find(
                addiction => addiction.id === bookingState.addiction.current.id,
            );

            if (addictionItem) {
                addictionListData = addictionListData.filter(
                    addiction =>
                        addiction.id !== bookingState.addiction.current.id,
                );
            }

            addictionListData = addictionItem
                ? [addictionItem].concat(addictionListData.slice(0, 2))
                : addictionListData.slice(0, 3);
        }

        return Object.keys(addictionListData).map(index => {
            return (
                <div
                    className="addiction-picker__list-item"
                    key={'addiction-key-' + index}>
                    <Addiction
                        addiction={addictionListData[index]}
                        handleSelection={handleAddictionSelection}
                        key={'addiction-item-' + index}
                    />
                </div>
            );
        });
    };

    if (loading) return <LoadingIcon />;

    return (
        <div className="addiction-picker">
            <PanelTitle title="What can we help you with?" />
            <div className="addiction-picker__list">{addictionsList()}</div>
            <div className="addiction-picker__toggle">
                <button
                    className="addiction-picker__toggle-button button"
                    onClick={() => toggleActiveAddictions()}>
                    Show {addictionsVisible ? 'Less' : 'More'}
                </button>
            </div>
            <div className="booking__seperator"></div>
        </div>
    );
};

export default AddictionPicker;
