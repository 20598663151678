const toggleViewAlls = document.querySelectorAll('[data-behaviour*="toggle-view-all"]');
if (toggleViewAlls.length > 0) {
    toggleViewAlls.forEach((toggleViewAll) => {
        toggleViewAll.addEventListener('click', () => {
            const oldAltText = toggleViewAll.innerHTML;
            toggleViewAll.innerHTML = toggleViewAll.dataset.altText;
            toggleViewAll.dataset.altText = oldAltText;

            const viewAllTargets = toggleViewAll.closest('.content-section').querySelectorAll('[data-target="view-all-content"]');
            if (viewAllTargets.length > 0) {
                viewAllTargets.forEach(viewAllTarget => viewAllTarget.classList.toggle('view-all-hidden'));
            }
        });
    });
}
