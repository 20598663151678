import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BookingContext } from '../Context/BookingContext';

// Icons
import LoadingIcon from '../../Icons/LoadingIcon';
import SessionOnDemand from '../../Icons/SessionOnDemand';
import MoneyBackGuarantee90Icon from '../../Icons/MoneyBackGuarantee90Icon';

//Sub components
import IconList from './components/IconList';
import Tag from './components/Tag';
import Summary from './components/Summary';
import Price from './components/Price';
import FromPrice from './components/FromPrice';
import MoneyBackGuarantee from './components/MoneyBackGuarantee';
import TestimonialCard from './components/TestimonialCard';
import Upsell from './components/Upsell';
import Form from '../../Forms';
// Utilities
import Dropdown from '../../Utilities/Dropdown';
import { addToCart, pushHistory } from '../../../modules/utilities';
import CountdownTimer from '../../Utilities/CountdownTimer';

const proptypes = {
    item: PropTypes.object.isRequired,
    type: PropTypes.oneOf(['group', 'live-group', 'ovp', 'onetoone']),
    preferType: PropTypes.oneOf(['location', 'online', 'onetoone']),
    titleOverride: PropTypes.string,
    handleErrorMessages: PropTypes.func,
    handleUpsell: PropTypes.func,
    couponCode: PropTypes.string,
    priceOverride : PropTypes.string,
    overrideText : PropTypes.string,
    overrideOriginalText : PropTypes.string,
    form: PropTypes.string
};

const ResultsCard = ({ item, type, preferType, titleOverride, handleErrorMessages, handleUpsell, couponCode = '', priceOverride = '', overrideText = '', overrideOriginalText, form = '' }) => {
    const [bookingState, setBookingState] = useContext(BookingContext);
    const [showIconList, setShowIconList] = useState(false);
    const [tag, setTag] = useState({
        text: null,
        icon: null,
    });
    const [loading, setLoading] = useState(false);
    const [showComponent, setShowComponent] = useState(true);
    const searchParams = new URLSearchParams(window.location.search);
    if (couponCode == '' && searchParams.has('coupon_code')) {
        couponCode = searchParams.get('coupon_code');
    }

    const updateTag = cardItem => {
        if (type === 'group' || type === 'live-group' || type === 'taster') {
            setTag({
                text: cardItem.date,
                icon: 'event',
            });
        } else if (type === 'onetoone') {
            setTag({
                text: 'Complete Privacy',
                icon: 'verified_user',
            });
        } else if (type === 'ovp') {
            setTag({
                text: 'On-demand',
                icon: <SessionOnDemand />,
            });
        }
    };

    const handleLanguageSelection = option => {
        // As we are showing/hiding multiple cards we need to match sure that the other dropdowns are synced
        const resultsActiveCard = document.querySelector(
            `.results-card__content-container[data-id="${option.value}"]`,
        );
        if (resultsActiveCard !== null) {
            const resultsCards = resultsActiveCard.parentElement.querySelectorAll(
                '.results-card__content-container',
            );

            if (resultsCards.length > 0) {
                resultsCards.forEach(resultsCard => {
                    resultsCard.classList.add('u-hidden');
                });
            }

            resultsActiveCard.classList.remove('u-hidden');
            const dropdownText = resultsActiveCard.querySelector(
                '.dropdown__button .dropdown__text',
            );
            const dropdownOptionTexts = resultsActiveCard.querySelectorAll(
                '.dropdown__options .dropdown__text',
            );
            if (dropdownOptionTexts.length > 0 && dropdownText !== null) {
                dropdownText.innerHTML = `${option.title}`;
                dropdownOptionTexts.forEach(dropdownOptionText => {
                    if (dropdownOptionText.innerHTML === option.title) {
                        dropdownOptionText.parentElement.classList.add(
                            'dropdown__item--active',
                        );
                    } else {
                        dropdownOptionText.parentElement.classList.remove(
                            'dropdown__item--active',
                        );
                    }
                });
            }
            resultsActiveCard.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleUpgradeAccessSelection = option => {
        const resultsActiveCard = document.querySelector(
            '.results-card__content-container:not(.u-hidden)',
        );
        if (resultsActiveCard !== null) {
            const purchasePrice = resultsActiveCard.querySelector(
                '[data-target="purchase-price"]',
            );
            const purchaseButton = resultsActiveCard.querySelector(
                '[data-target="purchase-button"]',
            );
            if (purchasePrice !== null) {
                purchasePrice.innerHTML = `${option.changeValue}`;
            }

            if (purchaseButton !== null) {
                purchaseButton.dataset.variation = `${option.id}`;
            }
        }
    };

    const handleUpgradeLanguageSelection = option => {
        const resultsActiveCard = document.querySelector(
            `.results-card__content-container[data-id="${option.parentId}"]`,
        );
        if (resultsActiveCard !== null) {
            const purchasePrice = resultsActiveCard.querySelector(
                '[data-target="purchase-price"]',
            );
            const purchaseButton = resultsActiveCard.querySelector(
                '[data-target="purchase-button"]',
            );
            if (purchasePrice !== null) {
                purchasePrice.innerHTML = `${option.changeValue}`;
            }

            if (purchaseButton !== null) {
                purchaseButton.dataset.variation = `${option.id}`;
            }
        }
    };

    const handleNhsModal = () => {
        const modal = document.querySelector(
            '[data-behaviour="modal"][data-target="questionnaire-modal"]',
        );

        if (modal !== null) {
            modal.classList.add('modal--active');
            document.body.classList.add('u-lock-scrolling');
        }
    };

    const handleAddToCart = async (id, variationId, couponCode = '') => {
        setLoading(true);

        const formId = document.cookie.match('(^|;)\\s*questionnaire-form-id\\s*=\\s*([^;]+)')?.pop() || '';
        const entryId = document.cookie.match('(^|;)\\s*questionnaire-entry-id\\s*=\\s*([^;]+)')?.pop() || '';

        if (form != '' && formId == '' && entryId == '') {
            const productId = variationId != 0 ? variationId : id;
            pushHistory('form_id', form);
            pushHistory('product_id', variationId != 0 ? variationId : id);
            pushHistory('coupon_code', couponCode);

            if (document.body.classList.contains('logged-in')) {
                window.location.href = `${wp_ajax.home_url}/questionnaire/?form_id=${form}&product_id=${productId}&coupon_code=${couponCode}`;
            } else {
                handleNhsModal();
            }
        } else {
            const quantity =
            bookingState.sessionCount && bookingState.sessionCount.value > 1
                ? bookingState.sessionCount.value
                : 1;
            const cartStatus = await addToCart(id, quantity, variationId, bookingState?.upsell, false, couponCode);

            if (cartStatus.status === 'error') {
                if (!cartStatus.inStock) {
                    setShowComponent(false);
                }

                if (typeof handleErrorMessages === 'function') {
                    handleErrorMessages(cartStatus.message);
                }
            } else {
                window.location.href = `${wp_ajax.home_url}checkout/`;
            }
        }

        setLoading(false);
    };

    useEffect(() => {
        updateTag(item);

        if (item.time || item.group || item.seminarLocation) {
            setShowIconList(true);
        }
    }, []);

    const resultsCardTitle = cardItem => {
        let classes = 'results-card__title';
        let title = '';

        if (type === 'ovp' || cardItem.voucher) {
            title += cardItem.display_title;
        } else {
            if (bookingState.addiction) {
                title += `${bookingState.addiction.current.display_name}`;
            }

            if (type === 'onetoone' && preferType === 'online') {
                if (title !== '') {
                    title += ' ';
                }

                title += 'Live Online';
            }

            if (type === 'onetoone') {
                if (title !== '') {
                    title += ' ';
                }

                title += 'One-to-One Seminar';
            } else if (type === 'taster') {
                if (title !== '') {
                    title += ' ';
                }

                title += 'Seminar Taster';
            } else {
                if (title !== '') {
                    title += ' ';
                }
                title += 'Seminar';
            }

            if (type === 'onetoone' && preferType === 'onetoone') {
                if (title !== '') {
                    title += ' ';
                }

                title += 'at Home';
            }
        }

        if (cardItem.saleEndDate && cardItem.saleEndDate !== '') {
            classes += ' results-card__title--no-margin-bottom';
        }

        if (titleOverride && titleOverride.length > 0) {
            title = titleOverride;
        }

        return <h3 className={classes}>{title}</h3>;
    };

    const getDefaultLanguage = () => {
        let defaultLanguage = '';
        const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.get('language')) {
            defaultLanguage = parseInt(searchParams.get('language'));
        }

        return defaultLanguage;
    };

    const earlyBirdDiscountTimer = cardItem => {
        return (
            cardItem.saleEndDate &&
            cardItem.saleEndDate !== '' &&
            !cardItem.hideSaleTimer && (
                <>
                    {bookingState.data.early_bird_discount && (
                        <h4 className="results-card__subtitle">
                            {bookingState.data.early_bird_discount.title}
                        </h4>
                    )}
                    <CountdownTimer date={cardItem.saleEndDate} />
                </>
            )
        );
    };

    const resultsCardContent = cardItem => {
        if (cardItem.voucher) {
            return (
                <>
                    {earlyBirdDiscountTimer(cardItem)}
                    {/* From Price */}
                    {cardItem.price && <FromPrice price={cardItem.price} />}
                    {/* Summary Description */}
                    {cardItem.description && (
                        <div className="results-card__desc">
                            {cardItem.description}
                        </div>
                    )}
                    {/* Summary cardItems */}
                    {cardItem.whats_included && (
                        <Summary
                            items={cardItem.whats_included}
                            hideTitle={true}
                        />
                    )}
                    {cardItem.upgrade_language &&
                        cardItem.upgrade_language.length > 0 && (
                            <Dropdown
                                title="Language"
                                options={cardItem.upgrade_language}
                                handleSelection={handleUpgradeLanguageSelection}
                                supressEvent={true}
                            />
                        )}
                </>
            );
        }

        switch (type) {
            case 'group':
                return (
                    <>
                        {earlyBirdDiscountTimer(cardItem)}
                        {/* Limited Spaced Tag */}
                        {cardItem.isLowStock && (
                            <span className="results-card__limited-spaces">
                                {'Last remaining spaces'}
                            </span>
                        )}
                        {/* Icon List */}
                        {showIconList && <IconList item={cardItem} />}
                        {/* Summary cardItems */}
                        {cardItem.whats_included && (
                            <Summary items={cardItem.whats_included} />
                        )}
                    </>
                );
                break;
            case 'live-group':
            case 'taster':
                return (
                    <>
                        {earlyBirdDiscountTimer(cardItem)}
                        {/* Icon List */}
                        {showIconList && <IconList item={cardItem} />}
                        {/* Summary cardItems */}
                        {cardItem.whats_included && (
                            <Summary items={cardItem.whats_included} />
                        )}
                    </>
                );
                break;
            case 'onetoone':
                const oneToOneSession =
                    bookingState.session &&
                    bookingState.session.current.key !== 'onetoone'
                        ? bookingState.session.current.key
                        : 'home';
                return (
                    <>
                        {earlyBirdDiscountTimer(cardItem)}
                        {/* From Price */}
                        {cardItem.price && <FromPrice price={cardItem.price} />}
                        {/* Summary Description */}
                        {cardItem.description && (
                            <div className="results-card__desc">
                                {cardItem.description}
                            </div>
                        )}
                        {/* Summary cardItems */}
                        {cardItem.whats_included && (
                            <Summary
                                items={cardItem.whats_included}
                                hideTitle={true}
                            />
                        )}
                        {/* MoneyBack Guarantee */}
                        {bookingState.data.money_back_guarantee && (
                            <MoneyBackGuarantee
                                title={
                                    bookingState.data.money_back_guarantee.title
                                }
                                icon={<MoneyBackGuarantee90Icon />}
                                text={
                                    bookingState.data.money_back_guarantee.text
                                }
                            />
                        )}
                        {/* FORM */}
                        {cardItem.enquiry_form && (
                            <Form
                                formId={Number(cardItem.enquiry_form)}
                                hasBorder={false}
                                showTag={false}
                            />
                        )}
                    </>
                );
                break;
            case 'ovp':
                return (
                    <>
                        {earlyBirdDiscountTimer(cardItem)}
                        {/* From Price */}
                        {cardItem.price && <FromPrice price={cardItem.price} />}
                        {/* Summary Description */}
                        {cardItem.description && (
                            <div className="results-card__desc">
                                {cardItem.description}
                            </div>
                        )}
                        {/* Summary cardItems */}
                        {cardItem.whats_included && (
                            <Summary
                                items={cardItem.whats_included}
                                hideTitle={true}
                            />
                        )}
                        {/* MoneyBack Guarantee */}
                        {cardItem.money_back_guarantee &&
                            'text' in cardItem.money_back_guarantee &&
                            cardItem.money_back_guarantee.text !== '' && (
                                <MoneyBackGuarantee
                                    title={cardItem.money_back_guarantee.title}
                                    icon={<MoneyBackGuarantee90Icon />}
                                    text={cardItem.money_back_guarantee.text}
                                />
                            )}
                        {cardItem.featured_testimonial &&
                            'quote' in cardItem.featured_testimonial &&
                            cardItem.featured_testimonial.quote !== '' && (
                                <TestimonialCard
                                    quote={cardItem.featured_testimonial.quote}
                                    image={cardItem.featured_testimonial.image}
                                    author={
                                        cardItem.featured_testimonial.author
                                    }
                                />
                            )}
                        {/* Also fetch from the parent */}
                        {item.languages && item.languages.length > 0 && (
                            <Dropdown
                                title="Language"
                                options={item.languages}
                                handleSelection={handleLanguageSelection}
                                defaultValue={getDefaultLanguage()}
                            />
                        )}
                        {cardItem.upgrade_access &&
                            cardItem.upgrade_access.length > 0 && (
                                <Dropdown
                                    title="Upgrade number of days access"
                                    options={cardItem.upgrade_access}
                                    handleSelection={
                                        handleUpgradeAccessSelection
                                    }
                                    supressEvent={true}
                                />
                            )}
                    </>
                );
                break;

            default:
                break;
        }
    };

    const resultsCard = (cardItem, index) => {
        const quantity =
            bookingState.sessionCount && bookingState.sessionCount.value > 1
                ? bookingState.sessionCount.value
                : 1;

        return (
            <div
                key={'results-card-' + index}
                data-id={cardItem.voucher ? item.id : cardItem.id}
                className={
                    'results-card__content-container' +
                    (cardItem.voucher ||
                    type === 'group' ||
                    type === 'live-group' ||
                    type === 'ovp' ||
                    type === 'onetoone' ||
                    type === 'taster'
                        ? ' results-card__content-container--full'
                        : '')
                }>
                <div
                    className={
                        'results-card__content' +
                        ` results-card__content--${type}`
                    }>
                    {resultsCardTitle(cardItem)}
                    {resultsCardContent(cardItem)}
                </div>
                {/* PRICE */}
                {!cardItem.voucher &&
                    (type === 'group' ||
                        type === 'live-group' ||
                        type === 'taster') &&
                    cardItem.price &&
                    cardItem.id && !loading && (
                        <Price
                            price={cardItem.price}
                            originalPrice={cardItem.regularPrice}
                            itemID={cardItem.id}
                            quantity={quantity}
                            couponCode={couponCode}
                            priceOverride={priceOverride}
                            overrideText={overrideText}
                            overrideOriginalText={overrideOriginalText}
                            upsellCouponCode={cardItem.upsell_coupon_code}
                            buttonUrl={
                                cardItem.booking_link_external
                                    ? cardItem.booking_link
                                    : ''
                            }
                            onClick={
                                cardItem.booking_link_external
                                    ? false
                                    : handleAddToCart
                            }
                        />
                    )}
                {!cardItem.voucher &&
                    type === 'ovp' &&
                    cardItem.price &&
                    cardItem.id && !loading && (
                        <Price
                            price={
                                'upgrade_access' in cardItem
                                    ? cardItem.upgrade_access[0].price
                                    : cardItem.price
                            }
                            originalPrice={
                                'upgrade_access' in cardItem
                                    ? cardItem.upgrade_access[0].regularPrice
                                    : cardItem.regularPrice
                            }
                            itemID={cardItem.id}
                            itemVariationID={
                                'upgrade_access' in cardItem
                                    ? cardItem.upgrade_access[0].id
                                    : 0
                            }
                            quantity={quantity}
                            couponCode={couponCode}
                            priceOverride={priceOverride}
                            overrideText={overrideText}
                            overrideOriginalText={overrideOriginalText}
                            upsellCouponCode={cardItem.upsell_coupon_code}
                            buttonText="Buy now"
                            fullWidth={true}
                            buttonUrl={
                                cardItem.booking_link_external
                                    ? cardItem.booking_link
                                    : ''
                            }
                            onClick={
                                cardItem.booking_link_external
                                    ? false
                                    : handleAddToCart
                            }
                        />
                    )}
                {cardItem.voucher && cardItem.price && cardItem.id && !loading && (
                    <Price
                        price={
                            'upgrade_language' in cardItem
                                ? cardItem.upgrade_language[0].price
                                : cardItem.price
                        }
                        originalPrice={
                            'upgrade_language' in cardItem
                                ? cardItem.upgrade_language[0].regularPrice
                                : cardItem.regularPrice
                        }
                        itemID={cardItem.id}
                        itemVariationID={
                            'upgrade_language' in cardItem
                                ? cardItem.upgrade_language[0].id
                                : 0
                        }
                        quantity={quantity}
                        priceOverride={priceOverride}
                        overrideText={overrideText}
                        overrideOriginalText={overrideOriginalText}
                        couponCode={couponCode}
                        upsellCouponCode={cardItem.upsell_coupon_code}
                        buttonText="Buy now"
                        fullWidth={true}
                        buttonUrl={
                            cardItem.booking_link_external
                                ? cardItem.booking_link
                                : ''
                        }
                        onClick={
                            cardItem.booking_link_external
                                ? false
                                : handleAddToCart
                        }
                    />
                )}
            </div>
        );
    };

    // Upsell creates a coupon on the fly once added to basket so will need to calculate the price now
    if (
        type === 'live-group' &&
        bookingState.upsell &&
        item.price === item.regularPrice
    ) {
        let newPrice = parseFloat(item.price.replace(/[^\d\.]/g, ''));
        const discount = bookingState.upsellDiscount ?? 10;
        const discountPrice = newPrice * (parseFloat(discount) / 100);
        newPrice -= discountPrice;

        item.price = item.price.charAt(0) + newPrice.toFixed(2);
    }

    if (!showComponent) return <></>;

    return (
        <div className="results-card">
            {tag.text && tag.icon && <Tag icon={tag.icon} text={tag.text} />}
            {(type !== 'ovp' || (type === 'ovp' && item.voucher)) &&
                resultsCard(item)}
            {type === 'ovp' &&
                !item.voucher &&
                item.children &&
                item.children.length > 0 &&
                item.children.map((child, index) => resultsCard(child, index))}
            {type === 'ovp' &&
                !item.voucher &&
                bookingState.data.upsell &&
                bookingState.addiction &&
                bookingState.addiction.sessionTypesAvailable.includes(
                    'live-group',
                ) && (
                    <Upsell
                        label={bookingState.data.upsell.label}
                        title={bookingState.data.upsell.title}
                        text={bookingState.data.upsell.text}
                        buttonText={bookingState.data.upsell.button_text}
                        onClick={handleUpsell}
                    />
                )}
            {loading && <LoadingIcon />}
        </div>
    );
};

ResultsCard.proptypes = proptypes;
export default ResultsCard;
