import React, { useState, useContext, forwardRef } from "react";
import { BookingContext } from '../Context/BookingContext';
import PropTypes from "prop-types";
import DatePicker , { registerLocale } from "react-datepicker";
import ResultsFound from "../Results/ResultsFound";
//Localisation
import enGB from "date-fns/locale/en-GB";
registerLocale("en-GB", enGB);

import "react-datepicker/dist/react-datepicker.css";

const proptypes = {
    handleDateSelection: PropTypes.func.isRequired,
    availableAppointments: PropTypes.number.isRequired,
};

const DateSelection = ({ handleDateSelection, availableAppointments }) => {

    const [bookingState, setBookingState] = useContext(BookingContext);
    const [fromStartDate, setFromStartDate] = useState(new Date());
    const [endStartDate, setEndStartDate] = useState(new Date());

    const DatePickerInput = forwardRef(({ value, onClick, placeholder }, ref) => (
        <div className="date-selection__input" onClick={onClick} ref={ref}>
            <span className="material-icons material-icons-outlined date-selection__icon">event</span>
            <span className="date-selection__value">{ value ? value : placeholder}</span>
        </div>
    ));

    const datePickerDateChange = (date, type) => {
        let key = 'startDate';

        switch (type) {
            case 'from':
                setFromStartDate(date);
                // @todo sort out date end date when start date is picked 
                // setEndStartDate(new Date(date.setTime( date.getTime() + 5 * 86400000 )))
                break;
            case 'to':
                setEndStartDate(date);
                key = 'endDate';
                break;
        }

        handleDateSelection(key, date);
    }

    return (
        <div className="date-selection">
            <label>
                <span className="date-selection__label">From</span>
                <DatePicker
                    selected={fromStartDate}
                    onChange={(date) => datePickerDateChange(date, 'from')}
                    className="date-selection__picker"
                    customInput={<DatePickerInput />}
                    locale="en-GB"
                    dateFormat="dd/MM/yyyy"
                    popperPlacement="bottom-start"
                />
            </label>
            <label>
                <span className="date-selection__label">To</span>
                <DatePicker
                    selected={endStartDate}
                    onChange={(date) => datePickerDateChange(date, 'to')}
                    className="date-selection__picker"
                    placeholderText="Select"
                    locale="en-GB"
                    dateFormat="dd/MM/yyyy"
                    customInput={<DatePickerInput />}
                    minDate={fromStartDate}
                    // openToDate={fromStartDate}
                    popperClassName="date-selection--pull-left"
                    popperPlacement="bottom-start"
                />
            </label>
            <ResultsFound
                count={availableAppointments}
                label={bookingState.sessionType.current.key === 'ovp' ? 'OVP' : 'Seminar'}
            />
        </div>
    )
}

DateSelection.propTypes = proptypes;

export default DateSelection;