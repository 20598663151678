import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const proptypes = {
    question: PropTypes.string.isRequired, //'live-seminar', 'ovp' etc.
    answer: PropTypes.object.isRequired,
};

const FAQItem = ({ question, answer, defaultOpen = true }) => {
    const [open, setOpen] = useState(defaultOpen);
    const excerpt = answer.excerpt ?? null;
    const content = answer.content ?? null;

    const showFAQ = () => setOpen(!open);

    return (
        <div className="faq-item">
            <div className="faq-item__heading" onClick={showFAQ}>
                <h4 className="faq-item__title">{question}</h4>
                <span
                    className={
                        'material-icons' + (open ? ' u-rotate-180' : '')
                    }>
                    expand_more
                </span>
            </div>
            <div
                className={
                    'faq-item-content' + (open ? ' faq-item-content--open' : '')
                }>
                <div
                    className="faq-item__inner-content"
                    dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
        </div>
    );
};

FAQItem.propTypes = proptypes;

export default FAQItem;
