import { pushHistory } from "./utilities";

/* eslint-disable max-len */
const tabs = document.querySelectorAll('[data-behaviour="tabs"]');
if (tabs.length >= 1) {
    tabs.forEach((tabEle) => {
        const allTabs = tabEle.querySelectorAll('[data-behaviour="tab"]');
        allTabs.forEach((tab) => {
            tab.addEventListener('click', (event) => {
                event.preventDefault();

                if (!tab.classList.contains('tabs__tab-panel--selected')) {
                    // clean up any already selected tabs
                    const currentPanels = tabEle.querySelectorAll('[data-behaviour="tab-panel"]');
                    currentPanels.forEach((cur) => {
                        if (cur.classList.contains('tabs__content-panel--selected')) {
                            cur.classList.remove('tabs__content-panel--selected');
                        }
                    });
                    allTabs.forEach((cur) => {
                        if (cur.classList.contains('tabs__tab-panel--selected')) {
                            cur.classList.remove('tabs__tab-panel--selected');
                        }
                    });
                    // find the panel and add modifier
                    const content = tabEle.querySelectorAll(`[data-val="${tab.dataset.target}"]`);
                    content.forEach((con) => {
                        con.classList.add('tabs__content-panel--selected');
                    });
                    tab.classList.add('tabs__tab-panel--selected');
                }
            });
        });
    });
}

// https://codepen.io/callumbw95/pen/RwPKQNY
(window => {
    const tabActive = "tabs__tab--active";
    const contentActive = "tabs__content--active";
    const getContainer = target => target.closest("[data-tabs]").dataset.tabs;
    const resetTabs = target =>
      [...document.querySelectorAll(`[data-tabs="${getContainer(target)}"] [data-tabs-link]`)].map(tab =>
        tab.classList.remove(tabActive)
      );
    const resetContent = target =>
      [...document.querySelectorAll(`[data-tabs="${getContainer(target)}"] [data-tabs-content]`)].map(tab =>
        tab.classList.remove(contentActive)
      );
    const selectTab = target => !target.classList.add(tabActive);
    const selectContent = (target, tid) => {
      document
        .querySelector(`[data-tabs="${getContainer(target)}"] [data-tabs-content="${tid}"]`)
        .classList.add(contentActive);
      pushHistory('current-tab', tid);
    };

    return window.addEventListener(
      "click",
      ({ target: { dataset }, target }) =>
        dataset.tabsLink &&
        resetTabs(target) &&
        resetContent(target) &&
        selectTab(target) &&
        selectContent(target, dataset.tabsLink)
    );
  })(window);
  