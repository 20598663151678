import React, { useState } from "react";

const Checkbox = ({ title, id, onChange, initialChecked = false }) => {
    const [checked, setChecked] = useState(initialChecked);
    return (
        <div className="checkbox">
            <input
                type="checkbox"
                name={title.toLowerCase()}
                value={id}
                defaultChecked={checked}
                id={'checkbox_' + title.toLowerCase()}
                onChange={(event) => {
                    onChange(event)
                    setChecked(!checked);
                }}
            />
            <label htmlFor={'checkbox_' + title.toLowerCase()}>{title}</label>
        </div>
    )
}
export default Checkbox