const tabletBP = 768;
const breadcrumb = document.querySelector('nav.breadcrumbs span');

if (breadcrumb) {
    window.addEventListener('load', () => {
        if (document.documentElement.clientWidth < tabletBP) {
            breadcrumb.lastChild.classList.add('breadcrumb-mobile-show');
        }
    });
    window.addEventListener('resize', () => {
        if(document.documentElement.clientWidth >= tabletBP) {
            breadcrumb.lastChild.classList.remove('breadcrumb-mobile-show');
        } else {
            breadcrumb.lastChild.classList.add('breadcrumb-mobile-show');
        }
    });
}

