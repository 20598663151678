import { addVideoProgramViewLog } from './utilities';

const ovpTrackingVideos = document.querySelectorAll(
    '[data-behaviour*="ovp-tracking-video"]',
);
if (ovpTrackingVideos.length > 0) {
    const vimeoVideoHandleStop = (
        productId,
        videoId,
        orderId,
        userId,
        corporateId,
        data,
    ) => {
        const key = `ovp-tracking-video-product=${productId}-video=${videoId}-order=${orderId}-user=${userId}-corporate=${corporateId}`;
        const currentDate = new Date().toISOString();
        const percent = data.percent * 100;

        if (localStorage.getItem(`${key}-start`) === null) {
            localStorage.setItem(`${key}-start`, currentDate);
        }
        localStorage.setItem(`${key}-percent`, percent);
        localStorage.setItem(`${key}-time`, data.seconds);
        localStorage.setItem(`${key}-end`, currentDate);
    };

    const vimeoVideoUpdateTime = (
        vimeoVideo,
        productId,
        videoId,
        orderId,
        userId,
        corporateId,
        timeWatched
    ) => {
        const key = `ovp-tracking-video-product=${productId}-video=${videoId}-order=${orderId}-user=${userId}-corporate=${corporateId}`;
        let time = localStorage.getItem(`${key}-time`);

        if (time === null && parseFloat(timeWatched) > 0) {
            time = timeWatched;
            localStorage.setItem(`${key}-time`, timeWatched);
        }

        if (parseFloat(time) > 0) {
            vimeoVideo.src += `#t=${time}s`;
        }
    };

    const saveVideoProgramViewLog = (
        productId,
        videoId,
        orderId,
        userId,
        corporateId,
    ) => {
        const key = `ovp-tracking-video-product=${productId}-video=${videoId}-order=${orderId}-user=${userId}-corporate=${corporateId}`;
        addVideoProgramViewLog(
            productId,
            videoId,
            orderId,
            userId,
            localStorage.getItem(`${key}-percent`),
            localStorage.getItem(`${key}-time`),
            localStorage.getItem(`${key}-start`),
            localStorage.getItem(`${key}-end`),
            corporateId,
        );
    };

    ovpTrackingVideos.forEach(ovpTrackingVideo => {
        ovpTrackingVideo.delayInterval;
        const vimeoVideo = ovpTrackingVideo.querySelector(
            'iframe[class^="vimeo_"]',
        );
        if (vimeoVideo !== null) {
            vimeoVideoUpdateTime(
                vimeoVideo,
                ovpTrackingVideo.dataset.productId,
                ovpTrackingVideo.dataset.videoId,
                ovpTrackingVideo.dataset.orderId,
                ovpTrackingVideo.dataset.userId,
                ovpTrackingVideo.dataset.corporateId,
                ovpTrackingVideo.dataset.timeWatched,
            );

            window.addEventListener("accordion-close", (e) => {
                if (vimeoVideo.vimeoPlayer) {
                    vimeoVideo.vimeoPlayer.pause();
                }
            },false);

            vimeoVideo.addEventListener('load', () => {
                if (typeof Vimeo !== 'undefined') {
                    vimeoVideo.vimeoPlayer = new Vimeo.Player(vimeoVideo);
                    vimeoVideo.vimeoPlayer.setLoop(false);

                    vimeoVideo.vimeoPlayer.on('timeupdate', data => {
                        if (data !== 'undefined') {
                            vimeoVideoHandleStop(
                                ovpTrackingVideo.dataset.productId,
                                ovpTrackingVideo.dataset.videoId,
                                ovpTrackingVideo.dataset.orderId,
                                ovpTrackingVideo.dataset.userId,
                                ovpTrackingVideo.dataset.corporateId,
                                data,
                            );
                        }
                    });

                    vimeoVideo.vimeoPlayer.on('play', data => {
                        if (data !== 'undefined') {
                            vimeoVideoHandleStop(
                                ovpTrackingVideo.dataset.productId,
                                ovpTrackingVideo.dataset.videoId,
                                ovpTrackingVideo.dataset.orderId,
                                ovpTrackingVideo.dataset.userId,
                                ovpTrackingVideo.dataset.corporateId,
                                data,
                            );
    
                            saveVideoProgramViewLog(
                                ovpTrackingVideo.dataset.productId,
                                ovpTrackingVideo.dataset.videoId,
                                ovpTrackingVideo.dataset.orderId,
                                ovpTrackingVideo.dataset.userId,
                                ovpTrackingVideo.dataset.corporateId,
                            );
                        }
                    });

                    vimeoVideo.vimeoPlayer.on('seeked', data => {
                        // Need to account for video ending and seeking to the start
                        if (data !== 'undefined' && data.percent > 0) {
                            vimeoVideoHandleStop(
                                ovpTrackingVideo.dataset.productId,
                                ovpTrackingVideo.dataset.videoId,
                                ovpTrackingVideo.dataset.orderId,
                                ovpTrackingVideo.dataset.userId,
                                ovpTrackingVideo.dataset.corporateId,
                                data,
                            );

                            ovpTrackingVideo.delayInterval = saveVideoProgramViewLog(
                                ovpTrackingVideo.dataset.productId,
                                ovpTrackingVideo.dataset.videoId,
                                ovpTrackingVideo.dataset.orderId,
                                ovpTrackingVideo.dataset.userId,
                                ovpTrackingVideo.dataset.corporateId,
                            );
                        }
                    });

                    vimeoVideo.vimeoPlayer.on('pause', data => {
                        if (data !== 'undefined') {
                            vimeoVideoHandleStop(
                                ovpTrackingVideo.dataset.productId,
                                ovpTrackingVideo.dataset.videoId,
                                ovpTrackingVideo.dataset.orderId,
                                ovpTrackingVideo.dataset.userId,
                                ovpTrackingVideo.dataset.corporateId,
                                data,
                            );

                            saveVideoProgramViewLog(
                                ovpTrackingVideo.dataset.productId,
                                ovpTrackingVideo.dataset.videoId,
                                ovpTrackingVideo.dataset.orderId,
                                ovpTrackingVideo.dataset.userId,
                                ovpTrackingVideo.dataset.corporateId,
                            );
                        }
                    });

                    vimeoVideo.vimeoPlayer.on('ended', data => {
                        if (data !== 'undefined') {
                            vimeoVideoHandleStop(
                                ovpTrackingVideo.dataset.productId,
                                ovpTrackingVideo.dataset.videoId,
                                ovpTrackingVideo.dataset.orderId,
                                ovpTrackingVideo.dataset.userId,
                                ovpTrackingVideo.dataset.corporateId,
                                data,
                            );

                            saveVideoProgramViewLog(
                                ovpTrackingVideo.dataset.productId,
                                ovpTrackingVideo.dataset.videoId,
                                ovpTrackingVideo.dataset.orderId,
                                ovpTrackingVideo.dataset.userId,
                                ovpTrackingVideo.dataset.corporateId,
                            );
                        }
                    });
                }
            });
        }
    });
}
