import {
    validatePostcode,
    refreshTicketUserFields,
} from './utilities';
import { defaultObject } from '../components/Booking/Context/BookingDefaultData';

const forms = document.querySelectorAll('[data-behaviour*="validate-form"]');
for (const form of forms) {
    const toggleShippingField = form.querySelector( '[data-behaviour*="toggle-shipping-fields"]');
    if (toggleShippingField !== null) {
        document.addEventListener('DOMContentLoaded', () => {
            toggleShippingFieldChange(toggleShippingField);
        });

        toggleShippingField.addEventListener('change', () => {
            toggleShippingFieldChange(toggleShippingField);
        }); 
    }

    initFieldValidation(form);
}

/**
 * Init the field valididation
 * @param {object} form
 * @returns
 */
function initFieldValidation(form) {
    form.addEventListener('submit', () => {
        let fields = [
            ...form.querySelectorAll('[data-behaviour*="validate-field"]')
        ];
        if (fields.length > 0) {
            for (const field of fields) {
                validateField(field, form);
            }
        }
    });

    document.addEventListener('DOMContentLoaded', () => {
        trackPersonalDetailsCompleted(form);
    });

    /**
     * clear timeout on keydown so it doesnt fire repeatedly
     */
    let timeout;
    document.addEventListener('keydown', event => {
        if (
            event.target.matches('[data-behaviour]') &&
            event.target.matches('[data-behaviour*="validate-field"]')
        ) {
            clearTimeout(timeout);

            /**
             * event code on Iphone or return to go to the next sibling
             */
            if (event.keyCode == 13) {
                event.preventDefault();

                syncBillingToShippingField(event.target, form);
                validateField(event.target, form);
                trackPersonalDetailsCompleted(form);

                const parent = event.target?.closest('.form-row');
                const sibling = parent?.nextElementSibling;

                if (parent && sibling) {
                    const input = sibling.querySelector('input');
                    if (input !== null) {
                        input.focus();
                    }
                }
            }
        }
    });

    const triggers = ['keyup', 'change', 'paste'];
    for (const trigger of triggers) {
        document.addEventListener(trigger, (event) => {
            if (
                event.target.matches('[data-behaviour]') &&
                event.target.matches('[data-behaviour*="validate-field"]')
            ) {
                clearTimeout(timeout);
                setTimeout(() => {
                    syncBillingToShippingField(event.target, form);
                    validateField(event.target, form);
                    trackPersonalDetailsCompleted(form);
                }, 250);
            }
        });
    }

    /**
     * has to be jQuery as select2 does not populate select with selected option
     */
    if (typeof jQuery != 'undefined' && jQuery !== null) {
        /**
         * on country change remove and update required fields
         */
        jQuery(document).on('change', '.country_select', async (event) => {
            const formRow = event.target.closest('[data-validate-row]');
            if (
                event.target.matches('[data-behaviour]') &&
                event.target.matches('[data-behaviour*="validate-field"]') &&
                formRow !== null
            ) {
                let rowLabel = 'validateRow' in formRow.dataset
                    ? formRow.dataset.validateRow
                    : '';
                if (rowLabel == 'ticket') {
                    rowLabel = 'shipping';
                }

                // Need to refresh the available fields when the country changes
                if (
                    'productId' in event.target.dataset &&
                    'personId' in event.target.dataset
                ) {
                    const ticketForm = formRow.querySelector(
                        `[data-refresh-fields="shipping_extra_${event.target.dataset.productId}_${event.target.dataset.personId}"]`
                    );
                    if (ticketForm !== null) {
                        const ticketFields = [
                            ...ticketForm.querySelectorAll('input, select')
                        ];
                        let ticketFieldsValues = {};
                        if (ticketFields.length > 0) {
                            for (const ticketField of ticketFields) {
                                ticketFieldsValues[ticketField.name] = ticketField.value;
                            }
                        }

                        const ticketFormHtml = await refreshTicketUserFields(
                            event.target.value,
                            event.target.dataset.productId,
                            event.target.dataset.personId,
                            ticketFieldsValues
                        );
                        if (ticketFormHtml !== '') {
                            // We need to destory the select2 dropdowns first so they can be rebound
                            jQuery(
                                'select.country_select:visible, select.state_select:visible',
                            ).selectWoo('destroy');

                            ticketForm.innerHTML = ticketFormHtml;

                            // Need to re-init the select2 dropdowns
                            jQuery(
                                'select.country_select:visible, select.state_select:visible'
                            ).selectWoo();
                        }
                    }
                }

                // As the address fields are modifed we need to revalidate
                let fields = [
                    ...formRow.querySelectorAll('[data-behaviour*="validate-field"]')
                ];
                if (fields.length > 0) {
                    for (const field of fields) {
                        validateField(field, form);
                    }
                }
            }
        });
    }
}

/**
 * Validate a Field
 * @param {object} input
 * @param {object} form
 * @returns
 */
function validateField(input, form) {
    // for debouncer just incase it hits early
    if (input.classList.contains('input-validating')) {
        return;
    }
    input.classList.add('input-validating');

    const formRow = input.closest('[data-validate-row]');

    let rowLabel = 'validateRow' in formRow.dataset
        ? formRow.dataset.validateRow
        : '';
    if (rowLabel == 'ticket') {
        rowLabel = 'shipping';
    }

    const country = formRow.querySelector(
        `[data-validate-field="${rowLabel}_country"]`,
    );

    let inputValid = true;
    let errorHtml = '';
    let inputName =
        'validateField' in input.dataset
            ? input.dataset.validateField
            : input.name;

    let keyName = inputName.replace(/_/g, ' ');
    keyName = keyName.charAt(0).toUpperCase() + keyName.slice(1);

    let countryData =
        country !== null && country.value in defaultObject.countryData
            ? defaultObject.countryData[country.value]
            : {};

    // postcode
    if (inputName.includes('postcode')) {
        let postcodeProp = true;
        if ('postcode' in countryData) {
            postcodeProp =
                'required' in countryData.postcode
                    ? countryData.postcode.required
                    : true;
        }
        input.setAttribute('data-validate-required', postcodeProp);
        input.setAttribute('data-validate-required-state', postcodeProp);
    }
    //city
    if (inputName.includes('city')) {
        let cityProp = true;
        if ('city' in countryData) {
            cityProp =
                'required' in countryData.city
                    ? countryData.city.required
                    : true;
        }
        input.setAttribute('data-validate-required', cityProp);
        input.setAttribute('data-validate-required-state', cityProp);
    }

    // We need to overide the validate required if billing toggle is set
    let skipValidation = false;
    const toggleShippingField = document.querySelector(
        '[data-behaviour*="toggle-shipping-fields"]'
    );
    if (toggleShippingField !== null) {
        if (
            'validateRow' in formRow.dataset &&
            formRow.dataset.validateRow === 'shipping'
        ) {
            skipValidation = !toggleShippingField.checked;
        }
    }

    // convert dataset-validate-required to boolean
    const inputRequired =
        input.getAttribute('data-validate-required') === 'true';

    if (inputRequired && !skipValidation) {
        switch (input.type) {
            case 'checkbox':
                inputValid = input.checked;
                break;
            case 'radio':
                // Need to check if at least one of the radio is checked if more than one
                inputValid =
                    formRow.querySelectorAll(
                        `input[name="${input.name}"]:checked`
                    ).length > 0;
                break;
            case 'select':
            case 'select-one':
                inputValid = input.selectedIndex in input.options && 
                    input.options[input.selectedIndex].value !== '';
                break;
            default:
                inputValid = input.value !== '';
                break;
        }

        if (!inputValid) {
            errorHtml = `Required field`;
        }
    }

    // No need to continue if failed due to required field
    if (inputValid && !skipValidation) {
        switch (input.type) {
            case 'email':
                inputValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.value);
                errorHtml = `Invalid email address`;
                break;
            case 'number':
                inputValid = input.value == parseFloat(input.value);
                errorHtml = `Invalid number`;
                break;
            case 'tel':
                // This matches the backend validation WC_Validation::is_phone
                inputValid = input.value.replaceAll(/[\s\#0-9_\-\+\/\(\)\.]/g, '').length <= 0;
                errorHtml = `Invalid phone number`;
                break;
            case 'text':
                if (input.autocomplete === 'postal-code' && country !== null) {
                    let postcodeHidden = false;
                    if ('postcode' in countryData) {
                        postcodeHidden =
                            'hidden' in countryData.postcode
                                ? countryData.postcode.hidden
                                : false;
                    }

                    if (!postcodeHidden) {
                        // This matches the backend validation WC_Validation::is_postcode
                        inputValid = validatePostcode(input.value, country.value);
                        errorHtml = `Invalid postcode / ZIP.`;
                    }
                }
            default:
                break;
        }

        // Now we can check for the duplicate name as all other validation has passed
        if (
            inputValid &&
            !skipValidation &&
            'validateDuplicateName' in input.dataset
        ) {
            const nameField = document.querySelector(
                `[data-behaviour="validate-form"] [name="${input.dataset.validateDuplicateName}"]`,
            );
            if (nameField !== null) {
                let inputValue = input.value;
                let duplicateInputValue = nameField.value;

                // As we are combining fields before the check we need to build value and key name for the message
                if (
                    'validateSecondName' in input.dataset &&
                    'validateDuplicateSecondName' in input.dataset
                ) {
                    const secondNameField = document.querySelector(
                        `[data-behaviour="validate-form"] [name="${input.dataset.validateSecondName}"]`,
                    );
                    if (secondNameField !== null) {
                        inputValue += ` ${secondNameField.value}`;
                    }

                    const duplicateSecondNameField = document.querySelector(
                        `[data-behaviour="validate-form"] [name="${input.dataset.validateDuplicateSecondName}"]`,
                    );
                    if (duplicateSecondNameField !== null) {
                        duplicateInputValue += ` ${duplicateSecondNameField.value}`;
                    }
                }
                inputValid =
                    inputValue.toLowerCase() !==
                    duplicateInputValue.toLowerCase();
                errorHtml = `Cannot be the same as your personal details`;
            }
        }
    }

    input.classList[inputValid || skipValidation ? 'remove' : 'add'](
        'input-invalid',
    );

    // Need to make sure any other radio inputs are also marked as valid
    if (input.type === 'radio') {
        const radioInputs = formRow.querySelectorAll(
            `input[name="${input.name}"]`
        );
        if (radioInputs.length > 0) {
            radioInputs.forEach(radioInput => {
                radioInput.classList[
                    inputValid || skipValidation ? 'remove' : 'add'
                ]('input-invalid');
            });
        }
    }

    const fieldError = formRow.querySelector(
        `[data-target="form-row-error"][data-error-field="${input.name}"]`
    );
    if (fieldError !== null) {
        fieldError.innerHTML = inputValid || skipValidation ? '' : errorHtml;
        fieldError.classList[inputValid || skipValidation ? 'add' : 'remove']('u-hidden');
    }

    input.classList.remove('input-validating');
}

/**
 * Toggle Shipping Fields Change
 *
 * WooCommerce handles to show/hide of the fields but will need to remove field validation
 * @param {object} toggleFieldInput
 * @param {object} form
 */
function toggleShippingFieldChange(toggleFieldInput) {
    const formRow = toggleFieldInput.closest('[data-validate-row]');
    const fields = [
        ...formRow.querySelectorAll('[data-behaviour*="validate-field"]')
    ];

    if (fields.length > 0) {
        /**
         * Loop through fields and store the required state before you turn it to false
         */
        for (const field of fields) {
            // As validation will be skipped will need to remove any error messages and will copy from billing
            if (field.classList.contains('input-invalid')) {
                field.classList.remove('input-invalid');
            }

            const fieldError = formRow.querySelector(
                `[data-target="form-row-error"][data-error-field="${field.name}"]`
            );
            if (fieldError !== null) {
                fieldError.innerHTML = '';
                fieldError.classList.add('u-hidden');
            }

            // Need to set the value of the field if it matches shipping or PayPal validation fails
            if ('name' in field && field.name !== '') {
                const billingKey = field.name.replace('shipping', 'billing');
                const billingField = document.querySelector(
                    `[name="${billingKey}"]`
                );
                if (billingField !== null) {
                    if (['checkbox', 'radio'].includes(field.type)) {
                        if (toggleFieldInput.checked) {
                            field.checked = '';
                        }
                    } else {
                        field.value = !toggleFieldInput.checked ? billingField.value : '';
                    }
                }
            }
        }

        // Need to refresh the selects as they will have been changed based on the selected country by woo jquery
        if (typeof jQuery != 'undefined' && jQuery !== null) {
            jQuery('#shipping_country').trigger('change');
            jQuery('#shipping_state').val(!toggleFieldInput.checked ? jQuery('#billing_state').val() : '');
            jQuery('#shipping_state').trigger('change');
        }
    }
}

/**
 * Copies billing field to shipping field if 
 * @param {object} input
 * @returns
 */
function syncBillingToShippingField(input, form) {
    // Need to set the value of the field if it matches shipping or PayPal validation fails
    if ('name' in input && input.name !== '' && input.name.includes('billing')) {
        const shippingKey = input.name.replace('billing', 'shipping');
        const shippingField = form.querySelector(`[name="${shippingKey}"]`);
        const toggleShippingField = form.querySelector('[data-behaviour*="toggle-shipping-fields"]');
        if (shippingField !== null && toggleShippingField !== null && !toggleShippingField.checked) {
            shippingField.value = input.value;
        }
    }
}

/**
 * Trigger GA4 when personal details required fields have been completed
 */
function trackPersonalDetailsCompleted(form) {
    if (localStorage.getItem('personalDetailsFormCompletedSent') === null) {
        const billingFields = [
            ...form.querySelectorAll('[data-validate-form="billing"][data-validate-required="true"]')
        ];
        if (billingFields.length > 0) {
            const validBillingFields = billingFields.filter(billingField => {
                let valid = true;
                const formRow = billingField.closest('[data-validate-row]');
                switch (billingField.type) {
                    case 'checkbox':
                        valid = billingField.checked;
                        break;
                    case 'radio':
                        // Need to check if at least one of the radio is checked if more than one
                        valid = formRow.querySelectorAll(
                            `input[name="${billingField.name}"]:checked`
                        ).length > 0;
                        break;
                    case 'select':
                    case 'select-one':
                        valid = billingField.selectedIndex in billingField.options && 
                        billingField.options[billingField.selectedIndex].value !== '';
                        break;
                    default:
                        valid = billingField.value !== '';
                        break;
                }

                return valid;
            });

            if (billingFields.length === validBillingFields.length) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event' : 'personaldetailsformCompleted',
                    'formName' : 'Attendee Payment Details'
                });
                localStorage.setItem('personalDetailsFormCompletedSent', true);
            }
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    if (
        document.body.classList.contains('woocommerce-order-received') &&
        localStorage.getItem('personalDetailsFormCompletedSent') !== null
    ) {
        localStorage.removeItem('personalDetailsFormCompletedSent');
    }
});
