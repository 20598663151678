import React, { useState, useEffect } from "react";
import he from 'he';
import Accordion from "./Accordion";
import { deleteFromSet, addToSet } from '../Utilities/Functions';

const Filters = ({onSortChange, onAddictionChange, onAddictionPreChange, onCustomerTypeChange, onCustomerTypePreChange, sortQuery, filterCount, applyMobileFilters, filterHeader = '' }) => {

    const sort = {
        'newest':'Newest',
        'oldest':'Oldest'
    }
    const defaultFilterState = {
        'sort': 'newest',
        'addictions': new Set(),
        'customerType': new Set()
    };
    const [dbFilters, setDbFilters] = useState({});
    const [loading, setLoading] = useState(true);
    const [openFilters, setOpenFilters] = useState(false);
    const [disableFilters, setDisableFilters] = useState(true);
    const [activeFilters, setActiveFilters] = useState(defaultFilterState);

    useEffect(() => {
        fetchFilters();
    }, []);

    const fetchFilters = async () => {

        const formData = new FormData();
        formData.append('security', wp_ajax.security);  

        await fetch(`${wp_ajax.ajax_url}?action=client_reviews_filters`, {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(response => {
                setDbFilters(response.data);
                onCustomerTypePreChange(response.data.customers);
                onAddictionPreChange(response.data.addictions);
                setLoading(false);
                observer.observe(document.querySelector(".client-reviews__dropdown"));
            })
            .catch(error => {
                console.log('Error during fetch: ' + error);
            });
    }    

    function handleSortChange(value) {
        if (disableFilters) {
            setActiveFilters({
                ...activeFilters,
                'sort': value,
            });
            sortQuery.sort = value; //modify the prop
        } else {
            onSortChange(value);
        }
    }

    function handleAddictionChange(value) {
        if (disableFilters) {
            if (activeFilters.addictions.has(value)) {
                setActiveFilters({
                    ...activeFilters,
                    'addictions': deleteFromSet(activeFilters.addictions, value),
                });
            } else {
                setActiveFilters({
                    ...activeFilters,
                    'addictions': addToSet(activeFilters.addictions, value),
                });
            }
        } else {
            onAddictionChange(value);
        }
    }

    function handleCustomerTypeChange(value) {
        if (disableFilters) {
            if (activeFilters.customerType.has(value)) {
                setActiveFilters({
                    ...activeFilters,
                    'customerType': deleteFromSet(activeFilters.customerType, value),
                });
            } else {
                setActiveFilters({
                    ...activeFilters,
                    'customerType': addToSet(activeFilters.customerType, value),
                });
            }
        } else {
            onCustomerTypeChange(value);
        }
    }

    function handleDropDownClick() {
        setOpenFilters(prevState => !prevState);
    }

    let observer = new IntersectionObserver(function(entries) {
        if (entries[0]['intersectionRatio'] == 0) {
            // element is hidden
            setDisableFilters(false);
        } else {
            // element is visible
            setDisableFilters(true);
        }
    }, { root: document.documentElement });

    function applyFilters() {
        applyMobileFilters(activeFilters);
        handleDropDownClick();
    }

    function resetFilters() {
        document.querySelectorAll('.client-reviews__filters-list input[type=checkbox]').forEach(el => el.checked = false);
        setActiveFilters(defaultFilterState);
        applyMobileFilters(defaultFilterState);
        handleDropDownClick();
    }

    if (loading)
        return <div className="client-reviews__filters">Loading Filters...</div>

    return (
        <div className="client-reviews__filters">
            {filterHeader &&
                <div className="client-reviews__sort-header-wrapper" dangerouslySetInnerHTML={{ __html: filterHeader }} />
            }
            <div className="client-reviews__dropdown" onClick={handleDropDownClick}>
                <h5 className="client-reviews__dropdown-title">Filter &amp; Sort <span className="client-reviews__filter-count">{filterCount}</span></h5>
                
                <span className="material-icons">{(openFilters ? 'close' : 'sort')}</span>
            </div>
            <div className={'client-reviews__filters-list' + (openFilters ? ' client-reviews__filters-list--open' : '')}>
                <div className="triangle-top"></div>
                <Accordion title="Sort" filters={sort} type="radio" onSortChange={handleSortChange} sortQuery={sortQuery} canCollapse={false} />
                <Accordion title="Addiction Type" filters={dbFilters.addictions} onCheckboxChange={handleAddictionChange}/>
                <Accordion title="Customer Type" filters={dbFilters.customers} onCheckboxChange={handleCustomerTypeChange} />
                <div className="client-reviews__buttons">
                    <button className="button" onClick={applyFilters}>Apply filters</button>
                    <button onClick={resetFilters}>Reset</button>
                </div>
            </div>
        </div>
    );
};

export default Filters;