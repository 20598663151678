import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const proptypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    measurement: PropTypes.string.isRequired,
    defaultValue: PropTypes.number,
    debounceTime: PropTypes.number,
    handleRangeChange: PropTypes.func.isRequired,
    updateValueOnCallback: PropTypes.number,
};

const RangeSlider = ({
    min,
    max,
    step,
    measurement,
    defaultValue = 0,
    handleRangeChange,
    updateValueOnCallback,
}) => {
    const [rangeValue, setRangeValue] = useState(defaultValue);
    const rangeLabel = useRef();
    const rangeInput = useRef();

    const primaryColor = '#dc1a4c'; //$primary in vars
    const grayColor = '#D5D5D5';

    useEffect(() => {
        const tempDefaultValue = defaultValue;
        if (defaultValue !== rangeValue) setRangeValue(tempDefaultValue); // set range in state takes too long

        updateRange(tempDefaultValue);
    }, [defaultValue, max]);

    useEffect(() => {
        if (updateValueOnCallback && updateValueOnCallback !== rangeValue) {
            updateRange(updateValueOnCallback);
        }
    }, [updateValueOnCallback]);

    /**
     * On range Change
     * @param {Object} event
     */
    const onRangeChange = event => {
        setRangeValue(event.target.value);
        handleRangeChange(event.target.value);
    };

    /**
     * Update Label Position of Range Slider
     * @param {Number} value
     */
    const updateRange = value => {
        //Update Label
        const newValue = Number(((value - min) * 100) / (max - min));
        const newPosition = 10 - newValue * 0.2;
        rangeLabel.current.style.left = `calc(${newValue}% + (${newPosition}px))`;
        //Update background
        rangeInput.current.style.background = `linear-gradient(to right, ${primaryColor} 0%, ${primaryColor} ${newValue}%, ${grayColor} ${newValue}%, ${grayColor} 100%)`;

        setRangeValue(value);
    };

    return (
        <div className="range-slider u-relative">
            <div className="range-slider-value" ref={rangeLabel}>
                <span>
                    {rangeValue ? rangeValue : defaultValue} {measurement}
                </span>
            </div>
            <div className="u-relative">
                <input
                    type="range"
                    min={min}
                    max={max}
                    step={step}
                    value={rangeValue ? rangeValue : defaultValue}
                    onMouseUp={onRangeChange}
                    onTouchEnd={onRangeChange}
                    onChange={e => updateRange(e.target.value)}
                    data-target="booking-range"
                    ref={rangeInput}
                />
                <span className="range-slider-min">
                    {min} {measurement}
                </span>
                <span className="range-slider-max">
                    {max} {measurement}
                </span>
            </div>
        </div>
    );
};
RangeSlider.propTypes = proptypes;
export default RangeSlider;
