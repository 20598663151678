// Add your js module file imports here
function loadJavascriptIn(filename) {
    try {
        require('./'+filename);
    } catch (ex ) {
        console.error("Error in " + filename);
        console.error("Error in " + filename + ' ' + ex);
    }
}

loadJavascriptIn('geo-location');
loadJavascriptIn('footer-menu');
loadJavascriptIn('header-menu');
loadJavascriptIn('header-search');
loadJavascriptIn('modal');
loadJavascriptIn('seminar');
loadJavascriptIn('ovp');
loadJavascriptIn('frequently-asked-questions');
loadJavascriptIn('gravity-form-sections');
loadJavascriptIn('product-availability-checker');
loadJavascriptIn('block-references');
loadJavascriptIn('sliders');
loadJavascriptIn('copy-to-clipboard');
loadJavascriptIn('myaccount-video');
loadJavascriptIn('dropdown');
loadJavascriptIn('tabs');
loadJavascriptIn('rating-field');
loadJavascriptIn('addiction-field');
loadJavascriptIn('image-upload-field');
loadJavascriptIn('video-upload-field');
loadJavascriptIn('form-section-accordion');
loadJavascriptIn('location-online-checker');
loadJavascriptIn('accordion');
loadJavascriptIn('form-validation');
loadJavascriptIn('checkout');
loadJavascriptIn('breadcrumbs');
loadJavascriptIn('block-choose-programme');
loadJavascriptIn('checkout-upsell');
loadJavascriptIn('notice-block');
loadJavascriptIn('live-chat');
loadJavascriptIn('scrollable-table');
loadJavascriptIn('countdown-timer');
loadJavascriptIn('view-all');
loadJavascriptIn('toggle');
loadJavascriptIn('wpml-fix');
loadJavascriptIn('language-bar');
