import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../Utilities/Modal";

const proptypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    helpContent: PropTypes.node,
    hideArrows: PropTypes.bool
};

const PanelTitle = ({ title, subtitle = '', helpContent = '' }) => {
    
    const [showModal, setShowModal] = useState(false);

    const handleModalChange = () => {
        setShowModal(!showModal);
    }

    return (
        <div className="u-flex">
            {title && !helpContent &&
                <div className="booking__panel-title-wrap">
                    <h3 className="booking__panel-title">
                        {title}
                        {subtitle && <small><br/>{subtitle}</small>}
                    </h3>
                </div>
            }
            {title && helpContent &&
                <div className="booking__panel-title-wrap">
                    <h3 className="booking__panel-title">
                        {title}
                        {subtitle && <small><br/>{subtitle}</small>}
                    </h3>
                    {showModal && <span className="triangle-right"></span>}
                    <span
                        className="booking-tooltip material-icons material-icons-outlined"
                        onClick={() => handleModalChange()}>
                        help_outline
                    </span>
                    <Modal
                        handleShow={showModal}
                        content={helpContent}
                        handleModalClose={handleModalChange}
                        themeClass="modal-theme-booking"
                    />
                </div>
            }
        </div>
    )
}

PanelTitle.propTypes = proptypes;

export default PanelTitle;