import React, { useState } from "react";
import { BookingContext } from "./Context/BookingContext";
import { defaultObject } from "./Context/BookingDefaultData";
import Heading from "./Heading";
import BookingContainer from "./BookingContainer";
import { getLocalTimeZone } from '../../modules/utilities';

export const BookingProvider = ({ children, geo }) => {
    if (typeof geo === 'object') {
        const timeZone = getLocalTimeZone(true, false, geo.country);

        // Need to make sure we only fetch timezone that is supported otherwise force to UTC
        if (defaultObject.supportedTimeZones.includes(timeZone)) {
            geo.timezone = timeZone;
        } else {
            geo.timezone = 'UTC';
        }

        // Will need to convert to the standard time as the times are indexed with that
        if (geo.timezone in defaultObject.standardTimeZones) {
            geo.timezone = defaultObject.standardTimeZones[geo.timezone];
        }

        defaultObject.geo = geo;
    }

	const bookingState = useState({
		...defaultObject
	});

	return (
		<BookingContext.Provider value={bookingState}>
			{children}
		</BookingContext.Provider>
	);
};

const Booking = ({ geo }) => {
    return (
        <div className="booking">
            <Heading />
            <BookingProvider geo={geo}>
                <BookingContainer />
            </BookingProvider>
        </div>
    );
}
export default Booking