import Swiper, { Navigation, Pagination } from 'swiper';
import { debounce } from "./utilities";

 // configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

let articlesSlider = document.querySelectorAll('[data-behaviour="articles-slider"]');

articlesSlider.forEach(slider => {

    const section = slider.closest('[data-ref="post-slider"]');
    const prev = section.querySelector('[data-ref="slider-prev"]');
    const next = section.querySelector('[data-ref="slider-next"]');

    const artSlider = new Swiper(slider, {
        // Optional parameters
        direction: 'horizontal',
        loop: true,

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
      
        // Navigation arrows
        navigation: {
            nextEl: next,
            prevEl: prev,
        },

        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1.3,
                spaceBetween: 20,
                centeredSlides: true,
                loop: true,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 20,
                centeredSlides: false,
                loop: true,
            },
            1128: {
                slidesPerView: 3,
                spaceBetween: 45,
                loop: true,
            }
        }
    });
});

let carousel_slider_layout_1 = document.querySelectorAll('[data-behaviour="carousel-slider-layout_1"]');

carousel_slider_layout_1.forEach(slider => {

    const section = slider.closest('[data-ref="carousel-slider-wrap-layout_1"]');
    const prev = section.querySelector('[data-ref="slider-prev"]');
    const next = section.querySelector('[data-ref="slider-next"]');

    new Swiper(slider, {
        
        direction: 'horizontal',
        slidesPerView: 1.2,
        centeredSlides: true,
        loop: true,

        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
      
        navigation: {
            nextEl: next,
            prevEl: prev,
        },

        breakpoints: {
            320: {
                slidesPerView: 1.2,
                centeredSlides: true,
                loop: true,
            },
            768: {
                slidesPerView: 1,
                centeredSlides: true,
                loop: true,
            }
        }
    });
});

let carousel_slider_layout_2 = document.querySelectorAll('[data-behaviour="carousel-slider-layout_2"]');

carousel_slider_layout_2.forEach(slider => {

    const section = slider.closest('[data-ref="carousel-slider-wrap-layout_2"]');
    const prev = section.querySelector('[data-ref="slider-prev"]');
    const next = section.querySelector('[data-ref="slider-next"]');

    const swiper = new Swiper(slider, {
        direction: 'horizontal',
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,

        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
      
        navigation: {
            nextEl: next,
            prevEl: prev,
        },

        breakpoints: {
            320: {
                slidesPerView: 1,
                centeredSlides: true,
                loop: true,
            },
            768: {
                slidesPerView: 2,
                centeredSlides: false,
                loop: true,
            },
            1128: {
                slidesPerView: 3,
                centeredSlides: false,
                loop: true,
            },
            1378: {
                slidesPerView: 3,
                centeredSlides: false,
                loop: true,
            }
        }
    });

    const testimonialToggles = section.querySelectorAll('[data-behaviour*="testimonial-toggle"]');
    if (testimonialToggles.length > 0) {
        testimonialToggles.forEach((testimonialToggle) => {
            testimonialToggle.addEventListener('click', (e) => {
                e.stopPropagation();
                if (
                    testimonialToggle.previousElementSibling !== null &&
                    testimonialToggle.previousElementSibling.classList.contains('testimonial-carousel__quote')
                ) {
                    testimonialToggle.previousElementSibling.classList.add('testimonial-carousel__top--open');
                }
                swiper.update();
            });
        });
    }
});

let carousel_slider_layout_3 = document.querySelectorAll('[data-behaviour="carousel-slider-layout_3"]');

carousel_slider_layout_3.forEach(slider => {

    const section = slider.closest('[data-ref="carousel-slider-wrap-layout_3"]');
    const prev = section.querySelector('[data-ref="slider-prev"]');
    const next = section.querySelector('[data-ref="slider-next"]');
    let swiper = undefined;

    const enableSwiper = function () {
        swiper = new Swiper(slider, {
            direction: 'horizontal',
            slidesPerView: 1,
            centeredSlides: true,
            loop: true,

            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
        
            navigation: {
                nextEl: next,
                prevEl: prev,
            },

            breakpoints: {
                320: {
                    slidesPerView: 1,
                    centeredSlides: true,
                    loop: true,
                    autoHeight: true,
                },
                768: {
                    slidesPerView: 2.1,
                    centeredSlides: false,
                    loop: true,
                    autoHeight: false,
                },
                1128: {
                    slidesPerView: 2,
                    centeredSlides: false,
                    loop: true,
                    autoHeight: false,
                },
                1370: {
                    slidesPerView: 2,
                    centeredSlides: false,
                    loop: true,
                    autoHeight: false,
                }
            }
        })
    }
    enableSwiper();

    let initialWidth = document.body.clientWidth;
    //This fixes the autoHeight setting not being initialised
    window.addEventListener('resize', debounce(() => {
        
        let width = document.body.clientWidth;

        if (document.body.clientWidth > 768) {
            if (initialWidth < 768) {
                swiper.destroy(true, true);
                swiper = undefined;
                enableSwiper();
                initialWidth = width;
            }
        }
        if (document.body.clientWidth < 768) {
            if (initialWidth > 768) {
                swiper.destroy(true, true);
                swiper = undefined;
                enableSwiper();
                initialWidth = width;
            }
        }

    }), 300);
    const testimonialToggles = section.querySelectorAll('[data-behaviour*="testimonial-toggle"]');
    if (testimonialToggles.length > 0) {
        testimonialToggles.forEach((testimonialToggle) => {
            testimonialToggle.addEventListener('click', (e) => {
                e.stopPropagation();
                if (
                    testimonialToggle.previousElementSibling !== null &&
                    testimonialToggle.previousElementSibling.classList.contains('testimonial-carousel__quote')
                ) {
                    testimonialToggle.previousElementSibling.classList.add('testimonial-carousel__top--open');
                }
                swiper.update();
            });
        });
    }

});

let choose_programme_carousel_slider = document.querySelectorAll('[data-behaviour="choose-programme-slider"]');

choose_programme_carousel_slider.forEach(slider => {

    const section = slider.closest('[data-ref="choose-programme-carousel-slider"]');
    const prev = section.querySelector('[data-ref="slider-prev"]');
    const next = section.querySelector('[data-ref="slider-next"]');

    const most_popular_slide = section.querySelector('[data-most-popular-slide]').dataset.mostPopularSlide;

    const choose_programme_carousel_slider = new Swiper(slider, {
        
        slidesPerView: 1.2,
        slidesPerGroup: 1,

        direction: 'horizontal',
        loop: false,
        grabCursor: true,
        centeredSlides: true,
        spaceBetween: 10,
        
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        
        navigation: {
            nextEl: next,
            prevEl: prev,
        },
        
        breakpoints: {
            320: {
                slidesPerView: 1.25,
                spaceBetween: 20,
                centeredSlides: true,
                initialSlide: most_popular_slide ? most_popular_slide - 1 : 0,
            },
            768: {
                slidesPerView: 2.2,
                spaceBetween: 20,
                initialSlide: most_popular_slide ? most_popular_slide - 1 : 0,
                centeredSlides: true,
                centerInsufficientSlides: true,
            },
            1128: {
                slidesPerView: 4,
                spaceBetween: 30,
                initialSlide: 0,
                centeredSlides: false,
                centerInsufficientSlides: true,
            }
        }
    });
});

// featured testimonial slider
let featTestimonialSlider = document.querySelectorAll('[data-behaviour="testimonial-slider"]');

featTestimonialSlider.forEach(slider => {
    
    let swiper = undefined;

    const section = slider.closest('[data-ref="feat-testimonials"]');
    const enableSwiper = function () {
        swiper = new Swiper(slider, {

            direction: 'horizontal',
            slidesPerView: 1.4,
            spaceBetween: 20,
            centeredSlides: true,
            loop: true,

            pagination: {
                el: section.querySelector('.swiper-pagination'),
                clickable: true
            },
        
            navigation: {
                nextEl: '.swiper__nav--next',
                prevEl: '.swiper__nav--prev',
            },

            breakpoints: {
                480: {
                    slidesPerView: 1.4,
                },
                768: {
                    slidesPerView: 2,
                },
            }
        });  
    }
    
    window.onload = () => {
        if (document.body.clientWidth < 769) {
            enableSwiper();
        }
    };

    window.addEventListener('resize', debounce(() => {

        if (document.body.clientWidth < 769 && swiper == undefined) {
            enableSwiper();
		} else if(document.body.clientWidth > 768 && swiper !== undefined) {
            swiper.destroy(true, true);
            swiper = undefined;
        }

    }), 300);
});

let choose_addiction_slider = document.querySelectorAll('[data-behaviour="choose-addiction-slider"]');

choose_addiction_slider.forEach(slider => {

    const section = slider.closest('[data-ref="choose-addiction-slider"]');
    const prev = section.querySelector('[data-ref="slider-prev"]');
    const next = section.querySelector('[data-ref="slider-next"]');

    new Swiper(slider, {
        
        slidesPerView: 2.2,

        direction: 'horizontal',
        loop: false,
        grabCursor: true,
        spaceBetween: 10,

        navigation: {
            nextEl: next,
            prevEl: prev,
        },
        
        breakpoints: {
            320: {
                slidesPerView: 2.2,
            },
            768: {
                slidesPerView: 3,
            },
            1128: {
                slidesPerView: 4,
            }
        }
    });
});

const video_slider = document.querySelectorAll('[data-behaviour="video-slider"]');

video_slider.forEach(slider => {
    new Swiper(slider, {
        slidesPerView: 1.2,
        direction: 'horizontal',
        loop: false,
        spaceBetween: 20,
        navigation: false,
        clickable: true,
        breakpoints: {
            320: {
                slidesPerView: 1.2,
                loop: false,
            },
            768: {
                slidesPerView: 2,
                loop: false,
            },
            1128: {
                slidesPerView: 4,
                loop: false,
            }
        }
    });
})